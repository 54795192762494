import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import {
    Button, Col, Navbar,
    NavbarBrand,
  } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';


// the onSubmit part will go into pagebilling

const TodoBilling = ({ todoisAuthenticated }) => {
   

    if (todoisAuthenticated) {
        return <Redirect to='/todo/dashboard/index' />;
    }

    return (
    <React.Fragment> 
        <div className="maincontainer">
        <div class="container-fluid">
            <div class="row no-gutter body-film">
               
                <div class="col-md-6 d-none d-md-flex billing-bg"></div>
                
                <div class="col-md-6 cheese-bg">
                    <div class="login d-flex align-items-center py-5">
                       
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-10 col-xl-7 mx-auto">
                                  <NavbarBrand href="https://app.ricotta.team/todo/billing" target="_blank"><span><img src="/assets/cheese-512.png" width={30} alt="Ricotta" /> </span> <span style={{color: '#E04F60'}}>Ricotta Todos</span></NavbarBrand>
                                    <div className ="container billing-div-3">
                                    {/* <p>Black Friday Sale! Use Code <b><span className="ricotta-color">BLACKFRIDAY30</span></b> to get <b>30% Off</b> on Annual Plans. Valid till <b>4th December</b> for new subscriptions!</p> */}
                                    <p class="text-muted mt-3 mb-1">Please confirm your Slack account again.</p>
                                    </div>
                                    <div className="mt-md-4 mt-2"><Col xs = {{size: 12}} md={{size: 12 }}><div className="col-md-12 mx-md-auto">
                                      <Button className = "btn-lg btn-block signUpButton2" href="https://slack.com/oauth/v2/authorize?client_id=791862583680.2839242940227&scope=users:read&state=billing&redirect_uri=https://slackproductivity.ricotta.team/api/todo/auth">
                                      {/* <Button className = "btn-lg btn-block signUpButton2" href="https://slack.com/oauth/v2/authorize?client_id=2241315215093.2268033462896&scope=users:read&state=billing&redirect_uri=https://a424-106-51-141-123.ngrok.io/api/auth"> */}
                                      Confirm Slack Account</Button></div></Col></div>
                                    <div className="mt-5">
                                      <p className="text-muted">Subscribe to Ricotta Todos to get access to:</p>
                                      <ul className="fa-ul">
                                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Unlimited Todo lists</li>
                                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Manage all Todos without leaving Slack</li>
                                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Reminders for your Todos</li>
                                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Bookmark Todos from Slack messages</li>
                                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Share Todo lists on Slack Channels and DMs</li>
                                        <li><span className="fa-li"><i className="fa fa-check"></i></span>End-to-end Data Encryption</li>
                                      </ul>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </React.Fragment>  
    )
};

TodoBilling.propTypes = {
    todoisAuthenticated: PropTypes.bool
  };
  
const mapStateToProps = state => ({
 todoisAuthenticated: state.auth.todoisAuthenticated
});

export default connect(mapStateToProps)(TodoBilling);