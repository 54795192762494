import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  Button, Media
} from 'reactstrap';
const axios = require('../config/axiosInstance');
const axios2 = require('axios');



// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// const stripePromise = loadStripe('pk_test_aubx4QrCXgh7gmaqmyjx5jHQ00u6EtZOmX');

const stripePromise = loadStripe('pk_live_oYyuovMeNVaQe5SSflWCE8XD00edZonWk9');

export default function Pagebilling(props) {

  const [billing, setBilling] = useState("annually");

  const [errorText, setErrorText] = useState();
  const [isAdmin, setIsAdmin] = useState();
  const [isDummy, setIsDummy] = useState();
  const [isBillingAdmin, setIsBillingAdmin] = useState(false);
  const [isBillingActive, setIsBillingActive] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [conversationId, setConversationId] = useState("");
  const [slackteamid, setSlackteamid] = useState("");
  const [slackuserid, setSlackuserid] = useState("");
  const [country, setCountry] = useState("");
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);



  // // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
   

  

    


   
  
    (async () => {
      try {
         // setCountry(ipRes.data.country_name);
        // console.log(country);
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let foo = params.get('code');
        console.log('came here 232')
        console.log(foo)

        
        const config = {
          headers: {
            'Content-Type': 'application/json'
          }
        };

        const body = JSON.stringify({ 
          code: foo
        });

        var ipRes = await axios2.get('https://ipapi.co/json/')
        console.log(ipRes)
        ipRes.status === 200 ? setCountry(ipRes.data.country_name) : setCountry("Not found")
        console.log(country);
        //ipRes.data.country_name


        console.log('came here 232')
        const res = await axios.post(`/api/billingauth`, body, config);
        var resData = res.data;

        var isAdmin = resData.isAdmin;
        var email = resData.email;
        var isBillingAdmin = resData.isBillingAdmin;
        var isBillingActive = resData.isBillingActive;
        var name = resData.name;
        var conversationId = resData.conversationId;
        var slackTeamID = resData.slackTeamID;
        var slackUserID = resData.slackUserID;


        setIsAdmin(isAdmin);
        setIsBillingAdmin(isBillingAdmin);
        setIsBillingActive(isBillingActive);
        setName(name);
        setEmail(email);
        setConversationId(conversationId);
        setSlackteamid(slackTeamID);
        setSlackuserid(slackUserID);
        setIsDummy(isAdmin)

        res.status === 200 ? setLoaded(true) : console.log('oops')

        
      } catch (error) {
        console.log(error);
      }
      
    })();
      

    window.setTimeout(function(){

      setErrorText(<p className ="lead mt-3">Something went wrong or your session expired. Please return to the <a href="/billing">billing page</a> and login again</p>)

    }, 6000);
    

    

   
    // // Update the document title using the browser API
    // document.title = `You clicked ${count} times`;
  }, []);

  const pricesEarlyGlobalOld = {
    monthly: 75,
    annually: 540
  }

  const pricesGrowthGlobalOld = {
    monthly: 150,
    annually: 1080
  }

  const pricesEarlyGlobal = {
    monthly: 45,
    annually: 324
  }

  const pricesGrowthGlobal = {
    monthly: 90,
    annually: 648
  }


  const pricesEarlyIndia = {
    monthly: 1400,
    annually: 9960
  }


  const pricesGrowthIndia = {
    monthly: 2800,
    annually: 19920
  }

  const buttonClass1 = {
    monthly: "btn btn-first",
    annually: "btn btn-second"
  }

  const buttonClass2 = {
    monthly: "btn btn-second",
    annually: "btn btn-first"
  }

  const handleClickPrice1 = async (event) => {
    setLoading(true)
    // When the customer clicks on the button, redirect them to Checkout.
    const stripe = await stripePromise;


    const priceId1 = country === 'India' ? 'price_1HMt47JBEzpLhH7BBpAXfJ4y' : 'price_1HbPJwJBEzpLhH7B9ck5q3J5'
    // global normal price id 'price_1HbPJwJBEzpLhH7B9ck5q3J5'

    const { error } = await stripe.redirectToCheckout({
      lineItems: [
        // Replace with the ID of your price
        {price: priceId1, quantity: 1}
      ],
      mode: 'subscription',
      successUrl: `https://app.ricotta.team/paymentsuccess?team=${slackteamid}&conv=${conversationId}`,
      cancelUrl: 'https://app.ricotta.team/paymentfailed',
      billingAddressCollection: 'required',
      customerEmail: email,
      clientReferenceId: `okr&limitedMonthly&${slackteamid}&${slackuserid}`
    });
    
  };

  const handleClickPrice2 = async (event) => {
    setLoading(true)
    // When the customer clicks on the button, redirect them to Checkout.
    const stripe = await stripePromise;
    const priceId2 = country === 'India' ? 'price_1HMt47JBEzpLhH7BRgfPhxNS' : 'price_1HbPJwJBEzpLhH7BCtmvzO3e'

    // global normal price id 'price_1HbPJwJBEzpLhH7BCtmvzO3e'

    const { error } = await stripe.redirectToCheckout({
      lineItems: [
        // Replace with the ID of your price
        {price: priceId2, quantity: 1}
      ],
      mode: 'subscription',
      successUrl: `https://app.ricotta.team/paymentsuccess?team=${slackteamid}&conv=${conversationId}`,
      cancelUrl: 'https://app.ricotta.team/paymentfailed',
      customerEmail: email,
      billingAddressCollection: 'required',
      clientReferenceId: `okr&limitedAnnually&${slackteamid}&${slackuserid}`
    });
    
  };

  const handleClickPrice3 = async (event) => {
    setLoading(true)
    // When the customer clicks on the button, redirect them to Checkout.
    const stripe = await stripePromise;
    const priceId3 = country === 'India' ? 'price_1HMt48JBEzpLhH7BWA2LCel3' : 'price_1HbPJwJBEzpLhH7B6OrQPd1g'

    // global normal price id 'price_1HbPJwJBEzpLhH7B6OrQPd1g'

    const { error } = await stripe.redirectToCheckout({
      lineItems: [
        // Replace with the ID of your price
        {price: priceId3, quantity: 1}
      ],
      mode: 'subscription',
      successUrl: `https://app.ricotta.team/paymentsuccess?team=${slackteamid}&conv=${conversationId}`,
      cancelUrl: 'https://app.ricotta.team/paymentfailed',
      customerEmail: email,
      billingAddressCollection: 'required',
      clientReferenceId: `okr&unlimitedMonthly&${slackteamid}&${slackuserid}`
    });
    
  };

  const handleClickPrice4 = async (event) => {
    setLoading(true)
    // When the customer clicks on the button, redirect them to Checkout.
    const stripe = await stripePromise;
    // old - normal pricing
    const priceId4 = country === 'India' ? 'price_1HMt47JBEzpLhH7BzYxXerEM' : 'price_1HbPJwJBEzpLhH7B5No7Gebl'

  
    // global normal price id 'price_1HbPJwJBEzpLhH7B5No7Gebl' 

    const { error } = await stripe.redirectToCheckout({
      lineItems: [
        // Replace with the ID of your price
        {price: priceId4, quantity: 1}
      ],
      mode: 'subscription',
      successUrl: `https://app.ricotta.team/paymentsuccess?team=${slackteamid}&conv=${conversationId}`,
      cancelUrl: 'https://app.ricotta.team/paymentfailed',
      customerEmail: email,
      billingAddressCollection: 'required',
      clientReferenceId: `okr&unlimitedAnnually&${slackteamid}&${slackuserid}`
    });
    
  };

  // const handleClickManageBilling = async () => {
  //   console.log('came here 1')
  //   try {
  //     const configT = {
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'customerId': 'cus_HuXXaloYRv158C',
  //         'returnUrl': 'https://example.com/account'
  //       }
  //     };

  //     const body = JSON.stringify({ 
  //       dummy: 'https://example.com/account'
  //     });
  //     console.log(body);
  //     const response = await axios2.post(`/api/createSession`, body, configT);

  //     console.log(response);
  //     console.log(response.data.url);
  //     var newUrl = response.data.url;
  //     window.location.href = newUrl;
      
  //   } catch (error) {
      
  //   }

    

  // };

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  function handleMonthlyChange(evt) {
      setBilling("monthly");
  }

  function handleAnnuallyChange(evt) {
    setBilling("annually");
  }

  var currency = country === 'India' ? '₹' : '$'

  var earlyPriceM = country === 'India' ? numberWithCommas(pricesEarlyIndia["monthly"]) : numberWithCommas(pricesEarlyGlobal["monthly"])
  var growthPriceM = country === 'India' ? numberWithCommas(pricesGrowthIndia["monthly"]) : numberWithCommas(pricesGrowthGlobal["monthly"])

  var earlyPriceOldM = country === 'India' ? numberWithCommas(pricesEarlyIndia["monthly"] * 3) : numberWithCommas(pricesEarlyGlobalOld["monthly"])
  var growthPriceOldM = country === 'India' ? numberWithCommas(pricesGrowthIndia["monthly"] * 3): numberWithCommas(pricesGrowthGlobalOld["monthly"])

  var earlyPriceA = country === 'India' ? numberWithCommas(pricesEarlyIndia["annually"]) : numberWithCommas(pricesEarlyGlobal["annually"])
  var growthPriceA = country === 'India' ? numberWithCommas(pricesGrowthIndia["annually"]) : numberWithCommas(pricesGrowthGlobal["annually"])

  var earlyPriceOldA = country === 'India' ? numberWithCommas(pricesEarlyIndia["annually"] * 3) : numberWithCommas(pricesEarlyGlobalOld["annually"])
  var growthPriceOldA = country === 'India' ? numberWithCommas(pricesGrowthIndia["annually"] * 3) : numberWithCommas(pricesGrowthGlobalOld["annually"])

  var block = (<React.Fragment>
    
    <div className="col-lg-3">
    <div className="card mb-5 mb-lg-0">
          <div className="card-body">
            <h5 className="card-title text-muted text-uppercase text-center">Early-stage Companies Monthly</h5>
            <h6 className="card-price text-center"><span className="card-price-old"><del>{currency}{earlyPriceOldM}</del></span><span className="period">/month</span>{"\n"}{currency}{earlyPriceM}<span className="period">/month</span></h6>
            <hr />
            <ul className="fa-ul">
              <li><span className="fa-li"><i className="fa fa-check"></i></span>Upto 40 users</li>
              <li><span className="fa-li"><i className="fa fa-check"></i></span>Monthly Subscription</li>
              <li><span className="fa-li"><i className="fa fa-check"></i></span>Task boards, OKRs, Stand-ups</li>
              <li><span className="fa-li"><i className="fa fa-check"></i></span>Automated OKR management workflows</li>
              <li><span className="fa-li"><i className="fa fa-check"></i></span>14-days free trial</li>
              <li><span className="fa-li"><i className="fa fa-check"></i></span>Email Support</li>
            </ul>
            <Button className="btn btn-block btn-primary text-uppercase" onClick={handleClickPrice1} disabled={loading}>{loading && (
                <i
                className="fa fa-refresh fa-spin"
                style={{ marginRight: "5px" }}
                />
            )}
            {loading && <span>Loading...</span>}
            {!loading && <span>Select plan</span>}</Button>
          </div>
        </div>
      </div>

      <div className="col-lg-3">
        <div className="card mb-5 mb-lg-0">
          <div className="card-body">
            <h5 className="card-title text-muted text-uppercase text-center">Early-stage Companies Annually</h5>
            <h6 className="card-price text-center"><span className="card-price-old"><del>{currency}{earlyPriceOldA}</del></span><span className="period">/year</span>{"\n"}{currency}{earlyPriceA}<span className="period">/year</span></h6>
            <hr />
            <ul className="fa-ul">
              <li><span className="fa-li"><i className="fa fa-check"></i></span>Upto 40 users</li>
              <li><span className="fa-li"><i className="fa fa-check"></i></span>Yearly Subscription</li>
              <li><span className="fa-li"><i className="fa fa-check"></i></span>Task boards, OKRs, Stand-ups</li>
              <li><span className="fa-li"><i className="fa fa-check"></i></span>Automated OKR management workflows</li>
              <li><span className="fa-li"><i className="fa fa-check"></i></span>14-days free trial</li>
              <li><span className="fa-li"><i className="fa fa-check"></i></span>Email Support</li>
            </ul>
            <Button className="btn btn-block btn-primary text-uppercase" onClick={handleClickPrice2} disabled={loading}>{loading && (
                <i
                className="fa fa-refresh fa-spin"
                style={{ marginRight: "5px" }}
                />
            )}
            {loading && <span>Loading...</span>}
            {!loading && <span>Select plan</span>}</Button>
          </div>
        </div>
      </div>
      
      <div className="col-lg-3">
        <div className="card mb-5 mb-lg-0">
          <div className="card-body">
            <h5 className="card-title text-muted text-uppercase text-center">Growth-stage Companies Monthly</h5>
            <h6 className="card-price text-center"><span className="card-price-old"><del>{currency}{growthPriceOldM}</del></span><span className="period">/month</span>{"\n"}{currency}{growthPriceM}<span className="period">/month</span></h6>
            <hr />
            <ul className="fa-ul">
              <li><span className="fa-li"><i className="fa fa-check"></i></span>Unlimited users</li>
              <li><span className="fa-li"><i className="fa fa-check"></i></span>Monthly Subscription</li>
              <li><span className="fa-li"><i className="fa fa-check"></i></span>Task boards, OKRs, Stand-ups</li>
              <li><span className="fa-li"><i className="fa fa-check"></i></span>Automated OKR management workflows</li>
              <li><span className="fa-li"><i className="fa fa-check"></i></span>14-days free trial</li>
              <li><span className="fa-li"><i className="fa fa-check"></i></span>Priority Support</li>                        
            </ul>
            <Button className="btn btn-block btn-primary text-uppercase" onClick={handleClickPrice3} disabled={loading}>{loading && (
                <i
                className="fa fa-refresh fa-spin"
                style={{ marginRight: "5px" }}
                />
            )}
            {loading && <span>Loading...</span>}
            {!loading && <span>Select plan</span>}</Button>
          </div>
        </div>
      </div>
      
      <div className="col-lg-3">
        <div className="card mb-5 mb-lg-0">
          <div className="card-body">
          <h5 className="card-title text-muted text-uppercase text-center">Growth-stage Companies Annually</h5>
            <h6 className="card-price text-center"><span className="card-price-old"><del>{currency}{growthPriceOldA}</del></span><span className="period">/year</span>{"\n"}{currency}{growthPriceA}<span className="period">/year</span></h6>
            <hr />
            <ul className="fa-ul">
              <li><span className="fa-li"><i className="fa fa-check"></i></span>Unlimited users</li>
              <li><span className="fa-li"><i className="fa fa-check"></i></span>Yearly Subscription</li>
              <li><span className="fa-li"><i className="fa fa-check"></i></span>Task boards, OKRs, Stand-ups</li>
              <li><span className="fa-li"><i className="fa fa-check"></i></span>Automated OKR management workflows</li>
              <li><span className="fa-li"><i className="fa fa-check"></i></span>14-days free trial</li>
              <li><span className="fa-li"><i className="fa fa-check"></i></span>Priority Support</li>                        
            </ul>
            <Button className="btn btn-block btn-primary text-uppercase" onClick={handleClickPrice4} disabled={loading}>{loading && (
                <i
                className="fa fa-refresh fa-spin"
                style={{ marginRight: "5px" }}
                />
            )}
            {loading && <span>Loading...</span>}
            {!loading && <span>Select plan</span>}</Button>
          </div>
        </div>
      </div>
      </React.Fragment>) 


  return (
    <React.Fragment>
      <div className ="container mt-5">
        <h1 className="billing-management-header">Ricotta Billing Management</h1>
        {loaded === false ? (<p className ="mt-2">Loading...please wait!</p>) : (<p className ="mt-2">Ricotta Billing Dashboard</p>)}


        {isAdmin === true ? isBillingActive === true ? isBillingAdmin === true ? 
          (<p className ="lead mt-3">Hello {name}, to update or cancel your plan, please contact us at <a href = "mailto: cheese@ricotta.team">cheese@ricotta.team</a></p>) 
            : (<p className ="lead mt-3">Hello {name}, please contact your team administrator who handled the billing or contact us at cheese@ricotta.team</p>) 
              : (<p className ="lead mt-3"> Hello {name}, please choose your plan and proceed.</p>) 
                : isAdmin === false ? (<p className ="lead mt-3">Hello {name}, please choose your plan and proceed.</p>)
                : errorText}

      </div> 

    {/* <Button className="btn btn-block btn-primary text-uppercase" onClick={handleClickManageBilling}>Manage Billing</Button> */}
    {/* <form method="GET" action={country}>
      <button type="submit">Manage billing</button>
    </form> */}
    <section className="pricing py-5">
            <div className="container">
              {/* <div className="row d-flex justify-content-center">
                <div class="btn-group" role="group" aria-label="Basic example">
                  <button type="button" className={buttonClass1[billing]} onClick={handleMonthlyChange}>Monthly</button>
                  <button type="button" className={buttonClass2[billing]} onClick={handleAnnuallyChange}>Annually</button>
                </div>
              </div>   */}
              <div className="row mt-2">

                {isDummy === true || isDummy === false ? isBillingActive === true ? isBillingAdmin === true ? 
                (<div className="container">
                  <Media>
                    <img src="/assets/mailbox.svg" className = "img-fluid billing-management-image" alt="Ricotta on Slack" />
                  </Media>
                  </div>) 
                  : (<div className="container">
                      <Media>
                        <img src="/assets/denied_access.svg" className = "img-fluid billing-management-image" alt="Ricotta on Slack" />
                      </Media>
                      </div>) 
                    : block : console.log('nothing here')}

              {/* {isAdmin === true ? isBillingActive === true ? isBillingAdmin === true ? 
                (<div className="container">
                  <Media>
                    <img src="/assets/mailbox.svg" className = "img-fluid billing-management-image" alt="Ricotta on Slack" />
                  </Media>
                  </div>) 
                  : (<div className="container">
                      <Media>
                        <img src="/assets/denied_access.svg" className = "img-fluid billing-management-image" alt="Ricotta on Slack" />
                      </Media>
                      </div>) 
                    : block
                      : isAdmin === false ? (<div className="container">
                          <Media>
                            <img src="/assets/denied_access.svg" className = "img-fluid billing-management-image" alt="Ricotta on Slack" />
                          </Media>
                          </div>) 
                          : console.log('nothing here')}
                         */}

             

                
                
              </div>
            </div>

            
          </section>
    </React.Fragment>
  );
}