import React from "react";
import { Container, Button } from "shards-react";

const Errors = () => (
  <Container fluid className="main-content-container px-4 pb-4">
    <div className="error">
      <div className="error__content">
        <h3>Something went wrong!</h3>
        <p>There was a problem on our end. Please try again later.</p>
        <p>If this error persists, please write to us on cheese@ricotta.team</p>
      </div>
    </div>
  </Container>
);

export default Errors;
