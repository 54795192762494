const axios = require('axios');

var axiosInstance4 = process.env.NODE_ENV === 'production' ? axios.create({
  baseURL: 'https://ricotta-events-lgdoz.ondigitalocean.app/'
}) : 
axios.create({
  baseURL: 'http://localhost:5000/'
})




module.exports = axiosInstance4;