import React, { useEffect, useState} from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../components/common/PageTitle";
import SmallStats from "../components/common/SmallStats";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import UsersOverview from "../components/blog/UsersOverview";
import Spinner from "../../layout-spinner/Spinner"
import BlogPosts from "./BlogPosts";
import Errors from "./Errors";


function BlogOverview({ engagement: { engagement, loading, error } }) {


  engagement = engagement === null ? {} : engagement
  const [smallStats1, setsmallStats1] = useState([{
      label: "Total Workspace Engagement Score",
      value: engagement.totalCount,
      percentage: "4.7%",
      increase: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "6", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(0, 184, 216, 0.1)",
          borderColor: "rgb(0, 184, 216)",
          data: [0, 0, 0, 0, 0, 0, 0]
        }
      ]
    },
    {
      label: "Trivia Contests",
      value: engagement.contestsTakenCount,
      percentage: "12.4",
      increase: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "6", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(23,198,113,0.1)",
          borderColor: "rgb(23,198,113)",
          data: [0, 0, 0, 0, 0, 0, 0]
        }
      ]
    },
    {
      label: "Photo Quiz",
      value: engagement.pcontestsTakenCount,
      percentage: "3.8%",
      increase: true,
      decrease: false,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "4", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,180,0,0.1)",
          borderColor: "rgb(255,180,0)",
          data: [0, 0, 0, 0, 0, 0, 0]
        }
      ]
    },
    {
      label: "Weekly Trivia",
      value: engagement.weeklyContestsTakenCount,
      percentage: "2.71%",
      increase: false,
      decrease: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "4", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,65,105,0.1)",
          borderColor: "rgb(255,65,105)",
          data: [0, 0, 0, 0, 0, 0, 0]
        }
      ]
    },
    {
      label: "Ice-breakers",
      value: engagement.iceCount,
      percentage: "2.4%",
      increase: false,
      decrease: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "4", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgb(0,123,255,0.1)",
          borderColor: "rgb(0,123,255)",
          data: [0, 0, 0, 0, 0, 0, 0]
        }
      ]
    }
  ])

  const [smallStats2, setsmallStats2] = useState([
    {
      label: "Connect4",
      value: engagement.connect4Count,
      percentage: "4.7%",
      increase: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "6", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(0, 184, 216, 0.1)",
          borderColor: "rgb(0, 184, 216)",
          data: [0, 0, 0, 0, 0, 0, 0]
        }
      ]
    },
    {
      label: "Tic Tac Toe",
      value: engagement.tttCount,
      percentage: "12.4",
      increase: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "6", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(23,198,113,0.1)",
          borderColor: "rgb(23,198,113)",
          data: [0, 0, 0, 0, 0, 0, 0]
        }
      ]
    },
    {
      label: "Rock Paper Scissors",
      value: engagement.rpsCount,
      percentage: "3.8%",
      increase: true,
      decrease: false,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "4", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,180,0,0.1)",
          borderColor: "rgb(255,180,0)",
          data: [0, 0, 0, 0, 0, 0, 0]
        }
      ]
    },
    {
      label: "This or That",
      value: engagement.thisthatTakenCount,
      percentage: "2.71%",
      increase: false,
      decrease: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "4", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,65,105,0.1)",
          borderColor: "rgb(255,65,105)",
          data: [0, 0, 0, 0, 0, 0, 0]
        }
      ]
    },
    {
      label: "Two Truths and a lie",
      value: engagement.twotruthsTakenCount,
      percentage: "2.4%",
      increase: false,
      decrease: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "4", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgb(0,123,255,0.1)",
          borderColor: "rgb(0,123,255)",
          data: [0, 0, 0, 0, 0, 0, 0]
        }
      ]
    }
  ])


  const [smallStats3, setsmallStats3] = useState([
    {
      label: "Hangman",
      value: engagement.hcontestsTakenCount,
      percentage: "4.7%",
      increase: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "6", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(0, 184, 216, 0.1)",
          borderColor: "rgb(0, 184, 216)",
          data: [0, 0, 0, 0, 0, 0, 0]
        }
      ]
    },
    {
      label: "Word of the day",
      value: engagement.wodcontestsTakenCount,
      percentage: "12.4",
      increase: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "6", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(23,198,113,0.1)",
          borderColor: "rgb(23,198,113)",
          data: [0, 0, 0, 0, 0, 0, 0]
        }
      ]
    },
    {
      label: "Most likely to",
      value: engagement.mltcontestsTakenCount,
      percentage: "3.8%",
      increase: true,
      decrease: false,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "4", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,180,0,0.1)",
          borderColor: "rgb(255,180,0)",
          data: [0, 0, 0, 0, 0, 0, 0]
        }
      ]
    },
    {
      label: "Party Prank Picture",
      value: engagement.pppcontestsTakenCount,
      percentage: "2.71%",
      increase: false,
      decrease: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "4", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,65,105,0.1)",
          borderColor: "rgb(255,65,105)",
          data: [0, 0, 0, 0, 0, 0, 0]
        }
      ]
    },
    {
      label: "Never have I ever",
      value: engagement.nevercontestsTakenCount,
      percentage: "2.4%",
      increase: false,
      decrease: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "4", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgb(0,123,255,0.1)",
          borderColor: "rgb(0,123,255)",
          data: [0, 0, 0, 0, 0, 0, 0]
        }
      ]
    }
  ])
  

  return (
    loading && engagement === null ? <Spinner /> :
    <React.Fragment>
    {error !== true ? 
    <React.Fragment>
          <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <PageTitle title="Workspace Engagement Report" subtitle="Ricotta Games and Trivia" className="text-sm-left mb-3" />
        </Row>

      
        <Row>
          {smallStats1.map((stats, idx) => (
            <Col className="col-lg mb-4" key={idx} {...stats.attrs}>
              <SmallStats
                id={`small-stats-${idx}`}
                variation="1"
                chartData={stats.datasets}
                chartLabels={stats.chartLabels}
                label={stats.label}
                value={stats.value}
                percentage={stats.percentage}
                increase={stats.increase}
                decrease={stats.decrease}
              />
            </Col>
          ))}
        </Row>

        <Row>
          {smallStats2.map((stats, idx) => (
            <Col className="col-lg mb-4" key={idx} {...stats.attrs}>
              <SmallStats
                id={`small-stats-${idx}`}
                variation="1"
                chartData={stats.datasets}
                chartLabels={stats.chartLabels}
                label={stats.label}
                value={stats.value}
                percentage={stats.percentage}
                increase={stats.increase}
                decrease={stats.decrease}
              />
            </Col>
          ))}
        </Row>

        <Row>
          {smallStats3.map((stats, idx) => (
            <Col className="col-lg mb-4" key={idx} {...stats.attrs}>
              <SmallStats
                id={`small-stats-${idx}`}
                variation="1"
                chartData={stats.datasets}
                chartLabels={stats.chartLabels}
                label={stats.label}
                value={stats.value}
                percentage={stats.percentage}
                increase={stats.increase}
                decrease={stats.decrease}
              />
            </Col>
          ))}
        </Row>

        <Row>
        
          

        </Row>
        <Row noGutters className="page-header py-4" id="eScoreDesc">
              <h4>What is Workspace Engagement Score?</h4>
              <p>Workspace Engagement Score measures the frequency of the games played and the number of users who participated in the game or trivia. <br />The higher the Workspace Engagement Score, the more the engagement, bonding and fun at your workplace!</p>
              <h4>How is it calculated?</h4>
              <p>The scores are calculated on a point basis. The points increase as the number of games played and participants increase. <br /> <br /> For example: If 20 people in your workspace complete a Trivia contest, your score increases by 20 points, since 20 users were engaged. The more Games and Trivia your workspace users play, the higher your Workspace Engagement Score.</p>
              <h4>How can you improve your Workspace Engagement Score?</h4>
              <p>1. Let your workspace members know about Ricotta Games and Trivia and encourage them to use it <br />2. Post Ice-breakers and Social Games often on public channels. <br />3. Auto-Schedule Trivia and Photo Quiz, several times a week. <br />4. Keep posting the Overall Trivia leaderboard on a public channel <br />5. Reward winners of Trivia Contests or Social Games with gift vouchers, a free coffee or a free lunch.<br />6 Encourage your team to participate in the weekly trivia contests</p>
          </Row>
      </Container>
    </React.Fragment> : <Errors />}
    </React.Fragment>
  )
}



BlogOverview.propTypes = {
  /**
   * The small stats dataset.
   */
  smallStats: PropTypes.array,
  smallStats2: PropTypes.array,
  engagement: PropTypes.object.isRequired

};

const mapStateToProps = state => ({
  engagement: state.engagement
});


export default connect(mapStateToProps)(BlogOverview);

