const axios = require('axios');

var axiosInstanceTodo = process.env.NODE_ENV === 'production' ? axios.create({
  baseURL: 'https://slackproductivity.ricotta.team'
}) : 
axios.create({
  baseURL: 'http://localhost:3000/'
})




module.exports = axiosInstanceTodo;