import React from "react";
import { Nav, NavItem, NavLink } from "shards-react";

import Notifications from "./Notifications";
import UserActions from "./UserActions";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout } from "../../../../../../actions/auth";
import Errors from "../../../../views/Errors";
import Spinner from "../../../../../layout-spinner/Spinner";

function NavbarNav({ auth: { loading, user }, logout }) {
  
  return ( loading ? <Spinner /> : loading === false && user !== null ?
    <React.Fragment>
    <Nav navbar className="md-mr-3 ml-auto navItems">
            <NavItem className = "mt-2">
                  {typeof user !== 'undefined' && user !==null && user.workspaceName !== null ? user.workspaceName : console.log('nada')}
            </NavItem>
              <NavItem>
              <NavLink className = "text-center mt-n2 mr-md-2" onClick={logout} href='#!'>
              {(
                <div
                  className="d-inline-block item-icon-wrapper"
                  dangerouslySetInnerHTML={{ __html: '<i class="material-icons">logout</i>' }}
                />)}
              {<span>{" Logout"}</span>}
                </NavLink>
            </NavItem>
    {/* <Notifications /> */}
    {/* <UserActions /> */}
  </Nav>
  </React.Fragment> : <Errors />
  )
}


NavbarNav.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { logout }
)(NavbarNav);



