import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { wikilogin } from '../actions/auth';
import Spinner from './layout-spinner/Spinner';

// app.ricotta.team/trivia/billing or app.ricotta.team/trivia/dashboard

// the onSubmit part will go into pagebilling

const WikiPageBilling = ({ wikilogin, wikiisAuthenticated, wikiisLoading }) => {
    useEffect(() => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let code = params.get('code');
        wikilogin(code);

        // window.location.href = "http://stackoverflow.com";

    }, []);

 


    if (wikiisAuthenticated) {
        return <Redirect to='/wiki/dashboard/index' />;
    }

    return <Spinner />
};

WikiPageBilling.propTypes = {
    wikilogin: PropTypes.func.isRequired,
    wikiisAuthenticated: PropTypes.bool,
    wikiisLoading: PropTypes.bool
  };
  
const mapStateToProps = state => ({
    wikiisAuthenticated: state.auth.wikiisAuthenticated,
    wikiisLoading: state.auth.wikiloading
});

export default connect(mapStateToProps, {wikilogin})(WikiPageBilling);
