import React from 'react';
import { Container, Row, Col } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import BlogPosts from "./BlogPosts";

function BlogView() {
    return (
        <React.Fragment>
        <Container fluid className="main-content-container px-4">
            <Row noGutters className="page-header py-4">
            <PageTitle title="Team Building and Employee Engagement" subtitle="The Ricotta Blog" className="text-sm-left mb-3" />
            </Row>
     
            <Row>
                <Col lg="12" md="12" sm="12" className="mb-4">
                    <BlogPosts />
                </Col>
            </Row>
        </Container>
        </React.Fragment>
    )
}

export default BlogView
