import React, { useState, useEffect } from 'react';
import {
     Button
  } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BillingActiveMessage from './BillingActiveMessage';
import { wikiloadUser } from '../../../actions/auth';
import Spinner from "../../layout-spinner/Spinner"
import { loadStripe } from '@stripe/stripe-js';
const axios = require('axios');
const axios4 = require('../../../config/axiosInstance4');
const stripePromise = loadStripe('pk_live_oYyuovMeNVaQe5SSflWCE8XD00edZonWk9');


function BillingPricesWiki({ auth: { wikiuser, wikiloading } }) {
    const [show, setShow] = React.useState();
    const [billing, setBilling] = React.useState("annually");
    const [country, setCountry] = React.useState("USA");
    const [stripeLoading, setstripeLoading] = useState(false);
    // const [dummyTU, setDummyTU] = useState({isBillingActive: false, isUserLevelBillingActive: true, numUsers: 324});


      useEffect( () => {
        (async () => {
          var ipRes = await axios.get('https://ipapi.co/json/')
          ipRes.status === 200 ? setCountry(ipRes.data.country_name) : setCountry("Not found")
        })();
        
       
      }, [])

     
    
        // early stage annually
    
      const handleClickPriceM = async (event) => {
        setstripeLoading(true)
        console.log('handleprice2')

        // When the customer clicks on the button, redirect them to Checkout.
        const stripe = await stripePromise;
        // old price
        
        const priceIdM1 = country === 'India' ? 'price_1KQsWAJBEzpLhH7BkkU8EsJ2' : 'price_1KQsQLJBEzpLhH7BV6NAfjlE'
        const priceIdM2 = country === 'India' ? 'price_1KQsWAJBEzpLhH7Bf9XWDpWw' : 'price_1KQsQLJBEzpLhH7BIiXPXguw'
        const priceIdM3 = country === 'India' ? 'price_1KQsWAJBEzpLhH7Bl23pSeEt' : 'price_1KQsQLJBEzpLhH7BLLf6VsUh'
        const priceIdM4 = country === 'India' ? 'price_1KQsWBJBEzpLhH7Ba9XttYh0' : 'price_1KQsQLJBEzpLhH7BxSJU4xGF'

        var lePriceM = wikiuser.numUsers < 51 ? priceIdM1 
          : wikiuser.numUsers < 241 ? priceIdM2
          : wikiuser.numUsers < 601 ? priceIdM3 : priceIdM4

        const configT = {
          headers: {
              'Content-Type': 'application/json',
          }
        };
        
        var rawBody = {
            priceId: lePriceM,
              slackTeamID: wikiuser.teamId,
              slackUserID: wikiuser.id,
              numUsers: wikiuser.numUsers,
              plan: 'wiki&workspaceMonthly'
        }

        if(wikiuser.email !== null && wikiuser.email !== 'email'){
          rawBody.email = wikiuser.email
        }
        else {
          console.log('nothing')
        }

        const body = JSON.stringify(rawBody);
    
 
    
        const res = await axios4.post('/api/checkoutSessionNew/wikisub', body, configT);
        var sessionId = res.data;
    
        const { error } = await stripe.redirectToCheckout({
          sessionId: sessionId
        });
    
        
      };
    
      // growth stage monthly
      const handleClickPriceA = async (event) => {
        setstripeLoading(true)
        // When the customer clicks on the button, redirect them to Checkout.
        const stripe = await stripePromise;
        const priceIdA1 = country === 'India' ? 'price_1KQsWBJBEzpLhH7Bkzr0jPVf' : 'price_1KQsQLJBEzpLhH7BZA492Iq3' 
        const priceIdA2 = country === 'India' ? 'price_1KQsWBJBEzpLhH7BbCe1ZBTg' : 'price_1KQsQLJBEzpLhH7BvoRuScXD' 
        const priceIdA3 = country === 'India' ? 'price_1KQsWBJBEzpLhH7BE9bUePQf' : 'price_1KQsQMJBEzpLhH7BTz6LRLeK' 
        const priceIdA4 = country === 'India' ? 'price_1KQsWBJBEzpLhH7BuBLxuVT7' : 'price_1KQsQLJBEzpLhH7Bmkfo9SxG' 

        var lePriceA = wikiuser.numUsers < 51 ? priceIdA1 
          : wikiuser.numUsers < 241 ? priceIdA2
          : wikiuser.numUsers < 601 ? priceIdA3 : priceIdA4
    
        const configT = {
          headers: {
              'Content-Type': 'application/json',
          }
        };

        var rawBody = {
          priceId: lePriceA,
          slackTeamID: wikiuser.teamId,
          slackUserID: wikiuser.id,
          numUsers: wikiuser.numUsers,
          plan: 'wiki&workspaceAnnually'
        }

        if(wikiuser.email !== null && wikiuser.email !== 'email'){
          rawBody.email = wikiuser.email
        }
        else {
          console.log('nothing')
        }

      const body = JSON.stringify(rawBody);
    
    
        
    
        const res = await axios4.post('/api/checkoutSessionNew/wikisub', body, configT);
        var sessionId = res.data;
    
        const { error } = await stripe.redirectToCheckout({
          sessionId: sessionId
        });
    
        
      };
    

    
      const buttonClass1 = {
        monthly: "btn btn-first",
        annually: "btn btn-second"
      }
    
      const buttonClass2 = {
        monthly: "btn btn-second",
        annually: "btn btn-first"
      }

      

    
      function handleMonthlyChange(evt) {
          setBilling("monthly");
      }
    
      function handleAnnuallyChange(evt) {
        setBilling("annually");
      }
    
      function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
 
      
      function pricingCta(button) {
          var fn = button === 2 ? handleClickPriceM : handleClickPriceA
           

           return (<Button className="btn btn-block btn-primary text-uppercase" onClick={fn}>{stripeLoading && (
            <i
            className="fa fa-refresh fa-spin"
            style={{ marginRight: "5px" }}
            />
        )}
        {stripeLoading && <span>Loading...</span>}
        {!stripeLoading && <span>Select plan</span>}</Button>)
      }
  
    
      var currency = country === 'India' ? '₹' : '$'

      const priceM1 = country === 'India' ? 1200 : 24
      const priceM2 = country === 'India' ? 3000 : 59
      const priceM3 = country === 'India' ? 6000 : 119
      const priceM4 = country === 'India' ? 9999 : 199

      const priceA1 = country === 'India' ? 9999 : 19*12
      const priceA2 = country === 'India' ? 29999 : 49*12
      const priceA3 = country === 'India' ? 49999 : 99*12
      const priceA4 = country === 'India' ? 99999 : 159*12


      var monthlyWorkspacePrice = wikiuser.numUsers < 51 ? priceM1 
      : wikiuser.numUsers < 241 ? priceM2
      : wikiuser.numUsers < 601 ? priceM3 : priceM4

      var annualWorkspacePrice = wikiuser.numUsers < 51 ? priceA1 
      : wikiuser.numUsers < 241 ? priceA2
      : wikiuser.numUsers < 601 ? priceA3 : priceA4

 

    return (
        wikiloading ? <Spinner /> : wikiloading === false && wikiuser.isBillingActive === true ? <BillingActiveMessage user={wikiuser} /> 
          :  
        (<React.Fragment>        
          <section className="pricing py-4">
            <div className="container">
              {/* <div className="row d-flex justify-content-center">
                <div class="btn-group" role="group" aria-label="Basic example">
                  <button type="button" class={buttonClass1[billing]} onClick={handleMonthlyChange}>Personal Plan</button>
                  <button type="button" class={buttonClass2[billing]} onClick={handleAnnuallyChange}>Workspace Plan</button>
                </div>
              </div>  */}
              <div className="mt-3 text-center"><p>You have <b>{wikiuser.numUsers} users </b> in your Slack Workspace. Please contact us if you wish to subscribe for fewer number of users in your Workspace. <br />We offer special discounts to Educational Institutions and Non-Profits. Please write to us on <b><span className="ricotta-color">cheese@ricotta.team</span></b></p></div>
              {/* <div className="mt-3 text-center"><p>Black Friday Sale! Use Code <b><span className="ricotta-color">BLACKFRIDAY30</span></b> to get <b>30% Off</b> on Annual Plans. Valid till <b>4th December</b> for new subscriptions!</p></div> */}

              {/* {country === 'India' ? 
                (<div className="mt-3 text-center">
                   Switch to Annual plans and save <b><span className="ricotta-color">20% on billing</span></b>
                  </div>) : 
                  (<div className="mt-3 text-center">
                   Switch to Annual plans and save <b><span className="ricotta-color">20% on billing</span></b>
                  </div>)}  */}
              <div className="row mt-4">

                
                <div className="col-lg-6">
                  <div className="card mb-5 mb-lg-0">
                    <div className="card-body">
                      <h5 className="card-title text-uppercase text-center">Workspace Plan - Monthly</h5>
                      <h6 className="card-price text-center">{currency}{monthlyWorkspacePrice}<span className="period">/month </span></h6>
                      <hr />
                      <ul className="fa-ul">
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Workspace Plan</li>
                          <li><span className="fa-li"><i className="fa fa-check"></i></span>Monthly Subscription</li>
                          <li><span className="fa-li"><i className="fa fa-check"></i></span>Manage Wiki without leaving Slack</li>
                          <li><span className="fa-li"><i className="fa fa-check"></i></span>Create, Search and Share Wiki</li>
                          <li><span className="fa-li"><i className="fa fa-check"></i></span>Virtual Bulletin Board</li>
                          <li><span className="fa-li"><i className="fa fa-check"></i></span>End-to-end Data Encryption</li>
                      </ul>
                      {pricingCta(2)}
                      {/* <Button className="btn btn-block btn-primary text-uppercase" href="#">Sign up</Button> */}
                    </div>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="card mb-5 mb-lg-0">
                    <div className="card-body">
                      <h5 className="card-title text-uppercase text-center">Workspace Plan - Annually</h5>
                      <h6 className="card-price text-center">{currency}{annualWorkspacePrice}<span className="period">/year </span></h6>
                      <hr />
                      <ul className="fa-ul">
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Workspace Plan</li>
                          <li><span className="fa-li"><i className="fa fa-check"></i></span>Annual Subscription</li>
                          <li><span className="fa-li"><i className="fa fa-check"></i></span>Manage Wiki without leaving Slack</li>
                          <li><span className="fa-li"><i className="fa fa-check"></i></span>Create, Search and Share Wiki</li>
                          <li><span className="fa-li"><i className="fa fa-check"></i></span>Virtual Bulletin Board</li>
                          <li><span className="fa-li"><i className="fa fa-check"></i></span>End-to-end Data Encryption</li>
                      </ul>
                      {pricingCta(3)}
                    </div>
                  </div>
                </div>
                
                
              </div>
            </div>
          </section>
      </React.Fragment> )
    )
}


BillingPricesWiki.propTypes = {
  auth: PropTypes.object.isRequired,
  wikiloadUser: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { wikiloadUser })(BillingPricesWiki);


