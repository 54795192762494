import React, { Component } from 'react';
import { Media } from 'reactstrap';

class Dashboard extends Component {
    constructor(props) {
  super(props);
  this.state = { width: 0, height: 0 };
  this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
}

componentDidMount() {
  this.updateWindowDimensions();
  window.addEventListener('resize', this.updateWindowDimensions);
}

componentWillUnmount() {
  window.removeEventListener('resize', this.updateWindowDimensions);
}

updateWindowDimensions() {
  this.setState({ width: window.innerWidth, height: window.innerHeight });
}
    
    render(){
        
        
        return(
           <div className = "container py-lg-4">
            
            <Media className = "text-center col-12 mt-3">
            <img src="/assets/mainView.png" className = "img-fluid" alt="Ricotta OKRs" />
            </Media>
            </div>
        );
    }
}

export default Dashboard;