export default function() {
  return [
    {
      title: "Ricotta Blog",
      to: "/taskboards/dashboard/index",
      htmlBefore: '<i class="material-icons">article</i>',
      htmlAfter: ""
    },
    {
      title: "Taskboards Billing Dashboard",
      htmlBefore: '<i class="material-icons">credit_card</i>',
      to: "/taskboards/dashboard/billing",
      htmlAfter: ""
    }
  ];
}


    // {
    //   title: "Forms & Components",
    //   htmlBefore: '<i class="material-icons">view_module</i>',
    //   to: "/components-overview",
    // },
    // {
    //   title: "Tables",
    //   htmlBefore: '<i class="material-icons">table_chart</i>',
    //   to: "/tables",
    // },
    // {
    //   title: "User Profile",
    //   htmlBefore: '<i class="material-icons">person</i>',
    //   to: "/user-profile-lite",
    // },
    // {
    //   title: "Errors",
    //   htmlBefore: '<i class="material-icons">error</i>',
    //   to: "/errors",
    // }
