export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOAD_ENGAGEMENT = 'LOAD_ENGAGEMENT';
export const ENGAGEMENT_ERROR = 'ENGAGEMENT_ERROR';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';
export const LOGOUT = 'LOGOUT';

export const TODO_USER_LOADED = 'TODO_USER_LOADED';
export const TODO_AUTH_ERROR = 'TODO_AUTH_ERROR';
export const TODO_LOGIN_SUCCESS = 'TODO_LOGIN_SUCCESS';
export const TODO_LOGIN_FAIL = 'TODO_LOGIN_FAIL';
export const TODO_LOGOUT = 'TODO_LOGOUT';


export const WIKI_USER_LOADED = 'WIKI_USER_LOADED';
export const WIKI_AUTH_ERROR = 'WIKI_AUTH_ERROR';
export const WIKI_LOGIN_SUCCESS = 'WIKI_LOGIN_SUCCESS';
export const WIKI_LOGIN_FAIL = 'WIKI_LOGIN_FAIL';
export const WIKI_LOGOUT = 'WIKI_LOGOUT';



export const TASKBOARDS_USER_LOADED = 'TASKBOARDS_USER_LOADED';
export const TASKBOARDS_AUTH_ERROR = 'TASKBOARDS_AUTH_ERROR';
export const TASKBOARDS_LOGIN_SUCCESS = 'TASKBOARDS_LOGIN_SUCCESS';
export const TASKBOARDS_LOGIN_FAIL = 'TASKBOARDS_LOGIN_FAIL';
export const TASKBOARDS_LOGOUT = 'TASKBOARDS_LOGOUT';