import React, { Component , useState, useEffect } from 'react';
import { Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  NavbarText, Jumbotron, Button, Col, 
  Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import LandingForm from './form';
import Features from './features';
import BlogLink from './bloglink';
import TriviaPricing from './trivia-pricing';
import CTA from './cta';
import Footer from './footer';
import { Link as ScrollLink } from "react-scroll";
import ReactGA from 'react-ga';
import FormComponent from './formComponent';
import TriviaQuestion from './trivia-question';
import TriviaFeatures from './trivia-features';
import TriviaDashboard from './trivia-dashboard';
import TriviaFooter from './trivia-footer';
import Typewriter from 'typewriter-effect';
import TriviaHeading from './trivia-heading';
import TriviaWhy from './trivia-why';
import ShowLogos from './show-logos';

import {Helmet} from "react-helmet";
const axios = require('axios');




export default function TriviaHeader() {
  console.log(ReactGA);

  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(true)
  };

  const handleClose = () => setModalOpen(false);

 

  function toggle() {
    setOpen(!open);
  }

  function handleHeaderButtonClick() {
    ReactGA.event({
      category: "App Page - Trivia Header Button Click",
      action: "Clicked on trivia header button",
    })
    window.open('https://slack.com/oauth/v2/authorize?client_id=791862583680.1579546743637&scope=channels:read,chat:write,chat:write.public,im:read,im:write,mpim:read,mpim:write,users:read,users:read.email&user_scope=')
  }



  return(
    <React.Fragment>
    <div className="trivia-header cheese-bg">
          {/* <Helmet>
            <title>Ricotta Trivia - Engage remote teams with trivia on Slack</title>
            <meta
              name="description"
              content="Ricotta Trivia helps remote teams have fun by hosting quiz contests and creating custom ice-breakers on Slack."
            />
            <meta property="og:site_name" content="Ricotta Trivia" />
            <meta property="og:title" content="Trivia quiz application for Slack" />
            <meta property="og:description" content="Engage your remote team with some trivia and ice-breakers" />
            <meta property="og:url" content="https://ricotta.team/trivia" />
            <meta property="og:image" content="https://ricotta.team/%PUBLIC_URL%/assets/trivia-dashboard-min.png" />
            </Helmet> */}
   
      <Navbar className = "navbar" light expand="lg">
        
        
        <NavbarBrand className = "ml-md-4" href="/"><span><img src="/assets/cheese-512.png" width={40} alt="Ricotta" /> <span className="ricotta-color">Ricotta Trivia</span></span></NavbarBrand>
      
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={open} navbar>
          <Nav className="md-mr-3 ml-auto navItems" navbar>
            
             {/* <NavItem>
              <NavLink className = "mr-md-2" href="http://ricotta.team/" target="_blank">
                Ricotta OKR
                </NavLink>
            </NavItem> */}
            <NavItem>
            <NavLink className = "mr-md-2" href="#">
              <ScrollLink
                activeClass="active"
                to="features"
                spy={true}
                smooth={true}
                duration={500}
                >
                Features
                </ScrollLink>
              </NavLink>
            </NavItem>
            <NavItem>
            <NavLink className = "mr-md-2" href="#">
              <ScrollLink
                activeClass="active"
                to="pricing"
                spy={true}
                smooth={true}
                duration={500}
                >
                Pricing
                </ScrollLink>
              </NavLink>
            </NavItem>
            <NavItem>
            <NavLink className = "mr-md-2" href="#">
              <ScrollLink
                activeClass="active"
                to="trivia-question"
                spy={true}
                smooth={true}
                duration={500}
                >
                Quiz
                </ScrollLink>
                </NavLink>
            </NavItem>
            <NavItem>
              <button className="btn btn-sm mr-md-2 navbar-btn" type="button" onClick={handleHeaderButtonClick}><b>Add to Slack</b></button>
            </NavItem>
            
           
            
       
          </Nav>
        </Collapse>
      </Navbar>
      {/* <hr/>   */}

    <div className ="jumbotron jumbotron-fluid"> 
        <div className ="container">
          {/* <h1 className="landing-header-trivia">Miss having fun at work and those water cooler talks?</h1> */}
          {/* <h1 className="landing-header-trivia">Engage your <span className="ricotta-color">remote team</span> with some <span className="ricotta-color">trivia and ice-breakers</span></h1> */}
          {/* <h1 className="landing-header-trivia"><Typewriter
            options={{
              strings: ['Miss having fun at the workplace?', 'Engage your remote team with some trivia and ice-breakers'],
              autoStart: true,
              delay: 20,
              deleteSpeed: 1,
              pauseFor: 2000,
              loop: true,
            }}
          /></h1> */}
          <h1>
          <Typewriter
            options={{
              delay: 15,
            }}
            onInit={(typewriter) => {
            typewriter.typeString('Miss having <span style="color: #E04F60;">fun</span> at the workplace?')
              .pauseFor(2000)
              .deleteAll(1)
              .typeString('Engage your remote team with <span style="color: #E04F60;">trivia and ice-breaker games</span>')
              .start();
          }}
          />
          </h1>
          {/* <TriviaHeading /> */}
          <p className ="lead mt-3 landing-header2">Host <b>asynchronous Trivia quizzes</b>, play <b>Icebreaker games</b> and outperform your team on <b>Ricotta weekly!</b> All on<span><img src="/assets/slack.svg" width={96} alt="Slack" /></span> </p>
        </div> 
        <div className="mt-sm-4 mt-md-4 mt-lg-4 mt-xl-4 mt-2"><Col xs = {{size: 12 }} sm = {{size: 8, offset: 2 }} md={{size: 6, offset: 3 }} lg={{size: 6, offset: 3 }} xl={{size: 4, offset: 4 }}><div class="col-sm-12 mx-sm-auto col-md-12 mx-md-auto col-lg-9 mx-lg-auto col-xl-9 mx-xl-auto col-11 mx-auto">
          <Button className = "btn-lg btn-block signUpButton2" onClick={handleHeaderButtonClick}>Add Ricotta Trivia to Slack</Button>
        <small>14 days free trial. No credit card required</small></div></Col>
        </div>
  </div>

    <TriviaDashboard />  
    <hr/> 
    </div>

    {/* <div>
    <ShowLogos />
    <hr/> 
    </div> */}
    

    <div id="features" className="trivia-header">     
    <TriviaFeatures />    
    </div> 
    {/* <hr/>
    <div id="trivia-why">     
    <TriviaWhy />    
    </div>  */}
    <hr/>  
    <div id="pricing">    
    <TriviaPricing />    
    </div> 
    <hr/>
    <div id="trivia-question">  
    <TriviaQuestion />    
    </div> 
    <div> 
    <TriviaFooter/>
    </div>
    

    
        
      
    </React.Fragment>
    
  );

}