import React, { Component } from 'react';
import { Media, Col, Row, Button } from 'reactstrap';
import ReactGA from 'react-ga';


class BlogLink extends Component {
    constructor(props) {
  super(props);
  this.state = { width: 0, height: 0 };
  this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
}

componentDidMount() {
  this.updateWindowDimensions();
  window.addEventListener('resize', this.updateWindowDimensions);
}

componentWillUnmount() {
  window.removeEventListener('resize', this.updateWindowDimensions);
}

updateWindowDimensions() {
  this.setState({ width: window.innerWidth, height: window.innerHeight });
}
    
    render(){
    const imgStyle = {
  maxWidth: this.state.width
}    

function handleBlogLinkClick() {
  ReactGA.event({
    category: "Blog link Click",
    action: "Clicked on blog link button",
  })
  window.open('https://medium.com/@ricottahq/what-are-okrs-and-why-should-your-team-adopt-them-7e12474b4393')
}
        
        
        return(
<React.Fragment>    
<div className = "container mt-xs-1 mb-xs-1 mt-lg-5 mb-lg-5">   
    <Row>   
        <Col xs = {{size: 12}} md = {12} className = "align-self-center">   
            <h4 className = "featureHeading text-center"><b>What are OKRs and why should your company adopt them?</b></h4>
            <p className ="lead mt-3">OKRs - Objectives and Key Results - is an effective goal-setting framework that can help your organisation get focused, aligned and engaged in what matters the most. The best practice of OKRs is to set aspirational goals with measurable key results.</p>
            <div class="readMoreButtonDiv"><Button className = "btn-lg readMoreButton" onClick={handleBlogLinkClick}>Read more</Button></div>
        </Col>          
    </Row>        
</div>
        </React.Fragment>    
        );
    }
}

export default BlogLink;