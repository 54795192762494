import React, { Component } from 'react';
import { Media, Col, Row } from 'reactstrap';

class ShowLogos extends Component {
    constructor(props) {
  super(props);
  this.state = { width: 0, height: 0 };
  this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
}

componentDidMount() {
  this.updateWindowDimensions();
  window.addEventListener('resize', this.updateWindowDimensions);
}

componentWillUnmount() {
  window.removeEventListener('resize', this.updateWindowDimensions);
}

updateWindowDimensions() {
  this.setState({ width: window.innerWidth, height: window.innerHeight });
}
    
    render(){
    const imgStyle = {
  maxWidth: this.state.width
}    
        
        
        return(
<React.Fragment>  
<div className = "container text-center mt-xs-1 mb-xs-1 mt-lg-5 mb-lg-5"> 
    <h1 className="landing-header-trivia"><span className="ricotta-color">1000+ teams use Ricotta Trivia on Slack</span></h1>
</div>
<div className = "container mt-xs-1 mb-xs-1 mt-lg-5 mb-lg-5">   
    <Row>  
        <Col xs = {{size: 4}} md ={{ size: 2}} className = "text-center">    
            <Media className = "mt-2">
                <img src="/assets/Amazon_Logo.jpg" className = "img-fluid img-trivia" alt="Ricotta on Slack" />
            </Media>
        </Col>  
        <Col xs = {{size: 4}} md ={{ size: 2}} className = "text-center">    
            <Media className = "mt-2">
                <img src="/assets/Squakr_Logo.jpg" className = "img-fluid img-trivia" alt="Ricotta on Slack" />
            </Media>
        </Col>
        <Col xs = {{size: 4}} md ={{ size: 2}} className = "text-center">    
            <Media className = "mt-2">
                <img src="/assets/Haven_Logo.jpg" className = "img-fluid img-trivia" alt="Ricotta on Slack" />
            </Media>
        </Col>  
        <Col xs = {{size: 4}} md ={{ size: 2}} className = "text-center">    
            <Media className = "mt-2">
                <img src="/assets/Clarify_Logo.jpg" className = "img-fluid img-trivia" alt="Ricotta on Slack" />
            </Media>
        </Col>
        <Col xs = {{size: 4}} md ={{ size: 2}} className = "text-center">    
            <Media className = "mt-2">
                <img src="/assets/Gebhardt_Logo.jpg" className = "img-fluid img-trivia" alt="Ricotta on Slack" />
            </Media>
        </Col>  
        <Col xs = {{size: 4}} md ={{ size: 2}} className = "text-center">    
            <Media className = "mt-2">
                <img src="/assets/SDG_Logo.jpg" className = "img-fluid img-trivia" alt="Ricotta on Slack" />
            </Media>
        </Col>
    </Row>        
</div>  

        </React.Fragment>    
        );
    }
}

export default ShowLogos;