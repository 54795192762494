var quizQuestion = (r) => {
    const quizArray = [{
        "question": "Which is the capital of Finland?",
        "optionA": "Stockholm",
        "optionB": "Helsinki",
        "optionC": "London",
        "optionD": "Rome",
        "correctAnswer": "Helsinki"
    },
    {
        "question": "How many lines usually make up a limerick?",
        "optionA": "Three",
        "optionB": "Four",
        "optionC": "Five",
        "optionD": "Six",
        "correctAnswer": "Five"
    },
    {
        "question": "Which sportswear manufacturer uses the slogan 'Just do it'?",
        "optionA": "Reebok",
        "optionB": "Adidas",
        "optionC": "Puma",
        "optionD": "Nike",
        "correctAnswer": "Nike"
    },
    {
        "question": "Which digit does not exist in Roman Numerals?",
        "optionA": "Nine",
        "optionB": "Seven",
        "optionC": "Zero",
        "optionD": "Eight",
        "correctAnswer": "Zero"
    },
    {
        "question": "Which surface is the Mona Lisa painted on?",
        "optionA": "Canvas",
        "optionB": "Paper",
        "optionC": "Wood",
        "optionD": "Cloth",
        "correctAnswer": "Wood"
    },
    {"question": "Before the Euro, what was the currency of Austria?","optionA": "Lira","optionB": "Schilling","optionC": "Mark","optionD": "Peseta","correctAnswer": "Schilling"},
    {"question": "What is a group of frogs called?","optionA": "Army","optionB": "Swarm","optionC": "Colony","optionD": "Pack","correctAnswer": "Army"},
    {"question": "What is the longest river in Italy?","optionA": "Po","optionB": "Tiber","optionC": "Piave","optionD": "Arno","correctAnswer": "Po"},
    {"question": "Who directed the movie 'The Godfather'?","optionA": "Francis Ford Coppola","optionB": "Martin Scorsese","optionC": "Stanley Kubrick","optionD": "Steven Spielberg","correctAnswer": "Francis Ford Coppola"},
    {
        "question": "Which digit does not exist in Roman Numerals?",
        "optionA": "Nine",
        "optionB": "Seven",
        "optionC": "Zero",
        "optionD": "Eight",
        "correctAnswer": "Zero"
    }]

    return quizArray[r];
}



module.exports = quizQuestion;
