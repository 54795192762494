import React, { Component } from 'react';
import { Media, Col, Row } from 'reactstrap';

class Features extends Component {
    constructor(props) {
  super(props);
  this.state = { width: 0, height: 0 };
  this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
}

componentDidMount() {
  this.updateWindowDimensions();
  window.addEventListener('resize', this.updateWindowDimensions);
}

componentWillUnmount() {
  window.removeEventListener('resize', this.updateWindowDimensions);
}

updateWindowDimensions() {
  this.setState({ width: window.innerWidth, height: window.innerHeight });
}
    
    render(){
    const imgStyle = {
  maxWidth: this.state.width
}    
        
        
        return(
<React.Fragment>  

<div className = "container mt-xs-1 mb-xs-1 mt-lg-5 mb-lg-5">   
    <Row>   
        <Col xs = {{size: 12}} md = {6} className = "align-self-center">   
            <h4 className = "featureHeading"><b>Everything can be done on Slack</b></h4>
            <p className ="lead mt-3">Employees can access all the functionalities of Ricotta on Slack and don’t have to login to a separate website, making it easier to implement and adopt Ricotta among teams</p>
        </Col> 
        <Col xs = {{size: 12}} md ={{ size: 5, offset: 1 }}  className = "text-center">    
            <Media>
                <img src="/assets/focus1.svg" className = "img-fluid" alt="Ricotta on Slack" />
            </Media>
        </Col>           
    </Row>        
</div>
<div className = "container mt-xs-1 mb-xs-1 mt-lg-5 mb-lg-5">   
    <Row>   
        
        <Col xs = {{size: 12}} md = {{size: 6}} className = "align-self-center">   
            <h4 className = "featureHeading"><b>Data Privacy and Encryption</b></h4>
            <p className ="lead mt-3">All OKRs and To-dos data is end-to-end encrypted, ensuring that your data remains private and secure.</p>
        </Col>  
        <Col xs = {{size: 12}} md ={{ size: 5, offset: 1 }}>    
            <Media>
            <img src="/assets/data_privacy.svg" className = "img-fluid" alt="Ricotta on Slack" />
            </Media>
        </Col>    
    </Row>        
</div>
<div className = "container mt-xs-1 mb-xs-1 mt-lg-5 mb-lg-5">   
    <Row>   
        
        <Col xs = {{size: 12}} md = {{size: 6}} className = "align-self-center">   
            <h4 className = "featureHeading"><b>Automated OKR Reminders</b></h4>
            <p className ="lead mt-3">Automated OKR reminders on Slack mean you spend little time on OKR implementation and more time on growing your business.</p>
        </Col>  
        <Col xs = {{size: 12}} md ={{ size: 5, offset: 1 }}>    
            <Media>
            <img src="/assets/reminder.svg" className = "img-fluid" alt="Ricotta on Slack" />
            </Media>
        </Col>    
    </Row>        
</div>
<div className = "container mt-xs-1 mb-xs-1 mt-lg-5 mb-lg-5">   
    <Row>   
        <Col xs = {{size: 12}} md = {6} className = "align-self-center">   
            <h4 className = "featureHeading"><b>Collaborate easily</b></h4>
            <p className ="lead mt-3">Use Ricotta OKRs to ensure distributed remote teams collaborate smoothly and asynchronously</p>
        </Col> 
        <Col xs = {{size: 12}} md ={{ size: 5, offset: 1 }} className = "text-center">    
            <Media>
                <img src="/assets/collaborate.svg" className = "img-fluid" alt="Ricotta on Slack" />
            </Media>
        </Col>           
    </Row>        
</div>   
        </React.Fragment>    
        );
    }
}

export default Features;