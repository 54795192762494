import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from 'reactstrap/lib/Spinner';
import Errors from '../components/dashboard-trivia/views/Errors';
import ErrorsUnauth from '../components/dashboard-trivia/views/ErrorsUnauth';

const PrivateRouteTriviaURL = ({
  component: Component,
  auth: { isAuthenticated, loading, user },
  ...rest
}) => (
    loading === false ?  
  <Route
    {...rest}
    render={props =>
      isAuthenticated ? (user !== null && user.teamId === 'T01811Z114P' ? <Component {...props} /> : <ErrorsUnauth />) : <Redirect to="/trivia/billing" />
    }
  /> : null
);

PrivateRouteTriviaURL.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(PrivateRouteTriviaURL);