import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const PrivateRouteTaskboards = ({
  component: Component,
  auth: { taskboardsisAuthenticated, taskboardsloading },
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      taskboardsisAuthenticated ? <Component {...props} /> : <Redirect to="/taskboards/billing" />
    }
  />
);

PrivateRouteTaskboards.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(PrivateRouteTaskboards);