import React, { useState, useEffect } from 'react';
import {
  Button, Col, Navbar,
  NavbarBrand,
} from 'reactstrap';
const axios = require('axios');


export default function TriviaPortalBilling(props) {



  return (
    <React.Fragment>
      <div className="maincontainer">
        <div class="container-fluid">
            <div class="row no-gutter body-film">
               
                <div class="col-md-6 d-none d-md-flex billing-bg"></div>
                
                <div class="col-md-6 cheese-bg">
                    <div class="login d-flex align-items-center py-5">
                       
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-10 col-xl-7 mx-auto">
                                  <NavbarBrand href="https://ricotta.team/trivia" target="_blank"><span><img src="/assets/cheese-512.png" width={30} alt="Ricotta" /> </span> <span style={{color: '#E04F60'}}>Ricotta Trivia</span></NavbarBrand>
                                    <div className ="container billing-div-3">
                                      <p class="text-muted mt-3 mb-1">Please confirm your Slack account again.</p>
                                      <p class="text-muted mb-2">Sign in with Slack.</p>
                                    </div>
                                    <div className="mt-md-4 mt-2"><Col xs = {{size: 12}} md={{size: 12 }}><div className="col-md-12 mx-md-auto">
                                      <Button className = "btn-lg btn-block signUpButton2" href="https://slack.com/oauth/v2/authorize?client_id=791862583680.1579546743637&scope=users:read&state=updatebilling&redirect_uri=https://triviaapp.ricotta.team/api/auth">
                                        Sign in with<span><img src="/assets/slack_white.svg" width={80} height={30} alt="Slack" /></span></Button></div></Col></div>
                                   
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
       

    
    
    </React.Fragment>
  );
}