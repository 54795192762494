import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { login } from '../actions/auth';
import Spinner from './layout-spinner/Spinner';

// app.ricotta.team/trivia/billing or app.ricotta.team/trivia/dashboard

// the onSubmit part will go into pagebilling

const TriviaPageBilling = ({ login, isAuthenticated, isLoading }) => {
    useEffect(() => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let code = params.get('code');
        login(code);

        // window.location.href = "http://stackoverflow.com";

    }, []);

 


    if (isAuthenticated) {
        return <Redirect to='/trivia/dashboard/index' />;
    }

    return <Spinner />
};

TriviaPageBilling.propTypes = {
    login: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
    isLoading: PropTypes.bool
  };
  
const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    isLoading: state.auth.loading
});

export default connect(mapStateToProps, {login})(TriviaPageBilling);
