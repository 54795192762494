const { WebClient } = require('@slack/web-api');
const token = 'xoxb-1273067035159-1542427508836-dnGArHZFqknLDqAqerg5gWQs';
const web = new WebClient(token);

export async function emailSignUpCall(email) {

        


        var theBlock = [
            {
                "type": "divider"
            },
            {
                "type": "section",
                "text": {
                    "type": "mrkdwn",
                    "text": "*New Email sign-up for demo*"
                }
            },
            {
                "type": "divider"
            },
            {
                "type": "section",
                "fields": [
                    {
                        "type": "plain_text",
                        "text": `Email: ${email}`,
                        "emoji": true
                    }
                ]
            }
        ]

       


        const res = await web.chat.postMessage({ channel: '#ricotta-iq', 
            "blocks": theBlock
        });

        if(res.ts){
            return 'success';
        }
        else {
            return 'failure'
        }
        
    
  }