import React from "react";
import { Nav, NavItem, NavLink } from "shards-react";

import Notifications from "./Notifications";
import UserActions from "./UserActions";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { todologout } from "../../../../../../actions/auth";
import Errors from "../../../../views/Errors";
import Spinner from "../../../../../layout-spinner/Spinner";

function NavbarNav({ auth: { todoloading, todouser }, todologout }) {
  
  return ( todoloading ? <Spinner /> : todoloading === false && todouser !== null ?
    <React.Fragment>
    <Nav navbar className="md-mr-3 ml-auto navItems">
            <NavItem className = "mt-2">
                  {typeof todouser !== 'undefined' && todouser !==null && todouser.workspaceName !== null ? todouser.workspaceName : console.log('nada')}
            </NavItem>
              <NavItem>
              <NavLink className = "text-center mt-n2 mr-md-2" onClick={todologout} href='#!'>
              {(
                <div
                  className="d-inline-block item-icon-wrapper"
                  dangerouslySetInnerHTML={{ __html: '<i class="material-icons">logout</i>' }}
                />)}
              {<span>{" Logout"}</span>}
                </NavLink>
            </NavItem>
    {/* <Notifications /> */}
    {/* <UserActions /> */}
  </Nav>
  </React.Fragment> : <Errors />
  )
}


NavbarNav.propTypes = {
  todologout: PropTypes.func.isRequired,
  todoauth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { todologout }
)(NavbarNav);



