import React, { Component , useState, useEffect } from 'react';
import { Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  NavbarText, Jumbotron, Button, Col, 
  Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import LandingForm from './form';
import Dashboard from './dashboard';
import Features from './features';
import BlogLink from './bloglink';
import Pricing from './pricing';
import CTA from './cta';
import Footer from './footer';
import { Link as ScrollLink } from "react-scroll";
import ReactGA from 'react-ga';
import FormComponent from './formComponent';
import {Helmet} from "react-helmet";
const axios = require('axios');




export default function Header() {
  console.log(ReactGA);

  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(true)
  };

  const handleClose = () => setModalOpen(false);

  // const [country, setCountry] = useState("");

  // useEffect(() => {
    

  //   (async () => {
  //     var ipRes = await axios.get('https://ipapi.co/json/')
  //     ipRes.status === 200 ? setCountry(ipRes.data.country_name) : setCountry("Not found")
  //     console.log(country);
  //     //ipRes.data.country_name
  //   })();

  // }, []);



  // test 
  // // Similar to componentDidMount and componentDidUpdate:
  // useEffect(() => {

  //   (async () => {
  //     var ipRes = await axios.get('https://ipapi.co/json/')
  //     ipRes.status === 200 ? setCountry(ipRes.data.country_name) : setCountry("Not found")
  //     console.log(country);
  //   })();

  // });

  function toggle() {
    setOpen(!open);
  }

  function handleHeaderButtonClick() {
    ReactGA.event({
      category: "OKR Header Button Click",
      action: "Clicked on OKR header button",
    })
    window.open('https://slack.com/oauth/v2/authorize?client_id=791862583680.812221040258&scope=app_mentions:read,channels:read,chat:write,im:history,im:read,team:read,users:read,users:read.email')
  }



  return(
    <React.Fragment>
    <div>
          {/* <Helmet>
            <title>Ricotta - OKR software for remote teams on Slack</title>
            <meta
              name="description"
              content="Ricotta OKR software for setting and tracking objectives and key results. Easy Slack collaboration and high employee engagement. Focus on what matters most."
            />
            <meta property="og:site_name" content="Ricotta" />
            <meta property="og:title" content="OKR Software on Slack" />
            <meta property="og:description" content="Build high performing remote teams on Slack with Ricotta" />
            <meta property="og:url" content="https://ricotta.team" />
            <meta property="og:image" content="https://ricotta.team/%PUBLIC_URL%/assets/mainView.png" />
            </Helmet> */}
    <Navbar className = "navbar promo fixed-top" light expand="lg">
      <div className="container navbar-center">
      <span className="navbar-t">
        <b><span className="hide-text">Miss the daily water cooler talks at work?</span> Try Trivia <span className="hide-text">and custom ice-breakers</span> on Slack</b>
        <Button className="btn ml-md-3 ml-2 btn-sm promo-button" type="button" href="/trivia" target="_blank">Try Ricotta Trivia</Button>
      </span>
      </div>
    </Navbar>
      <Navbar className = "mt-5 navbar" light expand="lg">
        
        
        <NavbarBrand className = "ml-md-4" href="/"><span><img src="/assets/ricotta-logo-1.svg" width={140} alt="Ricotta" /> </span> </NavbarBrand>
      
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={open} navbar>
          <Nav className="md-mr-3 ml-auto navItems" navbar>
            {/* <NavItem>
              <NavLink className = "mr-md-2" href="#">What are OKRs?</NavLink>
            </NavItem> */}
             <NavItem>
            <NavLink className = "mr-md-2" href="#">
              <ScrollLink
                activeClass="active"
                to="bloglink"
                spy={true}
                smooth={true}
                duration={500}
                >
                What is OKR
                </ScrollLink>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className = "mr-md-2" href="https://medium.com/@ricottahq" target = "_blank">
                Blog
                </NavLink>
            </NavItem>
            <NavItem>
            <NavLink className = "mr-md-2" href="/pricing">
              Pricing
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className = "mr-md-2" href="http://docs.ricotta.team/" target="_blank">
                Help Docs
                </NavLink>
            </NavItem>
            <NavItem>
              <button className="btn btn-sm mr-md-2 navbar-btn" type="button" onClick={toggleModal}><b>Request a demo</b></button>
            </NavItem>
            
            
            {/* <NavItem>
            <NavLink className = "mr-md-2" href="#">
            <ScrollLink
              activeClass="active"
              to="cta"
              spy={true}
              smooth={true}
              duration={500}
              >
              Sign up
              </ScrollLink>
              </NavLink>
            </NavItem> */}
            
       
          </Nav>
        </Collapse>
      </Navbar>
    </div>
    {/* <Button className = "btn-lg btn-block signUpButton2" href="https://slack.com/oauth/v2/authorize?client_id=750150302018.1051512239702&redirect_uri=https://ricotta.team/billing&user_scope=identity.basic,identity.email"
        target = "_blank">Add Ricotta to<span><img src="/assets/slack_white.svg" width={80} height={43} alt="Slack" /></span></Button> */}

    <div className ="jumbotron jumbotron-fluid"> 
  <div className ="container">
    <h1 className="landing-header">Build <span className="ricotta-color">high-performing remote teams</span> with Ricotta OKR software</h1>
    <p className ="lead mt-3 landing-header2">Align your teams to focus on measurable goals, automate OKR management and increase employee engagement. All on<span><img src="/assets/slack.svg" width={96} alt="Slack" /></span> </p>
  </div> 
  <div className="mt-sm-4 mt-md-4 mt-lg-4 mt-xl-4 mt-2"><Col xs = {{size: 12 }} sm = {{size: 8, offset: 2 }} md={{size: 6, offset: 3 }} lg={{size: 4, offset: 4 }} xl={{size: 4, offset: 4 }}><div class="col-sm-12 mx-sm-auto col-md-12 mx-md-auto col-lg-12 mx-lg-auto col-xl-9 mx-xl-auto col-11 mx-auto">
      {/* <ReactGA.OutboundLink
            eventLabel="Header Add to Slack button"
            to="https://slack.com/oauth/v2/authorize?client_id=791862583680.812221040258&scope=app_mentions:read,channels:history,channels:read,dnd:read,groups:history,groups:read,im:history,im:read,mpim:history,mpim:read,reactions:read,team:read,users:read,users:read.email,chat:write&user_scope=users:read"
            target="_blank"> */}
      <Button className = "btn-lg btn-block signUpButton2" onClick={handleHeaderButtonClick}>Add Ricotta to Slack</Button>
        {/* </ReactGA.OutboundLink> */}
        <small>14 day free trial. No credit card required</small></div></Col>
        </div>
        <LandingForm open={modalOpen} handleClose = {handleClose}/>
    {/* {country === 'India' ? (<div className="mt-md-4 mt-2"><Col xs = {{size: 12 }} md={{size: 4, offset: 4 }}><div class="col-md-9 mx-md-auto col-11 mx-auto">
      <Button className = "btn-lg btn-block signUpButton2" href="https://slack.com/oauth/v2/authorize?client_id=791862583680.812221040258&scope=app_mentions:read,channels:history,channels:read,dnd:read,groups:history,groups:read,im:history,im:read,mpim:history,mpim:read,reactions:read,team:read,users:read,users:read.email,chat:write&user_scope=users:read"
        target = "_blank">Try Ricotta Beta on<span><img src="/assets/slack_white.svg" width={80} height={30} alt="Slack" /></span></Button><small>Beta users will get 50% off on all pricing plans</small></div></Col></div>) 
      : country !== "" ? (<LandingForm />) : console.log('nothing to show')} 
        <LandingForm /> */}
        
        <Dashboard />
</div>

    <hr/>  
    <div id="bloglink">     
    <BlogLink />    
    </div>       
    <hr/>  
    <div id="features">     
    <Features />    
    </div>  
    <hr/> 
    <div id="cta"> 
    <CTA/>
    </div>
    <hr/> 
    <div> 
    <FormComponent />
    </div>
    {/* <hr/> */}
    <div> 
    <Footer/>
    </div>
        
      
    </React.Fragment>
    
  );

}