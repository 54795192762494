import React, { useState, useEffect } from 'react';
import {
  Button, Col, Navbar,
  NavbarBrand,
} from 'reactstrap';


export default function PrivacyPolicy(props) {




  return (
    <React.Fragment>
        <Navbar className = "mt-2" light expand="lg">
            <NavbarBrand className = "ml-md-4" href="/"><span><img src="/assets/cheese-512.png" width={30} alt="Ricotta" /> </span> <span style={{color: '#E04F60'}}>Ricotta</span></NavbarBrand>
        </Navbar>
        <div className="container mt-4">
        <h1 className="privacy-header">Terms of Service and Privacy Policy of Ricotta</h1>

            <p>Ricotta operates the https://ricotta.team/ website, which provides <b>online project management and trivia quizzing softwares</b> as a service.</p>

            <p>This page is used to inform website visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service, the Ricotta website.</p>

            <p>If you choose to use our Service, then you agree to the collection and use of information in relation with this policy. The Personal Information that we collect are used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.</p>

            <h3>Information Collection and Use</h3>

            <p>For a better experience while using our Service, we may require you to provide us with certain personally identifiable information, including but not limited to your name and email address. The information that we collect will be used to contact or identify you.</p>

            <h3>Data Storage and Retention</h3>

            <p>We store your data on secure third-party storage systems and we take appropriate measures to adequately protect your data.</p>
            <p>We store your email id and other profile information to provide you with great user experience and also to communicate with you if and when required. We do not store any sensitive information like Slack messages and files outside of the Ricotta application.</p>
            <p>We retain your data as long as you remain an active user of our product.</p>
            <p>In case you unsubscribe to our OKR and project management product, we retain your data for 12 months to enable you to re-start using our product from where you left off. After 12 months of inactivity, we will no longer retain your data, unless requested otherwise.</p>
            <p>In case you unsubscribe to our trivia quizzing product, we retain your data for 3 months to enable you to re-start using our product from where you left off. After 3 months of inactivity, we will no longer retain your data, unless requested otherwise.</p>

            <h3>Data Access and Deletion requests</h3>

            <p>We will provide reasonable assistance to data access requests either for your internal usage or for regulatory compliance. In certain circumstances we reserve the right to charge a reasonable fee to comply with your requests. We will also provide reasonable assistance to data deletion requests.</p>
            <p>All data access and deletion requests shall be sent to <a href="mailto:cheese@ricotta.team">cheese@ricotta.team</a> and we will process your requests within 14 business days.</p>

            <h3>Log Data</h3>

            <p>We want to inform you that whenever you visit our Service, we collect information that your browser sends to us that is called Log Data. This Log Data may include information such as your computer’s Internet Protocol ("IP") address, browser version, pages of our Service that you visit, the time and date of your visit, the time spent on those pages, and other statistics.</p>

            <h3>Cookies</h3>

            <p>Cookies are files with small amount of data that is commonly used an anonymous unique identifier. These are sent to your browser from the website that you visit and are stored on your computer’s hard drive.</p>

            <p>Our website uses these "cookies" to collection information and to improve our Service. You have the option to either accept or refuse these cookies, and know when a cookie is being sent to your computer. If you choose to refuse our cookies, you may not be able to use some portions of our Service.</p>

            <p>For more general information on cookies, please read <a href="https://www.cookieconsent.com/what-are-cookies/">"What Are Cookies"</a>.</p>

            <h3>Service Providers</h3>

            <p>We may employ third-party companies and individuals due to the following reasons:</p>

            <ul>
                <li>To facilitate our Service;</li>
                <li>To provide the Service on our behalf;</li>
                <li>To perform Service-related services; or</li>
                <li>To assist us in analyzing how our Service is used.</li>
            </ul>

            <p>We want to inform our Service users that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.</p>

            <h3>Security</h3>

            <p>We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</p>

            <h3>Links to Other Sites</h3>

            <p>Our Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over, and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</p>

            <h3>Children's Privacy</h3>

            <p>Our Services do not address anyone under the age of 13. We do not knowingly collect personal identifiable information from children under 13. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions.</p>

            <h3>Changes to This Privacy Policy</h3>

            <p>We may update our Privacy Policy from time to time. Thus, we advise you to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately, after they are posted on this page.</p>

            <h3>Subscription</h3>

            <p>Monthly subscription plans can be cancelled by contacting us at <a href="mailto:cheese@ricotta.team">cheese@ricotta.team</a>. Your plan will be cancelled at the end of your current billing cycle. No refunds will be provided for the current billing cycle.</p>
            <p>Annual subscription plans for the current annual billing cycle cannot be cancelled, but you can be cancel the subscription for the next year by contacting us at <a href="mailto:cheese@ricotta.team">cheese@ricotta.team</a> before the billing date. No refunds will be provided for the current billing cycle.</p>

            <h3>Contact Us</h3>

            <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at <a href="mailto:cheese@ricotta.team">cheese@ricotta.team</a></p>
        </div>

    
    
    </React.Fragment>
  );
}