import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import Header from './components/header';
import Support from './components/support';
import PrivacyPolicy from './components/privacy-policy';
import Pagebilling from './components/pagebilling';
import Billing from './components/billing';
import Sale from './components/sale';
import SaleSuccess from './components/salesuccess';
import PaymentSuccess from './components/paymentsuccess';
import PaymentFailed from './components/paymentfailed';
import PaymentFailedSale from './components/paymentfailedsale';
import PricingPage from './components/pricingpage';
import ReactGA from 'react-ga';
import AppSumo from './components/appsumo';
import TriviaHeader from './components/trivia-header';
import TriviaBilling from './components/trivia-billing';
import TriviaPortalBilling from './components/trivia-portal-billing';
import TriviaPaymentSuccess from './components/trivia-paymentsuccess';
import TriviaPaymentFailed from './components/trivia-paymentfailed';
import TriviaPagebilling from './components/trivia-pagebilling';
import TriviaSale from './components/trivia-sale';
import TriviaSaleSuccess from './components/trivia-salesuccess';
import TriviaPaymentFailedSale from './components/trivia-paymentfailedsale';
import Test from './components/test';
import TriviaPortalPagebilling from './components/trivia-portal-pagebilling';
import store from './store';
import { loadUser, todoloadUser, wikiloadUser, taskboardsloadUser } from './actions/auth';
import { Provider } from 'react-redux';
import PrivateRouteTrivia from './routing/PrivateRouteTrivia';
import DashboardTrivia from './components/dashboard-trivia/dashboard';


import "bootstrap/dist/css/bootstrap.min.css";
import "../src/components/dashboard-trivia/shards-dashboard/styles/shards-dashboards.1.1.0.min.css";
import DefaultLayout from './components/dashboard-trivia/layouts/Default';
import BlogOverview from './components/dashboard-trivia/views/BlogOverview';
import GamesOverview from './components/dashboard-trivia/views/GamesOverview';
import BillingPrices from './components/dashboard-trivia/views/BillingPrices';
import BlogView from './components/dashboard-trivia/views/BlogView';
import CheckOtherTeamsEngagement from './components/dashboard-trivia/views/CheckOtherTeamsEngagement';
import PrivateRouteTriviaURL from './routing/PrivateRouteTriviaURL';


import PrivateRouteTodo from './routing/PrivateRouteTodo';
import DefaultLayoutTodo from './components/dashboard-todo/layouts/Default';
import BlogViewTodo from './components/dashboard-todo/views/BlogView';
import BillingPricesTodo from './components/dashboard-todo/views/BillingPrices';
import TodoBilling from './components/todo-billing';
import TodoPageBilling from './components/todo-pagebilling';
import TodoPaymentSuccess from './components/todo-paymentsuccess';
import TodoPaymentFailed from './components/todo-paymentfailed';


import PrivateRouteWiki from './routing/PrivateRouteWiki';
import DefaultLayoutWiki from './components/dashboard-wiki/layouts/Default';
import BlogViewWiki from './components/dashboard-wiki/views/BlogView';
import BillingPricesWiki from './components/dashboard-wiki/views/BillingPrices';
import WikiBilling from './components/wiki-billing';
import WikiPageBilling from './components/wiki-pagebilling';
import WikiPaymentSuccess from './components/wiki-paymentsuccess';
import WikiPaymentFailed from './components/wiki-paymentfailed';


import PrivateRouteTaskboards from './routing/PrivateRouteTaskboards';
import DefaultLayoutTaskboards from './components/dashboard-taskboards/layouts/Default';
import BlogViewTaskboards from './components/dashboard-taskboards/views/BlogView';
import BillingPricesTaskboards from './components/dashboard-taskboards/views/BillingPrices';
import TaskboardsBilling from './components/taskboards-billing';
import TaskboardsPageBilling from './components/taskboards-pagebilling';
import TaskboardsPaymentSuccess from './components/taskboards-paymentsuccess';
import TaskboardsPaymentFailed from './components/taskboards-paymentfailed';
import WebexPaymentSuccess from './components/webex-paymentsuccess';
import WebexPaymentFailed from './components/webex-paymentfailed';
import MSTeamsPaymentSuccess from './components/msteams-paymentsuccess';
import MSTeamsPaymentFailed from './components/msteams-paymentfailed';
import TeamsPageBilling from './components/teams-pagebilling';




function App() {

  useEffect(() => {
    // Initialize GA
    ReactGA.initialize('UA-178639237-1')

    // Track page views
    ReactGA.pageview(window.location.pathname)
    console.log(window.location.pathname)

    if(window.location.pathname.startsWith('/trivia')){
      console.log('yayyyy')
      store.dispatch(loadUser());
    }
    else if(window.location.pathname.startsWith('/todo')){
      console.log('nahhhh')
      store.dispatch(todoloadUser());
    }
    else if(window.location.pathname.startsWith('/wiki')){
      console.log('nahhhh')
      store.dispatch(wikiloadUser());
    }
    else if(window.location.pathname.startsWith('/taskboards')){
      console.log('nahhhh')
      store.dispatch(taskboardsloadUser());
    }
    
   

  }, []);

  return (
    <Provider store = {store}>

    <Router>
      <Route exact path = "/" component = { Support } />
      <Route path = "/pricing" component = { PricingPage } />
      <Route exact path = "/privacy-policy" component = { PrivacyPolicy } />
      <Route exact path = "/support" component = { Support } />
      <Route path = "/pagebilling" component = { Pagebilling } />

      {/* <Route path = "/okr-sale" component = { Sale } /> */}
      <Route path = "/billing" component = { Billing } />
      <Route path = "/paymentsuccess" component = { PaymentSuccess } />
      <Route path = "/salesuccess" component = { SaleSuccess } />
      <Route path = "/paymentfailed" component = { PaymentFailed } />
      <Route path = "/paymentfailedokrsale" component = { PaymentFailedSale } />
      <Route path = "/okr/deals/appsumo" component = { AppSumo } />
      <Route exact path = "/trivia" component = { Support } />
      <Route exact path = "/trivia/billing" component = { TriviaBilling } />
      <Route path = "/trivia/pagebilling" component = { TriviaPagebilling } />
      <Route path = "/trivia/paymentsuccess" component = { TriviaPaymentSuccess } />
      <Route path = "/trivia/paymentfailed" component = { TriviaPaymentFailed } />
      {/* <Route exact path = "/trivia/header" component = { TriviaHeader } /> */}
      <Route path = "/trivia-sale" component = { Support } />
      <Route path = "/trivia-paymentfailedsale" component = { TriviaPaymentFailedSale } />
      <Route path = "/trivia-salesuccess" component = { TriviaSaleSuccess } />
      <Route exact path = "/trivia/billing/update-payment" component = { TriviaPortalBilling } />
      <Route exact path = "/trivia/billing/update-payment/login" component = { TriviaPortalPagebilling } />

      <Route exact path = "/todo/billing" component = { TodoBilling } />
      <Route path = "/todo/pagebilling" component = { TodoPageBilling } />
      <Route path = "/todo/paymentsuccess" component = { TodoPaymentSuccess } />
      <Route path = "/todo/paymentfailed" component = { TodoPaymentFailed } />

      <Route exact path = "/wiki/billing" component = { WikiBilling } />
      <Route path = "/wiki/pagebilling" component = { WikiPageBilling } />
      <Route path = "/wiki/paymentsuccess" component = { WikiPaymentSuccess } />
      <Route path = "/wiki/paymentfailed" component = { WikiPaymentFailed } />

      <Route path = "/webex/paymentsuccess" component = { WebexPaymentSuccess } />
      <Route path = "/webex/paymentfailed" component = { WebexPaymentFailed } />

      <Route path = "/msteams/paymentsuccess" component = { MSTeamsPaymentSuccess } />
      <Route path = "/msteams/paymentfailed" component = { MSTeamsPaymentFailed } />
      <Route path = "/teams/billing" component = { TeamsPageBilling } />


      {/* <Route path = "/trivia/dashboard/billing" component = { BillingPrices } /> */}

      {/* <Route exact path = "/taskboards/billing" component = { TaskboardsBilling } />
      <Route path = "/taskboards/pagebilling" component = { TaskboardsPageBilling } />
      <Route path = "/taskboards/paymentsuccess" component = { TaskboardsPaymentSuccess } />
      <Route path = "/taskboards/paymentfailed" component = { TaskboardsPaymentFailed } /> */}
      {/* <PrivateRouteTrivia exact path = "/trivia/dashboard" component = {DashboardTrivia} /> */}
      <PrivateRouteTrivia exact path = "/trivia/dashboard/index" component = {props => {
              return (
                <DefaultLayout {...props}>
                  <GamesOverview {...props} />
                </DefaultLayout>
              );
            }} />

       <PrivateRouteTrivia exact path = "/trivia/dashboard/engagement" component = {props => {
              return (
                <DefaultLayout {...props}>
                  <BlogOverview {...props} />
                </DefaultLayout>
              );
            }} />

      <PrivateRouteTrivia exact path = "/trivia/dashboard/blog" component = {props => {
              return (
                <DefaultLayout {...props}>
                  <BlogView {...props} />
                </DefaultLayout>
              );
            }} />

      <PrivateRouteTrivia exact path = "/trivia/dashboard/billing" component = {props => {
              return (
                <DefaultLayout {...props}>
                  <BillingPrices {...props} />
                </DefaultLayout>
              );
            }} />

  <PrivateRouteTriviaURL exact path = "/trivia/dashboard/teamactivity" component = {props => {
              return (
                <DefaultLayout {...props}>
                  <CheckOtherTeamsEngagement {...props} />
                </DefaultLayout>
              );
            }} />

    <PrivateRouteTodo exact path = "/todo/dashboard/index" component = {props => {
              return (
                <DefaultLayoutTodo {...props}>
                  <BlogViewTodo {...props} />
                </DefaultLayoutTodo>
              );
            }} />

    <PrivateRouteTodo exact path = "/todo/dashboard/billing" component = {props => {
              return (
                <DefaultLayoutTodo {...props}>
                  <BillingPricesTodo {...props} />
                </DefaultLayoutTodo>
              );
            }} />

  <PrivateRouteWiki exact path = "/wiki/dashboard/index" component = {props => {
              return (
                <DefaultLayoutWiki {...props}>
                  <BlogViewWiki {...props} />
                </DefaultLayoutWiki>
              );
            }} />

    <PrivateRouteWiki exact path = "/wiki/dashboard/billing" component = {props => {
              return (
                <DefaultLayoutWiki {...props}>
                  <BillingPricesWiki {...props} />
                </DefaultLayoutWiki>
              );
            }} />
    
    {/* <PrivateRouteTaskboards exact path = "/taskboards/dashboard/index" component = {props => {
              return (
                <DefaultLayoutTaskboards {...props}>
                  <BlogViewTaskboards {...props} />
                </DefaultLayoutTaskboards>
              );
            }} />

    <PrivateRouteTaskboards exact path = "/taskboards/dashboard/billing" component = {props => {
              return (
                <DefaultLayoutTaskboards {...props}>
                  <BillingPricesTaskboards {...props} />
                </DefaultLayoutTaskboards>
              );
            }} /> */}





     <Route exact path="/trivia/dashboard">
        <Redirect to="/trivia/dashboard/index" />
      </Route>

      <Route exact path="/todo/dashboard">
        <Redirect to="/todo/dashboard/index" />
      </Route>

      <Route exact path="/wiki/dashboard">
        <Redirect to="/wiki/dashboard/index" />
      </Route>

      {/* <Route exact path="/taskboards/dashboard">
        <Redirect to="/taskboards/dashboard/index" />
      </Route> */}


    </Router>
    </Provider>
  
  );
}

export default App;
