import React, { useState, useEffect } from 'react';
import {
  Button, Col, Navbar,
  NavbarBrand, Media
} from 'reactstrap';
const axios = require('axios');


export default function TaskboardsPaymentFailed(props) {

  const [country, setCountry] = useState("");

  // // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
   
    window.setTimeout(function(){

        window.location.href = "/taskboards/dashboard/billing";

    }, 3000);


  });


  return (
    <React.Fragment>
        <div className ="container billing-div">
            <p className ="lead mt-5 text-center"><b>Oops, payment failed. Please try again...</b></p> 
        </div>
        <div className="container mt-3">
            <Media>
            <img src="/assets/sub_no.svg" className = "img-fluid billing-management-image" alt="Ricotta on Slack" />
            </Media>
        </div>
    
    
    </React.Fragment>
  );
}