import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { taskboardslogin } from '../actions/auth';
import Spinner from './layout-spinner/Spinner';

// app.ricotta.team/trivia/billing or app.ricotta.team/trivia/dashboard

// the onSubmit part will go into pagebilling

const TaskboardsPageBilling = ({ taskboardslogin, taskboardsisAuthenticated, taskboardsisLoading }) => {
    useEffect(() => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let code = params.get('code');
        taskboardslogin(code);

        // window.location.href = "http://stackoverflow.com";

    }, []);

 


    if (taskboardsisAuthenticated) {
        return <Redirect to='/taskboards/dashboard/index' />;
    }

    return <Spinner />
};

TaskboardsPageBilling.propTypes = {
    taskboardslogin: PropTypes.func.isRequired,
    taskboardsisAuthenticated: PropTypes.bool,
    taskboardsisLoading: PropTypes.bool
  };
  
const mapStateToProps = state => ({
    taskboardsisAuthenticated: state.auth.taskboardsisAuthenticated,
    taskboardsisLoading: state.auth.taskboardsloading
});

export default connect(mapStateToProps, {taskboardslogin})(TaskboardsPageBilling);
