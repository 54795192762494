import React, { useState, useEffect } from 'react';
import {
  Button, Col, Navbar,
  NavbarBrand,
} from 'reactstrap';
import ReactGA from 'react-ga';
const axios = require('axios');
  


export default function PricingPage() {

  const [country, setCountry] = useState("");

  const [billing, setBilling] = React.useState("annually");

   // // Similar to componentDidMount and componentDidUpdate:
   useEffect(() => {
   

    (async () => {
      var ipRes = await axios.get('https://ipapi.co/json/');
      ipRes.status === 200 ? setCountry(ipRes.data.country_name) : setCountry("Not found")
      console.log(country);
    })();


  });

  const pricesEarlyGlobalOld = {
    monthly: 75,
    annually: 45
  }

  const pricesGrowthGlobalOld = {
    monthly: 150,
    annually: 90
  }

  const pricesEarlyGlobal = {
    monthly: 45,
    annually: 27
  }

  const pricesGrowthGlobal = {
    monthly: 90,
    annually: 54
  }

  const pricesEarlyIndia = {
    monthly: 1400,
    annually: 830
  }

  const pricesGrowthIndia = {
    monthly: 2800,
    annually: 1660
  }

  const buttonClass1 = {
    monthly: "btn btn-first",
    annually: "btn btn-second"
  }

  const buttonClass2 = {
    monthly: "btn btn-second",
    annually: "btn btn-first"
  }


function handleMonthlyChange(evt) {
    setBilling("monthly");
}

function handleAnnuallyChange(evt) {
  setBilling("annually");
}

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function handlePricingButtonClick() {
  ReactGA.event({
    category: "Pricing CTA Button Click",
    action: "Clicked on Pricing CTA button",
  })
  window.open('https://slack.com/oauth/v2/authorize?client_id=791862583680.812221040258&scope=app_mentions:read,channels:read,chat:write,im:history,im:read,team:read,users:read,users:read.email')
}

var pricingCta = 
  (<Button className="btn btn-block btn-primary text-uppercase" onClick={handlePricingButtonClick}>Add to Slack</Button>) 
  

var currency = country === 'India' ? '₹' : '$'

var earlyPrice = country === 'India' ? numberWithCommas(pricesEarlyIndia[billing]) : numberWithCommas(pricesEarlyGlobal[billing])
var growthPrice = country === 'India' ? numberWithCommas(pricesGrowthIndia[billing]) : numberWithCommas(pricesGrowthGlobal[billing])

var earlyPriceOld = country === 'India' ? numberWithCommas(pricesEarlyIndia[billing] * 3) : numberWithCommas(pricesEarlyGlobalOld[billing])
var growthPriceOld = country === 'India' ? numberWithCommas(pricesGrowthIndia[billing] * 3) : numberWithCommas(pricesGrowthGlobalOld[billing])

 


  return (
    <React.Fragment>
        <Navbar className = "mt-2" light expand="lg">
            <NavbarBrand className = "ml-md-4" href="/"><span><img src="/assets/cheese-512.png" width={30} alt="Ricotta" /> </span> <span style={{color: '#E04F60'}}>Ricotta</span></NavbarBrand>
        </Navbar>
        <div className ="container">
        <h1 className="text-center mt-2 privacy-header">Simple and fair Pricing</h1>
        </div>
        {/* <div className="container mt-1">
          <h3 className="landing-header text-center">Build high-performing remote teams with Ricotta OKRs on Slack</h3>
        </div> */}
        <section className="pricing py-3">
            <div className="container">
              <div className="row d-flex justify-content-center">
                <div class="btn-group" role="group" aria-label="Basic example">
                  <button type="button" class={buttonClass1[billing]} onClick={handleMonthlyChange}>Monthly</button>
                  <button type="button" class={buttonClass2[billing]} onClick={handleAnnuallyChange}>Annually</button>
                </div>
              </div> 
              {country === 'India' ? 
                (<div className="mt-3 text-center">
                  <b>67% off subscriptions. Offer ends soon.</b>
                  </div>) : 
                  (<div className="mt-3 text-center">
                  <b>40% off subscriptions. Offer ends soon.</b>
                  </div>)} 
              <div className="row mt-4">
                
                <div className="col-lg-6">
                  <div className="card mb-5 mb-lg-0">
                    <div className="card-body">
                      <h5 className="card-title text-muted text-uppercase text-center">Early-stage Companies</h5>
                      <h6 className="card-price text-center"><span className="card-price-old"><del>{currency}{earlyPriceOld}</del></span> {currency}{earlyPrice}<span className="period">/month</span></h6>
                      <hr />
                      <ul className="fa-ul">
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Upto 40 users</li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Automated OKR management workflows</li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>14-days free trial</li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Email Support</li>
                      </ul>
                      {pricingCta}
                      {/* <Button className="btn btn-block btn-primary text-uppercase" href="#">Sign up</Button> */}
                    </div>
                  </div>
                </div>
                
                <div className="col-lg-6">
                  <div className="card mb-5 mb-lg-0">
                    <div className="card-body">
                      <h5 className="card-title text-muted text-uppercase text-center">Growth-stage Companies</h5>
                      <h6 className="card-price text-center"><span className="card-price-old"><del>{currency}{growthPriceOld}</del></span> {currency}{growthPrice}<span className="period">/month</span></h6>
                      <hr />
                      <ul className="fa-ul">
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Unlimited users</li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Automated OKR management workflows</li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>14-days free trial</li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Priority Support</li>
                      </ul>
                      {pricingCta}
                      {/* <Button className="btn btn-block btn-primary text-uppercase" href="#">Sign up</Button> */}
                    </div>
                  </div>
                </div>
                
                
              </div>
            </div>
          </section>

    
    
    </React.Fragment>
  );
}