import { combineReducers } from 'redux';
import auth from './auth';
import engagement from './engagement';


const rootReducer = combineReducers({
    auth,
    engagement
});



export default rootReducer;