import React, { useState, useEffect } from 'react';
import {
  Button, Col, Navbar,
  NavbarBrand,
} from 'reactstrap';
const axios = require('axios');


export default function Billing(props) {

  // const [country, setCountry] = useState("");

  // // // Similar to componentDidMount and componentDidUpdate:
  // useEffect(() => {
   

  //   (async () => {
  //     var ipRes = await axios.get('https://ipapi.co/json/')
  //     setCountry(ipRes.data.country_name);
  //     console.log(country);
  //   })();


  // });


  return (
    <React.Fragment>
        <Navbar className = "mt-2" light expand="lg">
            <NavbarBrand className = "ml-md-4" href="/"><span><img src="/assets/cheese-512.png" width={30} alt="Ricotta" /> </span> <span style={{color: '#E04F60'}}>Ricotta</span></NavbarBrand>
        </Navbar>
        <div className ="container billing-div">
          <p className ="lead mt-5 text-center"><b>Please confirm your Slack account again. Sign in with Slack.</b></p>
            {/* {country === 'India' ? 
                (<p className ="lead mt-5 text-center"><b>Please confirm your Slack account again. Sign in with Slack.</b></p>) 
                    : country !== '' ? (<p className ="lead mt-5 text-center"><b>Ricotta is currently unavailable in your country.</b></p>)
                    : console.log('nothing to show')} */}
        </div>
        <div className="mt-md-4 mt-2"><Col xs = {{size: 10, offset: 1 }} md={{size: 4, offset: 4 }}><div className="col-md-9 mx-md-auto">
          <Button className = "btn-lg btn-block signUpButton2" href="https://slack.com/oauth/v2/authorize?client_id=791862583680.812221040258&scope=users:read&state=billing&redirect_uri=https://slackproductivity.ricotta.team/api/auth">
            Sign in with<span><img src="/assets/slack_white.svg" width={80} height={30} alt="Slack" /></span></Button></div></Col></div>

    {/* {country === 'India' ? (<div className="mt-md-4 mt-2"><Col xs = {{size: 10, offset: 1 }} md={{size: 4, offset: 4 }}><div className="col-md-9 mx-md-auto">
    <Button className = "btn-lg btn-block signUpButton2" href="https://slack.com/oauth/v2/authorize?client_id=791862583680.812221040258&user_scope=identity.basic,identity.email,identity.team&state=billing&redirect_uri=https://ricotta-interaction.herokuapp.com/api/auth"
        >Sign in with<span><img src="/assets/slack_white.svg" width={80} height={30} alt="Slack" /></span></Button></div></Col></div>) 
      : console.log('nothing to show')}  */}

    
    
    </React.Fragment>
  );
}