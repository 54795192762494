import React, { useState, useEffect } from 'react';
import {
  Button, Col, Navbar, Row,
  NavbarBrand,
} from 'reactstrap';
import { loadStripe } from '@stripe/stripe-js';
import TriviaQuestion from './trivia-question';
import TriviaFeatures from './trivia-features';
import TriviaDashboard from './trivia-dashboard';
import TriviaPricing from './trivia-pricing';
const codesArray = require('../config/appsumocodes');
const axios2 = require('axios');
const axios4 = require('../config/axiosInstance4');


const stripePromise = loadStripe('pk_live_oYyuovMeNVaQe5SSflWCE8XD00edZonWk9');



export default function TriviaSale(props) {

    const [code, setCode] = useState();
    const [country, setCountry] = useState("");
    const [deals, setDeals] = useState();
    const [loading, setLoading] = useState(false);


    useEffect(async () => {

        const date1 = new Date("2021-06-02");
        const date2 = new Date();

  
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

        var leNum = 50 - (diffDays * 2)

        setDeals(leNum);
        
        var r = Math.floor(Math.random() * 9000);
        var theCode = codesArray[r];
        setCode(theCode);

        var ipRes = await axios2.get('https://ipapi.co/json/')
        console.log(ipRes)
        ipRes.status === 200 ? setCountry(ipRes.data.country_name) : setCountry("Not found")
        console.log(country);

    }, []);

    const handleClickPrice4 = async (event) => {
        setLoading(true)
        // When the customer clicks on the button, redirect them to Checkout.
        const stripe = await stripePromise;
        // old - normal pricing
        // const priceId4 = country === 'India' ? 'price_1HMt47JBEzpLhH7BzYxXerEM' : 'price_1HbPJwJBEzpLhH7B5No7Gebl'
        console.log('came here 1')

        // new quarterly sale pricing
        const priceId4 = country === 'India' ? 'price_1J2tbmJBEzpLhH7BsBVXbrDZ' : 'price_1J2tZ8JBEzpLhH7BdFKetn5J'
    

        const configT = {
            headers: {
                'Content-Type': 'application/json',
            }
        };


        const body = JSON.stringify({ 
            priceId: priceId4,
            coupon: 'dDJKYfIC',
            success: 'https://app.ricotta.team/trivia-salesuccess',
            code: code,
            failure: `https://app.ricotta.team/trivia-paymentfailedsale`,
            clienId: `triviaSale&unlimitedAnnually&NA&NA`
        });

        const res = await axios4.post('/api/checkoutSessionNew/triviasale', body, configT);
        var sessionId = res.data;
        console.log('came here 2')
        console.log(sessionId)

        const { error } = await stripe.redirectToCheckout({
          sessionId: sessionId
        });

        // const { error } = await stripe.redirectToCheckout({
        //     lineItems: [
        //       // Replace with the ID of your price
        //       {price: priceId4, quantity: 1}
        //     ],
        //     mode: 'subscription',
        //     successUrl: `https://app.ricotta.team/trivia-salesuccess?code=${code}`,
        //     cancelUrl: `https://app.ricotta.team/trivia-paymentfailedsale`,
        //     billingAddressCollection: 'required',
        //     clientReferenceId: `triviaSale&unlimitedAnnually&NA&NA`
        //   });
      };


    

  return (
    <React.Fragment>
        <div className="trivia-header cheese-bg">
        <Navbar className = "mt-2" light expand="lg">
            <NavbarBrand className = "ml-md-4" href="/"><span><img src="/assets/ricotta-logo.png" width={120} alt="Ricotta" /> </span></NavbarBrand>
        </Navbar>
        <div className ="container ">
          <h1 className ="mt-5 text-center ricotta-color"><b>Summer Flash Sale! Get Ricotta Trivia for only $199 a year.</b></h1>
            <h3 className ="mt-3 text-center">Offer ends on <span className="ricotta-color">June 30th</span>. So subscribe now!</h3>
        </div>
        <div className="container mt-5 text-center">
        <Row>
        <Col xs = {{size: 12 }} sm = {{size: 8, offset: 2 }} md={{size: 4, offset: 2 }} lg={{size: 4, offset: 2 }} xl={{size: 4, offset: 2 }}>
            <p className ="lead mt-2 text-center"><i><b>New user? Subscribe for $199 a year.</b></i></p>
            <Button className = "btn-lg btn-block quizButton" onClick={handleClickPrice4} disabled={loading}>
                {loading && (
                <i
                className="fa fa-refresh fa-spin"
                style={{ marginRight: "5px" }}
                />
            )}
            {loading && <span>Loading...</span>}
            {!loading && <span>Subscribe</span>}
            </Button>
        </Col>
        <Col xs = {{size: 12 }} sm = {{size: 8, offset: 2 }} md={{size: 4, offset: 0 }} lg={{size: 4, offset: 0 }} xl={{size: 4, offset: 0 }}>
            <p className ="lead mt-2 text-center"><i><b>Already have an account? Sign in.</b></i></p>
            <Button className = "btn-lg btn-block quizButton" href="https://slack.com/oauth/v2/authorize?client_id=791862583680.1579546743637&scope=users:read&state=billing&redirect_uri=https://triviaapp.ricotta.team/api/auth">
            Sign in with Slack</Button>
        </Col>
        </Row>
    </div>
        {/* <div className ="container mt-5">
          <p className ="lead mt-2 text-center"><b>New user? Subscribe for $99 a year.</b></p>
     
        </div>
        <div className="mt-md-4 mt-2"><Col xs = {{size: 10, offset: 1 }} md={{size: 4, offset: 4 }}><div className="col-md-9 mx-md-auto">
          <Button className = "btn-lg btn-block signUpButton2" onClick={handleClickPrice4}>
            Subscribe</Button></div></Col></div>

            <div className ="container mt-5">
          <p className ="lead mt-2 text-center"><b>Already have an account? Sign in with Slack to subscribe.</b></p>
     
        </div>
        <div className="mt-md-4 mt-2"><Col xs = {{size: 10, offset: 1 }} md={{size: 4, offset: 4 }}><div className="col-md-9 mx-md-auto">
          <Button className = "btn-lg btn-block signUpButton2" href="https://slack.com/oauth/v2/authorize?client_id=791862583680.1579546743637&scope=users:read&state=billing&redirect_uri=https://ricotta-trivia.herokuapp.com/api/auth">
            Sign in with<span><img src="/assets/slack_white.svg" width={80} height={30} alt="Slack" /></span></Button></div></Col></div> */}
    
            <div className ="container mt-5">
          <p className ="lead mt-2 text-center"><i><b>Try Ricotta Trivia for free for 14 days instead before subscribing</b></i></p>
     
        </div>
        <div className="mt-md-2 mt-2 mt-mb-5 mb-2"><Col xs = {{size: 10, offset: 1 }} md={{size: 4, offset: 4 }}><div className="col-md-9 mx-md-auto">
          <Button className = "btn-lg btn-block quizButton" href="https://slack.com/oauth/v2/authorize?client_id=791862583680.1579546743637&scope=channels:read,chat:write,chat:write.public,im:read,im:write,mpim:read,mpim:write,users:read,users:read.email&user_scope=">
            Add Ricotta to Slack</Button></div></Col></div>

    

    <div className="mt-md-5 mt-4">
    <TriviaDashboard />
    <hr/>
    </div>
    </div>
    <div id="features" className="trivia-header">     
    <TriviaFeatures />    
    </div> 
    <div id="pricing">    
    <TriviaPricing />    
    </div> 
    <hr/>
    <div id="trivia-question">  
    <TriviaQuestion />    
    </div> 
    </React.Fragment>
  );
}