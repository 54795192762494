import React, { useState, useEffect } from 'react';
import {
     Button
  } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BillingActiveMessage from './BillingActiveMessage';
import { todoloadUser } from '../../../actions/auth';
import Spinner from "../../layout-spinner/Spinner"
import { loadStripe } from '@stripe/stripe-js';
const axios = require('axios');
const axios4 = require('../../../config/axiosInstance4');
const stripePromise = loadStripe('pk_live_oYyuovMeNVaQe5SSflWCE8XD00edZonWk9');


function BillingPricesTodo({ auth: { todouser, todoloading } }) {
  
    const [show, setShow] = React.useState();
    const [billing, setBilling] = React.useState("annually");
    const [country, setCountry] = React.useState("USA");
    const [stripeLoading, setstripeLoading] = useState(false);
    // const [todouser, setTodouser] = useState({isBillingActive: false, isUserLevelBillingActive: false, numUsers: 324});
    // const [todoloading, settodoloading] = useState(false);


      useEffect( () => {
        (async () => {
          var ipRes = await axios.get('https://ipapi.co/json/')
          ipRes.status === 200 ? setCountry(ipRes.data.country_name) : setCountry("Not found")
        })();
        
       
      }, [])

      const handleClickPrice1 = async (event) => {
        setstripeLoading(true)
        console.log('handleprice1')
        // When the customer clicks on the button, redirect them to Checkout.
        const stripe = await stripePromise;
        
        const priceId1 = country === 'India' ? 'price_1LIYS8JBEzpLhH7BFjC2qiZz' : 'price_1LIYJIJBEzpLhH7B82KZ287w'  

        const configT = {
          headers: {
              'Content-Type': 'application/json',
          }
        };
    
        var rawBody = {
            priceId: priceId1,
            slackTeamID: todouser.teamId,
            slackUserID: todouser.id,
            numUsers: 1,
            plan: 'todo&workspaceMonthlyBasic'
        }

        if(todouser.email !== null && todouser.email !== 'email'){
          rawBody.email = todouser.email
        }
        else {
          console.log('nothing')
        }

        const body = JSON.stringify(rawBody);
    
        const res = await axios4.post('/api/checkoutSessionNew/todosub', body, configT);
        var sessionId = res.data;
    
        const { error } = await stripe.redirectToCheckout({
          sessionId: sessionId
        });
    
        
      };
    
        // early stage annually
    
      const handleClickPrice2 = async (event) => {
        setstripeLoading(true)
        console.log('handleprice2')

        // When the customer clicks on the button, redirect them to Checkout.
        const stripe = await stripePromise;
        // old price
        const priceId2 = country === 'India' ? 'price_1LIYS8JBEzpLhH7BFjC2qiZz' : 'price_1LIYIiJBEzpLhH7B8kqctigY' 
    
        const configT = {
          headers: {
              'Content-Type': 'application/json',
          }
        };
        
        var rawBody = {
            priceId: priceId2,
            slackTeamID: todouser.teamId,
            slackUserID: todouser.id,
            numUsers: 1,
            plan: 'todo&workspaceYearlyBasic'
        }

        if(todouser.email !== null && todouser.email !== 'email'){
          rawBody.email = todouser.email
        }
        else {
          console.log('nothing')
        }

        const body = JSON.stringify(rawBody);
    
 
    
        const res = await axios4.post('/api/checkoutSessionNew/todosub', body, configT);
        var sessionId = res.data;
    
        const { error } = await stripe.redirectToCheckout({
          sessionId: sessionId
        });
    
        
      };
    
      // growth stage monthly
      const handleClickPrice3 = async (event) => {
        setstripeLoading(true)
        // When the customer clicks on the button, redirect them to Checkout.
        const stripe = await stripePromise;
        const priceId3 = country === 'India' ? 'price_1LIYYbJBEzpLhH7BeEheSO5V' : 'price_1LIYL2JBEzpLhH7BOdHiC1Mq' 

    
        const configT = {
          headers: {
              'Content-Type': 'application/json',
          }
        };

        var rawBody = {
          priceId: priceId3,
            slackTeamID: todouser.teamId,
            slackUserID: todouser.id,
            numUsers: 1,
            plan: 'todo&workspaceMonthlyGrowth'
        }

        if(todouser.email !== null && todouser.email !== 'email'){
          rawBody.email = todouser.email
        }
        else {
          console.log('nothing')
        }

      const body = JSON.stringify(rawBody);
    
    
        
    
        const res = await axios4.post('/api/checkoutSessionNew/todosub', body, configT);
        var sessionId = res.data;
    
        const { error } = await stripe.redirectToCheckout({
          sessionId: sessionId
        });
    
        
      };


      // growth stage yearly
      const handleClickPrice4 = async (event) => {
        setstripeLoading(true)
        // When the customer clicks on the button, redirect them to Checkout.
        const stripe = await stripePromise;
        const priceId4 = country === 'India' ? 'price_1LIYYbJBEzpLhH7BeEheSO5V' : 'price_1LIYKOJBEzpLhH7B8una0uzu' 

    
        const configT = {
          headers: {
              'Content-Type': 'application/json',
          }
        };

        var rawBody = {
          priceId: priceId4,
            slackTeamID: todouser.teamId,
            slackUserID: todouser.id,
            numUsers: 1,
            plan: 'todo&workspaceYearlyGrowth'
        }

        if(todouser.email !== null && todouser.email !== 'email'){
          rawBody.email = todouser.email
        }
        else {
          console.log('nothing')
        }

      const body = JSON.stringify(rawBody);
    
    
        
    
        const res = await axios4.post('/api/checkoutSessionNew/todosub', body, configT);
        var sessionId = res.data;
    
        const { error } = await stripe.redirectToCheckout({
          sessionId: sessionId
        });
    
        
      };


       // enterprise stage monthly
       const handleClickPrice5 = async (event) => {
        setstripeLoading(true)
        // When the customer clicks on the button, redirect them to Checkout.
        const stripe = await stripePromise;
        const priceId5 = country === 'India' ? 'price_1LIYaOJBEzpLhH7BPH4aivby' : 'price_1LIYMOJBEzpLhH7BLEMBfUFl' 

    
        const configT = {
          headers: {
              'Content-Type': 'application/json',
          }
        };

        var rawBody = {
          priceId: priceId5,
          slackTeamID: todouser.teamId,
            slackUserID: todouser.id,
            numUsers: 1,
            plan: 'todo&workspaceMonthlyEnterprise'
        }

        if(todouser.email !== null && todouser.email !== 'email'){
          rawBody.email = todouser.email
        }
        else {
          console.log('nothing')
        }

      const body = JSON.stringify(rawBody);
    
    
        
    
        const res = await axios4.post('/api/checkoutSessionNew/todosub', body, configT);
        var sessionId = res.data;
    
        const { error } = await stripe.redirectToCheckout({
          sessionId: sessionId
        });
    
        
      };


      // enterprise stage yearly
      const handleClickPrice6 = async (event) => {
        setstripeLoading(true)
        // When the customer clicks on the button, redirect them to Checkout.
        const stripe = await stripePromise;
        const priceId6 = country === 'India' ? 'price_1LIYaOJBEzpLhH7BPH4aivby' : 'price_1LIYLoJBEzpLhH7BluNK6GcI' 

    
        const configT = {
          headers: {
              'Content-Type': 'application/json',
          }
        };

        var rawBody = {
          priceId: priceId6,
          slackTeamID: todouser.teamId,
            slackUserID: todouser.id,
            numUsers: 1,
            plan: 'todo&workspaceYearlyEnterprise'
        }

        if(todouser.email !== null && todouser.email !== 'email'){
          rawBody.email = todouser.email
        }
        else {
          console.log('nothing')
        }

      const body = JSON.stringify(rawBody);
    
    
        
    
        const res = await axios4.post('/api/checkoutSessionNew/todosub', body, configT);
        var sessionId = res.data;
    
        const { error } = await stripe.redirectToCheckout({
          sessionId: sessionId
        });
    
        
      };
    

      
    
      const pricesEarlyGlobal = {
        monthly: 19,
        annually: 14
      }
    
      const pricesGrowthGlobal = {
        monthly: 39,
        annually: 29
      }

      const pricesEnterpriseGlobal = {
        monthly: 99,
        annually: 79
      }

      const pricesEarlyIndia = {
        monthly: 1499,
        annually: 1099
      }
    
    
      const pricesGrowthIndia = {
        monthly: 2999,
        annually: 2099
      }
    
      const pricesEnterpriseIndia = {
        monthly: 7599,
        annually: 5999
      }
    
      const buttonClass1 = {
        monthly: "btn btn-first",
        annually: "btn btn-second"
      }
    
      const buttonClass2 = {
        monthly: "btn btn-second",
        annually: "btn btn-first"
      }

      

    
      function handleMonthlyChange(evt) {
        setBilling("monthly");
      }
    
      function handleAnnuallyChange(evt) {
        setBilling("annually");
      }
    
      function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    
      function handlePricingButtonClick() {
        
        window.open('https://slack.com/oauth/v2/authorize?client_id=791862583680.1579546743637&scope=channels:read,chat:write,chat:write.public,im:read,im:write,mpim:read,mpim:write,users:read,users:read.email&user_scope=')
      }

      function pricingCta(button) {
          var fn = button === 1 ? (billing === "annually" ? handleClickPrice2 : handleClickPrice1) :
            button === 2 ? (billing === "annually" ? handleClickPrice4 : handleClickPrice3): 
            (billing === "annually" ? handleClickPrice6 : handleClickPrice5)
          

          return (<Button className="btn btn-block btn-primary text-uppercase" onClick={fn}>{stripeLoading && (
            <i
            className="fa fa-refresh fa-spin"
            style={{ marginRight: "5px" }}
            />
        )}
        {stripeLoading && <span>Loading...</span>}
        {!stripeLoading && <span>Select plan</span>}</Button>)
      }


      function returnDuration() {
        if(country === 'India'){
          return (<React.Fragment>
                <div className="mt-3 text-center"><p>We offer special discounts to Educational Institutions and Non-Profits. Please write to us on <b><span className="ricotta-color">cheese@ricotta.team</span></b></p></div>
          </React.Fragment>)
        }
        else {
          return (<React.Fragment>
            <div className="row d-flex justify-content-center">
                  <div class="btn-group" role="group" aria-label="Basic example">
                    <button type="button" class={buttonClass1[billing]} onClick={handleMonthlyChange}>Monthly</button>
                    <button type="button" class={buttonClass2[billing]} onClick={handleAnnuallyChange}>Annually</button>
                  </div>
                </div> 
                <div className="mt-3 text-center"><p>We offer special discounts to Educational Institutions and Non-Profits. Please write to us on <b><span className="ricotta-color">cheese@ricotta.team</span></b></p></div>
          </React.Fragment>)
        }
    }
      

  
    
      var currency = country === 'India' ? '₹' : '$'

      var annualPrice1 = billing === 'annually' ? country === 'India' ? `(${currency}${pricesEarlyIndia["annually"]*12}/year)` : `(${currency}${pricesEarlyGlobal["annually"]*12}/year)` : ""
      var annualPrice2 = billing === 'annually' ? country === 'India' ? `(${currency}${pricesGrowthIndia["annually"]*12}/year)` : `(${currency}${pricesGrowthGlobal["annually"]*12}/year)` : ""
      var annualPrice3 = billing === 'annually' ? country === 'India' ? `(${currency}${pricesEnterpriseIndia["annually"]*12}/year)` : `(${currency}${pricesEnterpriseGlobal["annually"]*12}/year)` : ""

      var earlyPrice = country === 'India' ? numberWithCommas(pricesEarlyIndia[billing]) : numberWithCommas(pricesEarlyGlobal[billing])
      var growthPrice = country === 'India' ? numberWithCommas(pricesGrowthIndia[billing]) : numberWithCommas(pricesGrowthGlobal[billing])
      var enterprisePrice = country === 'India' ? numberWithCommas(pricesEnterpriseIndia[billing]) : numberWithCommas(pricesEnterpriseGlobal[billing])

      // var personalPrice = country === 'India' ? numberWithCommas(499) : numberWithCommas(10)
      // var monthlyWorkspacePrice = country === 'India' ? numberWithCommas(50*todouser.numUsers) : numberWithCommas(1*todouser.numUsers)
      // var annualWorkspacePrice = country === 'India' ? numberWithCommas(25*todouser.numUsers*12) : numberWithCommas(0.5*todouser.numUsers*12)

 

    return (
      todoloading ? <Spinner /> : todoloading === false && todouser.isBillingActive === true ? <BillingActiveMessage user={todouser} /> :
        <React.Fragment>        
          <section className="pricing py-4">
            <div className="container">
              {returnDuration()}
              {/* <div className="mt-3 text-center"><p>Black Friday Sale! Use Code <b><span className="ricotta-color">BLACKFRIDAY30</span></b> to get <b>30% off</b> on Annual Plans. Valid till <b>4th December</b> for new subscriptions!</p></div> */}
              {/* <div className="mt-3 text-center"><p>Limited time offer! Use Code <b><span className="ricotta-color">JUNE20</span></b> to get <b>20% off</b> on all plans.</p></div> */}

              <div className="row mt-4">
                
                <div className="col-lg-4">
                  <div className="card mb-5 mb-lg-0">
                    <div className="card-body">
                      <h5 className="card-title text-muted text-uppercase text-center">Early-stage Companies</h5>
                      <h6 className="card-price text-center">{currency}{earlyPrice}<span className="period">/month </span><span className="card-price-old">{annualPrice1}</span></h6>
                      <hr />
                      <ul className="fa-ul">
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>for upto 20 users</li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Unlimited Todo lists</li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Reminders for your Todos</li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Bookmark Todos from messages</li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Team, Shared and Personal Todos</li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>End-to-end Data Encryption</li>
                      </ul>
                      {pricingCta(1)}
                      {/* <Button className="btn btn-block btn-primary text-uppercase" href="#">Sign up</Button> */}
                    </div>
                  </div>
                </div>
                
                <div className="col-lg-4">
                  <div className="card mb-5 mb-lg-0">
                    <div className="card-body">
                      <h5 className="card-title text-muted text-uppercase text-center">Growth-stage Companies</h5>
                      <h6 className="card-price text-center">{currency}{growthPrice}<span className="period">/month </span><span className="card-price-old">{annualPrice2}</span></h6>
                      <hr />
                      <ul className="fa-ul">
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>for upto 50 users</li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Unlimited Todo lists</li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Reminders for your Todos</li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Bookmark Todos from messages</li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Team, Shared and Personal Todos</li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>End-to-end Data Encryption</li>
                      </ul>
                      {pricingCta(2)}
                      {/* <Button className="btn btn-block btn-primary text-uppercase" href="#">Sign up</Button> */}
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="card mb-5 mb-lg-0">
                    <div className="card-body">
                      <h5 className="card-title text-muted text-uppercase text-center">Enterprises</h5>
                      <h6 className="card-price text-center">{currency}{enterprisePrice}<span className="period">/month </span><span className="card-price-old">{annualPrice3}</span></h6>
                      <hr />
                      <ul className="fa-ul">
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Unlimited users</li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Unlimited Todo lists</li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Reminders for your Todos</li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Bookmark Todos from messages</li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Team, Shared and Personal Todos</li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>End-to-end Data Encryption</li>
                      </ul>
                      {pricingCta(3)}
                      {/* <Button className="btn btn-block btn-primary text-uppercase" href="#">Sign up</Button> */}
                    </div>
                  </div>
                </div>
                
                
              </div>
            </div>
          </section>
      </React.Fragment> 
    )
}


BillingPricesTodo.propTypes = {
  auth: PropTypes.object.isRequired,
  todoloadUser: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { todoloadUser })(BillingPricesTodo);


// todouser.isBillingActive