import React, { useState, useEffect } from 'react';
import {
  Button, Col, Navbar,
  NavbarBrand, Row
} from 'reactstrap';


export default function Support(props) {



  return (
    <React.Fragment>
        <Navbar className = "mt-2 mb-2" light expand="lg">
            <NavbarBrand className = "ml-md-4" href="/"><span><img src="/assets/cheese-512.png" width={30} alt="Ricotta" /> </span> <span style={{color: '#E04F60'}}>Ricotta</span></NavbarBrand>
        </Navbar>
        <div className="container billing-div text-center">
            <h3 className="privacy-header">Please contact cheese@ricotta.team if you have any queries 🧀</h3>
        </div>
      
    </React.Fragment>
  );
}