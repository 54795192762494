import React, {useEffect, useState} from 'react';
import { Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink } from 'reactstrap';

function SidebarMain() {

    const [open, setOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
  
    const toggleModal = () => {
      setModalOpen(true)
    };
  
    const handleClose = () => setModalOpen(false);
    function toggle() {
        setOpen(!open);
      }

    return (
        <div className="sidebar">
        <Nav vertical>
        <NavItem>
          <NavLink href="#">Link</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="#">Link</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="#">Another Link</NavLink>
        </NavItem>
        <NavItem>
          <NavLink disabled href="#">Disabled Link</NavLink>
        </NavItem>
      </Nav>
        </div>
    )
}

export default SidebarMain
