import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { todologin } from '../actions/auth';
import Spinner from './layout-spinner/Spinner';

// app.ricotta.team/trivia/billing or app.ricotta.team/trivia/dashboard

// the onSubmit part will go into pagebilling

const TodoPageBilling = ({ todologin, todoisAuthenticated, todoisLoading }) => {
    useEffect(() => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let code = params.get('code');
        todologin(code);

        // window.location.href = "http://stackoverflow.com";

    }, []);

 


    if (todoisAuthenticated) {
        return <Redirect to='/todo/dashboard/index' />;
    }

    return <Spinner />
};

TodoPageBilling.propTypes = {
    todologin: PropTypes.func.isRequired,
    todoisAuthenticated: PropTypes.bool,
    todoisLoading: PropTypes.bool
  };
  
const mapStateToProps = state => ({
    todoisAuthenticated: state.auth.todoisAuthenticated,
    todoisLoading: state.auth.todoloading
});

export default connect(mapStateToProps, {todologin})(TodoPageBilling);
