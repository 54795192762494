import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  Button, Media
} from 'reactstrap';
const axios = require('../config/axiosInstance3');
const axios2 = require('axios');
const axios4 = require('../config/axiosInstance4');



// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// const stripePromise = loadStripe('pk_test_aubx4QrCXgh7gmaqmyjx5jHQ00u6EtZOmX');

const stripePromise = loadStripe('pk_live_oYyuovMeNVaQe5SSflWCE8XD00edZonWk9');

export default function TriviaPortalPagebilling(props) {

  const [billing, setBilling] = useState("annually");

  const [errorText, setErrorText] = useState();
  const [isAdmin, setIsAdmin] = useState();
  const [isDummy, setIsDummy] = useState();
  const [isBillingActive, setIsBillingActive] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [slackteamid, setSlackteamid] = useState("");
  const [slackuserid, setSlackuserid] = useState("");
  const [custId, setCustId] = useState("");
  const [billingUser, setBillingUser] = useState("");
  const [country, setCountry] = useState("");
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [redirect, setRedirect] = useState();







//   // // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
   

  

    


   
  
    (async () => {
      try {

        let search = window.location.search;
        let params = new URLSearchParams(search);
        let foo = params.get('code');
        console.log('came here 232')

        
        const config = {
          headers: {
            'Content-Type': 'application/json'
          }
        };

        const body = JSON.stringify({ 
          code: foo
        });
        

        


        console.log('came here 232')
        const res = await axios.put(`/api/billingauth/ccportal`, body, config);
        var resData = res.data;

        var isAdmin = resData.isAdmin;
        var email = resData.email;
        var billingActive = resData.isBillingActive;
        var plan = resData.plan;
        var name = resData.name;
        var slackTeamID = resData.slackTeamID;
        var slackUserID = resData.slackUserID;
        var stripeCustomerId = resData.stripeCustomerId;
        var leBillingUser = resData.billingUser;

        


        // if(billingActive === true || plan === 'triviasale'){
        //   setIsBillingActive(true);
        // }
        // else {
        //   setIsBillingActive(false);
        // }

        setIsAdmin(isAdmin);
        setName(name);
        setEmail(email);
        setSlackteamid(slackTeamID);
        setSlackuserid(slackUserID);
        setIsDummy(isAdmin)
        setCustId(stripeCustomerId)
        setBillingUser(leBillingUser)

        
        if(res.status === 200){
            if(billingActive === false || slackUserID.toString() !== leBillingUser.toString()){

                setLoaded(true);
                setRedirect(false); 
            }
            else {

                // API call
                const configT = {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                  };
              
                  const body2 = JSON.stringify({ 
                      customerId: stripeCustomerId,
                      returnUrl: "https://www.ricotta.team/trivia"
                  });
              
                  const res = await axios4.post('/api/checkoutSessionNew/billingportal', body2, configT);
                  var sessionUrl = res.data;
                  window.location.href = sessionUrl;
            }
        }
        else {
            console.log('something went wrong')
        }



        
      } catch (error) {
        console.log(error);
      }
      
    })();
      

    window.setTimeout(function(){

      setErrorText(<p className ="lead mt-3">Something went wrong or your session expired. Please return to the <a href="/trivia/billing">billing page</a> and login again</p>)

    }, 10000);
    

    

   
    // // Update the document title using the browser API
    // document.title = `You clicked ${count} times`;
  }, []);



 


  return (
    <React.Fragment>
      <div className ="container mt-5">
        <h1 className="billing-management-header">Ricotta Trivia Update Payment</h1>
        {loaded === false ? (<p className ="lead mt-2">Loading...please wait!</p>) 
            : redirect === false ? (<p className ="lead mt-3"> Hello {name}, please request the user who paid to login or subscribe to Ricotta</p>) :
            (<p className ="mt-2">Redirecting...please wait</p>)}

      </div> 
        

 
    </React.Fragment>
  );
}