import React, { useEffect, useState} from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../components/common/PageTitle";
import SmallStats from "../components/common/SmallStats";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import UsersOverview from "../components/blog/UsersOverview";
import Spinner from "../../layout-spinner/Spinner"
import BlogPosts from "./BlogPosts";
import Errors from "./Errors";
const axios = require('../../../config/axiosInstance3');



function CheckOtherTeamsEngagement({ auth: { user, token } }) {
    const [smallStats1, setsmallStats1] = useState();
    const [smallStats2, setsmallStats2] = useState();
    const [smallStats3, setsmallStats3] = useState();
    const [loading, setLoading] = useState(true);
    const [theTeam, setTheTeam] = useState("");
    const [error, setError] = useState();


    useEffect( () => {
        (async () => {
            let search = window.location.search;
            let params = new URLSearchParams(search);
            let leTeam = params.get('team');
            setTheTeam(leTeam)
            const config = {
                headers: {
                  'Content-Type': 'application/json',
                  'x-auth-token': token
                }
            };

            // var teamId = user.teamId

            const res2 = await axios.get(`/api/billingauth/teamactivity/2/${leTeam}`, config);

            setsmallStats1([{
                label: "Total Workspace Engagement Score",
                value: res2.data.totalCount,
                percentage: "4.7%",
                increase: true,
                chartLabels: [null, null, null, null, null, null, null],
                attrs: { md: "6", sm: "6" },
                datasets: [
                  {
                    label: "Today",
                    fill: "start",
                    borderWidth: 1.5,
                    backgroundColor: "rgba(0, 184, 216, 0.1)",
                    borderColor: "rgb(0, 184, 216)",
                    data: [0, 0, 0, 0, 0, 0, 0]
                  }
                ]
              },
              {
                label: "Trivia Contests",
                value: res2.data.contestsTakenCount,
                percentage: "12.4",
                increase: true,
                chartLabels: [null, null, null, null, null, null, null],
                attrs: { md: "6", sm: "6" },
                datasets: [
                  {
                    label: "Today",
                    fill: "start",
                    borderWidth: 1.5,
                    backgroundColor: "rgba(23,198,113,0.1)",
                    borderColor: "rgb(23,198,113)",
                    data: [0, 0, 0, 0, 0, 0, 0]
                  }
                ]
              },
              {
                label: "Photo Quiz",
                value: res2.data.pcontestsTakenCount,
                percentage: "3.8%",
                increase: true,
                decrease: false,
                chartLabels: [null, null, null, null, null, null, null],
                attrs: { md: "4", sm: "6" },
                datasets: [
                  {
                    label: "Today",
                    fill: "start",
                    borderWidth: 1.5,
                    backgroundColor: "rgba(255,180,0,0.1)",
                    borderColor: "rgb(255,180,0)",
                    data: [0, 0, 0, 0, 0, 0, 0]
                  }
                ]
              },
              {
                label: "Weekly Trivia",
                value: res2.data.weeklyContestsTakenCount,
                percentage: "2.71%",
                increase: false,
                decrease: true,
                chartLabels: [null, null, null, null, null, null, null],
                attrs: { md: "4", sm: "6" },
                datasets: [
                  {
                    label: "Today",
                    fill: "start",
                    borderWidth: 1.5,
                    backgroundColor: "rgba(255,65,105,0.1)",
                    borderColor: "rgb(255,65,105)",
                    data: [0, 0, 0, 0, 0, 0, 0]
                  }
                ]
              },
              {
                label: "Ice-breakers",
                value: res2.data.iceCount,
                percentage: "2.4%",
                increase: false,
                decrease: true,
                chartLabels: [null, null, null, null, null, null, null],
                attrs: { md: "4", sm: "6" },
                datasets: [
                  {
                    label: "Today",
                    fill: "start",
                    borderWidth: 1.5,
                    backgroundColor: "rgb(0,123,255,0.1)",
                    borderColor: "rgb(0,123,255)",
                    data: [0, 0, 0, 0, 0, 0, 0]
                  }
                ]
              }
            ])

            setsmallStats2([
                {
                  label: "Connect4",
                  value: res2.data.connect4Count,
                  percentage: "4.7%",
                  increase: true,
                  chartLabels: [null, null, null, null, null, null, null],
                  attrs: { md: "6", sm: "6" },
                  datasets: [
                    {
                      label: "Today",
                      fill: "start",
                      borderWidth: 1.5,
                      backgroundColor: "rgba(0, 184, 216, 0.1)",
                      borderColor: "rgb(0, 184, 216)",
                      data: [0, 0, 0, 0, 0, 0, 0]
                    }
                  ]
                },
                {
                  label: "Tic Tac Toe",
                  value: res2.data.tttCount,
                  percentage: "12.4",
                  increase: true,
                  chartLabels: [null, null, null, null, null, null, null],
                  attrs: { md: "6", sm: "6" },
                  datasets: [
                    {
                      label: "Today",
                      fill: "start",
                      borderWidth: 1.5,
                      backgroundColor: "rgba(23,198,113,0.1)",
                      borderColor: "rgb(23,198,113)",
                      data: [0, 0, 0, 0, 0, 0, 0]
                    }
                  ]
                },
                {
                  label: "Rock Paper Scissors",
                  value: res2.data.rpsCount,
                  percentage: "3.8%",
                  increase: true,
                  decrease: false,
                  chartLabels: [null, null, null, null, null, null, null],
                  attrs: { md: "4", sm: "6" },
                  datasets: [
                    {
                      label: "Today",
                      fill: "start",
                      borderWidth: 1.5,
                      backgroundColor: "rgba(255,180,0,0.1)",
                      borderColor: "rgb(255,180,0)",
                      data: [0, 0, 0, 0, 0, 0, 0]
                    }
                  ]
                },
                {
                  label: "This or That",
                  value: res2.data.thisthatTakenCount,
                  percentage: "2.71%",
                  increase: false,
                  decrease: true,
                  chartLabels: [null, null, null, null, null, null, null],
                  attrs: { md: "4", sm: "6" },
                  datasets: [
                    {
                      label: "Today",
                      fill: "start",
                      borderWidth: 1.5,
                      backgroundColor: "rgba(255,65,105,0.1)",
                      borderColor: "rgb(255,65,105)",
                      data: [0, 0, 0, 0, 0, 0, 0]
                    }
                  ]
                },
                {
                  label: "Two Truths and a lie",
                  value: res2.data.twotruthsTakenCount,
                  percentage: "2.4%",
                  increase: false,
                  decrease: true,
                  chartLabels: [null, null, null, null, null, null, null],
                  attrs: { md: "4", sm: "6" },
                  datasets: [
                    {
                      label: "Today",
                      fill: "start",
                      borderWidth: 1.5,
                      backgroundColor: "rgb(0,123,255,0.1)",
                      borderColor: "rgb(0,123,255)",
                      data: [0, 0, 0, 0, 0, 0, 0]
                    }
                  ]
                }
              ])

              setsmallStats3([
                {
                  label: "Hangman",
                  value: res2.data.hcontestsTakenCount,
                  percentage: "4.7%",
                  increase: true,
                  chartLabels: [null, null, null, null, null, null, null],
                  attrs: { md: "6", sm: "6" },
                  datasets: [
                    {
                      label: "Today",
                      fill: "start",
                      borderWidth: 1.5,
                      backgroundColor: "rgba(0, 184, 216, 0.1)",
                      borderColor: "rgb(0, 184, 216)",
                      data: [0, 0, 0, 0, 0, 0, 0]
                    }
                  ]
                },
                {
                  label: "Word of the day",
                  value: res2.data.wodcontestsTakenCount,
                  percentage: "12.4",
                  increase: true,
                  chartLabels: [null, null, null, null, null, null, null],
                  attrs: { md: "6", sm: "6" },
                  datasets: [
                    {
                      label: "Today",
                      fill: "start",
                      borderWidth: 1.5,
                      backgroundColor: "rgba(23,198,113,0.1)",
                      borderColor: "rgb(23,198,113)",
                      data: [0, 0, 0, 0, 0, 0, 0]
                    }
                  ]
                },
                {
                  label: "Most likely to",
                  value: res2.data.mltcontestsTakenCount,
                  percentage: "3.8%",
                  increase: true,
                  decrease: false,
                  chartLabels: [null, null, null, null, null, null, null],
                  attrs: { md: "4", sm: "6" },
                  datasets: [
                    {
                      label: "Today",
                      fill: "start",
                      borderWidth: 1.5,
                      backgroundColor: "rgba(255,180,0,0.1)",
                      borderColor: "rgb(255,180,0)",
                      data: [0, 0, 0, 0, 0, 0, 0]
                    }
                  ]
                },
                {
                  label: "Party Prank Picture",
                  value: res2.data.pppcontestsTakenCount,
                  percentage: "2.71%",
                  increase: false,
                  decrease: true,
                  chartLabels: [null, null, null, null, null, null, null],
                  attrs: { md: "4", sm: "6" },
                  datasets: [
                    {
                      label: "Today",
                      fill: "start",
                      borderWidth: 1.5,
                      backgroundColor: "rgba(255,65,105,0.1)",
                      borderColor: "rgb(255,65,105)",
                      data: [0, 0, 0, 0, 0, 0, 0]
                    }
                  ]
                },
                {
                  label: "Never have I ever",
                  value: res2.data.nevercontestsTakenCount,
                  percentage: "2.4%",
                  increase: false,
                  decrease: true,
                  chartLabels: [null, null, null, null, null, null, null],
                  attrs: { md: "4", sm: "6" },
                  datasets: [
                    {
                      label: "Today",
                      fill: "start",
                      borderWidth: 1.5,
                      backgroundColor: "rgb(0,123,255,0.1)",
                      borderColor: "rgb(0,123,255)",
                      data: [0, 0, 0, 0, 0, 0, 0]
                    }
                  ]
                }
              ])

              res2.status === 200 ? setLoading(false) : (setError(true) && setLoading(false))
        })();
        
       
      }, [])



  

  return (
    loading ? 
    <React.Fragment>
        <Spinner />
        <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <PageTitle title="This may take up to 2 minutes." subtitle="Please wait!" className="text-sm-left mb-3" />
        </Row>
        </Container>
    </React.Fragment> :
    <React.Fragment>
    {error !== true ?
    <React.Fragment>
          <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <PageTitle title="Workspace Engagement Report" subtitle="Ricotta Games and Trivia" className="text-sm-left mb-3" />
        </Row>
        <Row noGutters>
        <h3>Team: {theTeam}</h3>
        </Row>    

      
        <Row>
          {smallStats1.map((stats, idx) => (
            <Col className="col-lg mb-4" key={idx} {...stats.attrs}>
              <SmallStats
                id={`small-stats-${idx}`}
                variation="1"
                chartData={stats.datasets}
                chartLabels={stats.chartLabels}
                label={stats.label}
                value={stats.value}
                percentage={stats.percentage}
                increase={stats.increase}
                decrease={stats.decrease}
              />
            </Col>
          ))}
        </Row>

        <Row>
          {smallStats2.map((stats, idx) => (
            <Col className="col-lg mb-4" key={idx} {...stats.attrs}>
              <SmallStats
                id={`small-stats-${idx}`}
                variation="1"
                chartData={stats.datasets}
                chartLabels={stats.chartLabels}
                label={stats.label}
                value={stats.value}
                percentage={stats.percentage}
                increase={stats.increase}
                decrease={stats.decrease}
              />
            </Col>
          ))}
        </Row>

        <Row>
          {smallStats3.map((stats, idx) => (
            <Col className="col-lg mb-4" key={idx} {...stats.attrs}>
              <SmallStats
                id={`small-stats-${idx}`}
                variation="1"
                chartData={stats.datasets}
                chartLabels={stats.chartLabels}
                label={stats.label}
                value={stats.value}
                percentage={stats.percentage}
                increase={stats.increase}
                decrease={stats.decrease}
              />
            </Col>
          ))}
        </Row>

        <Row>
        
          

        </Row>
        <Row noGutters className="page-header py-4" id="eScoreDesc">
              <h4>What is Workspace Engagement Score?</h4>
              <p>Workspace Engagement Score measures the frequency of the games played and the number of users who participated in the game or trivia. <br />The higher the Workspace Engagement Score, the more the engagement, bonding and fun at your workplace!</p>
              <h4>How is it calculated?</h4>
              <p>The scores are calculated on a point basis. The points increase as the number of games played and participants increase. <br /> <br /> For example: If 20 people in your workspace complete a Trivia contest, your score increases by 20 points, since 20 users were engaged. The more Games and Trivia your workspace users play, the higher your Workspace Engagement Score.</p>
              <h4>How can you improve your Workspace Engagement Score?</h4>
              <p>1. Let your workspace members know about Ricotta Games and Trivia and encourage them to use it <br />2. Post Ice-breakers and Social Games often on public channels. <br />3. Auto-Schedule Trivia and Photo Quiz, several times a week. <br />4. Keep posting the Overall Trivia leaderboard on a public channel <br />5. Reward winners of Trivia Contests or Social Games with gift vouchers, a free coffee or a free lunch.<br />6 Encourage your team to participate in the weekly trivia contests</p>
          </Row>
      </Container>
    </React.Fragment> : <Errors />}
    </React.Fragment>
  )
}



CheckOtherTeamsEngagement.propTypes = {
  /**
   * The small stats dataset.
   */
  smallStats: PropTypes.array,
  smallStats2: PropTypes.array,
  auth: PropTypes.object.isRequired,

};

const mapStateToProps = state => ({
    auth: state.auth
});


export default connect(mapStateToProps)(CheckOtherTeamsEngagement);

