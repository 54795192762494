import React, {useEffect, useState} from 'react';
import { Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink } from 'reactstrap';
import { Link as ScrollLink } from "react-scroll";


function NavbarMain() {

    const [open, setOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
  
    const toggleModal = () => {
      setModalOpen(true)
    };
  
    const handleClose = () => setModalOpen(false);
  
   
  
    function toggle() {
      setOpen(!open);
    }


    return (
        <React.Fragment>
            <div className="mt-2 navbar-dashboard">
            <Navbar className = "navbar" light expand="lg">
        
        
        <NavbarBrand className = "ml-md-4" href="/"><span><img src="/assets/cheese-512.png" width={40} alt="Ricotta" /> <span className="ricotta-color">Ricotta Dashboard</span></span></NavbarBrand>
      
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={open} navbar>
          <Nav className="md-mr-3 ml-auto navItems" navbar>
            
             <NavItem>
              <NavLink className = "mr-md-2" href="http://ricotta.team/" target="_blank">
                Log out
                </NavLink>
            </NavItem>
            
           
            
           
            
       
          </Nav>
        </Collapse>
      </Navbar>
            </div>

        </React.Fragment>
        
    )
}

export default NavbarMain
