import { USER_LOADED, AUTH_ERROR, LOGIN_SUCCESS, LOGIN_FAIL , LOAD_ENGAGEMENT, ENGAGEMENT_ERROR, CLEAR_PROFILE, LOGOUT,
  TODO_USER_LOADED, TODO_AUTH_ERROR, TODO_LOGIN_SUCCESS, TODO_LOGIN_FAIL , TODO_LOGOUT,
  WIKI_USER_LOADED, WIKI_AUTH_ERROR, WIKI_LOGIN_SUCCESS, WIKI_LOGIN_FAIL , WIKI_LOGOUT,
  TASKBOARDS_USER_LOADED, TASKBOARDS_AUTH_ERROR, TASKBOARDS_LOGIN_SUCCESS, TASKBOARDS_LOGIN_FAIL , TASKBOARDS_LOGOUT } from './types';
const axios = require('../config/axiosInstance3');
const axiosTodo = require('../config/axiosInstanceTodo');
const axiosWiki = require('../config/axiosInstanceWiki');
const axiosTaskboards = require('../config/axiosInstanceTaskboards');


// Load User
export const loadUsage = () => async dispatch => {
    

  var token = localStorage.token ? localStorage.token : null
  
  const config = {
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token
      }
  };

  try {
    const res2 = await axios.get('/api/billingauth/teamactivity/1', config);


    dispatch({
      type: LOAD_ENGAGEMENT,
      payload: res2.data
    })
  } catch (error) {
    dispatch({
      type: ENGAGEMENT_ERROR
    })
  }
}


export const loadUser = () => async dispatch => {
    

    var token = localStorage.token ? localStorage.token : null
    
    const config = {
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token
        }
    };

    try {
      const res = await axios.get('/api/billingauth/verify', config);

      dispatch({
        type: USER_LOADED,
        payload: res.data
      })

      dispatch(loadUsage());
    } catch (error) {
      dispatch({
        type: AUTH_ERROR
      })
    }
}



  // Login User
  export const login = (code) => async dispatch => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

      const body = JSON.stringify({ 
        code: code
      });
      


  
    try {
        const res = await axios.post(`/api/billingauth`, body, config);
        
  
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data
      });

      dispatch(loadUser());
  
    } catch (err) {
  

  
      dispatch({
        type: LOGIN_FAIL
      });
    }
  };

  // Logout / Clear Profile
export const logout = () => dispatch => {
  dispatch({
    type: CLEAR_PROFILE
  });
  dispatch({
    type: LOGOUT
  });
};

// todos

export const todoloadUser = () => async dispatch => {
    

  var token = localStorage.todotoken ? localStorage.todotoken : null
  
  const config = {
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token
      }
  };

  try {
    const res = await axiosTodo.get('/api/todo/billingauth/verify', config);

    dispatch({
      type: TODO_USER_LOADED,
      payload: res.data
    })

  } catch (error) {
    dispatch({
      type: TODO_AUTH_ERROR
    })
  }
}

 // Login User
 export const todologin = (code) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

    const body = JSON.stringify({ 
      code: code
    });
    



  try {
      const res = await axiosTodo.post(`/api/todo/billingauth`, body, config);
      

    dispatch({
      type: TODO_LOGIN_SUCCESS,
      payload: res.data
    });

    dispatch(todoloadUser());

  } catch (err) {



    dispatch({
      type: TODO_LOGIN_FAIL
    });
  }
};

// Logout / Clear Profile
export const todologout = () => dispatch => {

dispatch({
  type: TODO_LOGOUT
});
};

// wiki
export const wikiloadUser = () => async dispatch => {
    

  var token = localStorage.wikitoken ? localStorage.wikitoken : null
  
  const config = {
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token
      }
  };

  try {
    const res = await axiosWiki.get('/api/wiki/billingauth/verify', config);

    dispatch({
      type: WIKI_USER_LOADED,
      payload: res.data
    })

  } catch (error) {
    dispatch({
      type: WIKI_AUTH_ERROR
    })
  }
}

 // Login User
 export const wikilogin = (code) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

    const body = JSON.stringify({ 
      code: code
    });
    



  try {
      const res = await axiosWiki.post(`/api/wiki/billingauth`, body, config);
      

    dispatch({
      type: WIKI_LOGIN_SUCCESS,
      payload: res.data
    });

    dispatch(wikiloadUser());

  } catch (err) {



    dispatch({
      type: WIKI_LOGIN_FAIL
    });
  }
};

// Logout / Clear Profile
export const wikilogout = () => dispatch => {

dispatch({
  type: WIKI_LOGOUT
});
};


// taskboards
export const taskboardsloadUser = () => async dispatch => {
    

  var token = localStorage.taskboardstoken ? localStorage.taskboardstoken : null
  
  const config = {
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token
      }
  };

  try {
    const res = await axiosTaskboards.get('/api/taskboards/billingauth/verify', config);

    dispatch({
      type: TASKBOARDS_USER_LOADED,
      payload: res.data
    })

  } catch (error) {
    dispatch({
      type: TASKBOARDS_AUTH_ERROR
    })
  }
}

 // Login User
 export const taskboardslogin = (code) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

    const body = JSON.stringify({ 
      code: code
    });
    



  try {
      const res = await axiosTaskboards.post(`/api/taskboards/billingauth`, body, config);
      

    dispatch({
      type: TASKBOARDS_LOGIN_SUCCESS,
      payload: res.data
    });

    dispatch(taskboardsloadUser());

  } catch (err) {



    dispatch({
      type: TASKBOARDS_LOGIN_FAIL
    });
  }
};

// Logout / Clear Profile
export const taskboardslogout = () => dispatch => {

dispatch({
  type: TASKBOARDS_LOGOUT
});
};
