const axios = require('axios');

var axiosInstance5 = process.env.NODE_ENV === 'production' ? axios.create({
  baseURL: 'https://msteamsgames.ricotta.team/'
}) : 
axios.create({
  baseURL: 'http://localhost:8080/'
})




module.exports = axiosInstance5;