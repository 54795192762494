import React, { Component } from 'react';
import { Media } from 'reactstrap';

class TriviaDashboard extends Component {
    constructor(props) {
  super(props);
  this.state = { width: 0, height: 0 };
  this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
}



componentDidMount() {
  function labnolIframe(div) {
    var iframe = document.createElement('iframe');
    iframe.setAttribute(
      'src',
      'https://www.youtube.com/embed/' + div.dataset.id + '?autoplay=1&rel=0'
    );
    iframe.setAttribute('frameborder', '0');
    iframe.setAttribute('allowfullscreen', '1');
    iframe.setAttribute(
      'allow',
      'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
    );
    div.parentNode.replaceChild(iframe, div);
  }

  function initYouTubeVideos() {
    var playerElements = document.getElementsByClassName('youtube-player');
    for (var n = 0; n < playerElements.length; n++) {
      var videoId = playerElements[n].dataset.id;
      var div = document.createElement('div');
      div.setAttribute('data-id', videoId);
      var thumbNode = document.createElement('img');
      thumbNode.src = '//i.ytimg.com/vi/ID/maxresdefault.jpg'.replace(
        'ID',
        videoId
      );
      div.appendChild(thumbNode);
      var playButton = document.createElement('div');
      playButton.setAttribute('class', 'play');
      div.appendChild(playButton);
      div.onclick = function () {
        labnolIframe(this);
      };
      playerElements[n].appendChild(div);
    }
  }

  this.updateWindowDimensions();
  window.addEventListener('resize', this.updateWindowDimensions);
  window.addEventListener('DOMContentLoaded', initYouTubeVideos)
}

componentWillUnmount() {
  window.removeEventListener('resize', this.updateWindowDimensions);
}

updateWindowDimensions() {
  this.setState({ width: window.innerWidth, height: window.innerHeight });
}
    
    render(){
        
        
        return(
          <React.Fragment>
           <div className = "container py-lg-1 mb-md-5">
           
            <div class="youtube-player" data-id="2PYzLkCiAqQ"></div>
            {/* <Media className = "text-center col-12">
            <img src="/assets/trivia-dashboard-min.png" className = "img-fluid img-trivia" alt="Ricotta OKRs" />
            </Media> */}
            </div>
            </React.Fragment>
        );
    }
}

export default TriviaDashboard;