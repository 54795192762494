import React, { useState, useEffect } from 'react';
import {
  Button, Col, Navbar,
  NavbarBrand, Form, FormGroup, Label, Input, FormText
} from 'reactstrap';
import ReactGA from 'react-ga';
const codesArray = require('../config/appsumocodes');


export default function AppSumo(props) {

    const [code, setCode] = useState();
    const [valid, setValid] = useState();

    function handleAppSumoButtonClick() {
        ReactGA.event({
          category: "AppSumo Button Click",
          action: "Clicked on AppSumo Add to Slack button",
        })
        // production link
        // `https://slack.com/oauth/v2/authorize?client_id=791862583680.812221040258&state=AppSumo${code}scope=app_mentions:read,channels:history,channels:read,chat:write,groups:history,im:history,mpim:history,team:read,users:read,users:read.email,im:read`
        window.open(`https://slack.com/oauth/v2/authorize?client_id=791862583680.812221040258&state=AppSumo${code}&scope=app_mentions:read,channels:history,channels:read,chat:write,groups:history,im:history,mpim:history,team:read,users:read,users:read.email,im:read`)
      }
  
    // // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
     
  
    
  
      
  
  
     
    
      (async () => {
        try {
           // setCountry(ipRes.data.country_name);
          // console.log(country);
          let search = window.location.search;
          let params = new URLSearchParams(search);
          let foo = params.get('code');
          console.log('came here 232')
          console.log(foo)
          var validState = codesArray.includes(foo);
          setCode(foo);
          setValid(validState)

        //   window.setTimeout(function(){
        //     setHidden(false)
        //     console.log(code)
        //     setErrorText(<p className ="lead mt-3">Hello {code}, to update or cancel your plan, please contact us at <a href = "mailto: cheese@ricotta.team">cheese@ricotta.team</a></p>)
      
        //   }, 3000);
  
  
          
        } catch (error) {
          console.log(error);
        }
        
      })();
    }, []);


const block = (<React.Fragment>
    <Navbar className = "mt-2 mb-2" light expand="lg">
            <NavbarBrand className = "ml-md-4" href="/"><span><img src="/assets/cheese-512.png" width={30} alt="Ricotta" /> </span> <span style={{color: '#E04F60'}}>Ricotta</span></NavbarBrand>
        </Navbar>
        <div className ="container mt-5 text-center">
        <h2 className="appsumo-header"><span><img src="/assets/appsumo-logo.svg" height={43} alt="AppSumo" />exclusive deal</span></h2>
        </div>
        {valid === false ? (<React.Fragment>
            <div className ="container mt-5 text-center">
                <h5>Your AppSumo Code is invalid or does not exist. Please contact cheese@ricotta.team to resolve this issue.</h5>
            </div>
        </React.Fragment>): 
        (<React.Fragment>
            <div className ="container mt-5 text-center">
                <h5>Your AppSumo Code is <b>{code}</b></h5>
                <p>Note: Redeem this code by adding Ricotta to your Slack workspace. Once redeemed, your code will no longer be valid.</p>
            </div>
            <div className="mt-5 text-center"><Col xs = {{size: 12 }} sm = {{size: 8, offset: 2 }} md={{size: 4, offset: 4 }} lg={{size: 4, offset: 4 }} xl={{size: 4, offset: 4 }}><div class="col-sm-12 mx-sm-auto col-md-12 mx-md-auto col-lg-10 mx-lg-auto col-xl-9 mx-xl-auto col-11 mx-auto">
                <Button className = "btn-lg btn-block signUpButton2" onClick={handleAppSumoButtonClick}>Add Ricotta to Slack</Button><small>Redeem your AppSumo Code now</small></div></Col>
            </div>
        </React.Fragment>)}
        
</React.Fragment>)


  return block
  
}