export default function() {
  return [
    {
      title: "Games & Trivia",
      to: "/trivia/dashboard/index",
      htmlBefore: '<i class="material-icons">sports_esports</i>',
      htmlAfter: ""
    },
    {
      title: "Engagement Report",
      to: "/trivia/dashboard/engagement",
      htmlBefore: '<i class="material-icons">groups</i>',
      htmlAfter: ""
    },
    {
      title: "Ricotta Blog",
      htmlBefore: '<i class="material-icons">article</i>',
      to: "/trivia/dashboard/blog",
      htmlAfter: ""
    },
    {
      title: "Billing Dashboard",
      htmlBefore: '<i class="material-icons">credit_card</i>',
      to: "/trivia/dashboard/billing",
      htmlAfter: ""
    }
  ];
}


    // {
    //   title: "Forms & Components",
    //   htmlBefore: '<i class="material-icons">view_module</i>',
    //   to: "/components-overview",
    // },
    // {
    //   title: "Tables",
    //   htmlBefore: '<i class="material-icons">table_chart</i>',
    //   to: "/tables",
    // },
    // {
    //   title: "User Profile",
    //   htmlBefore: '<i class="material-icons">person</i>',
    //   to: "/user-profile-lite",
    // },
    // {
    //   title: "Errors",
    //   htmlBefore: '<i class="material-icons">error</i>',
    //   to: "/errors",
    // }
