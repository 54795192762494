import React, { useState, useEffect } from 'react';
import {
     Button
  } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BillingActiveMessage from './BillingActiveMessage';
import { taskboardsloadUser } from '../../../actions/auth';
import Spinner from "../../layout-spinner/Spinner"
import { loadStripe } from '@stripe/stripe-js';
const axios = require('axios');
const axios4 = require('../../../config/axiosInstance4');
const stripePromise = loadStripe('pk_live_oYyuovMeNVaQe5SSflWCE8XD00edZonWk9');


function BillingPricesTaskboards({ auth: { taskboardsuser, taskboardsloading } }) {
    const [show, setShow] = React.useState();
    const [billing, setBilling] = React.useState("annually");
    const [country, setCountry] = React.useState("USA");
    const [stripeLoading, setstripeLoading] = useState(false);
    // const [dummyTU, setDummyTU] = useState({isBillingActive: false, isUserLevelBillingActive: true, numUsers: 324});


      useEffect( () => {
        (async () => {
          var ipRes = await axios.get('https://ipapi.co/json/')
          ipRes.status === 200 ? setCountry(ipRes.data.country_name) : setCountry("Not found")
        })();
        
       
      }, [])

      const handleClickPrice1 = async (event) => {
        setstripeLoading(true)
        console.log('handleprice1')
        // When the customer clicks on the button, redirect them to Checkout.
        const stripe = await stripePromise;
        
        const priceId1 = country === 'India' ? 'price_1K7Km1JBEzpLhH7BN99zg21T' : 'price_1K7KkHJBEzpLhH7BMZutbyaX' 

        const configT = {
          headers: {
              'Content-Type': 'application/json',
          }
        };
    
        var rawBody = {
            priceId: priceId1,
            slackTeamID: taskboardsuser.teamId,
            slackUserID: taskboardsuser.id,
            numUsers: 1,
            plan: 'taskboards&personalAnnually'
        }

        if(taskboardsuser.email !== null && taskboardsuser.email !== 'email'){
          rawBody.email = taskboardsuser.email
        }
        else {
          console.log('nothing')
        }

        const body = JSON.stringify(rawBody);
    
        const res = await axios4.post('/api/checkoutSessionNew/taskboardssub', body, configT);
        var sessionId = res.data;
    
        const { error } = await stripe.redirectToCheckout({
          sessionId: sessionId
        });
    
        
      };
    
        // early stage annually
    
      const handleClickPrice2 = async (event) => {
        setstripeLoading(true)
        console.log('handleprice2')

        // When the customer clicks on the button, redirect them to Checkout.
        const stripe = await stripePromise;
        // old price
        const priceId2 = country === 'India' ? 'price_1K7Km1JBEzpLhH7BjN8Gj7cR' : 'price_1K7KkGJBEzpLhH7BVPOTchRb'
    
        const configT = {
          headers: {
              'Content-Type': 'application/json',
          }
        };
        
        var rawBody = {
            priceId: priceId2,
              slackTeamID: taskboardsuser.teamId,
              slackUserID: taskboardsuser.id,
              numUsers: taskboardsuser.numUsers,
              plan: 'taskboards&workspaceMonthly'
        }

        if(taskboardsuser.email !== null && taskboardsuser.email !== 'email'){
          rawBody.email = taskboardsuser.email
        }
        else {
          console.log('nothing')
        }

        const body = JSON.stringify(rawBody);
    
 
    
        const res = await axios4.post('/api/checkoutSessionNew/taskboardssub', body, configT);
        var sessionId = res.data;
    
        const { error } = await stripe.redirectToCheckout({
          sessionId: sessionId
        });
    
        
      };
    
      // growth stage monthly
      const handleClickPrice3 = async (event) => {
        setstripeLoading(true)
        // When the customer clicks on the button, redirect them to Checkout.
        const stripe = await stripePromise;
        const priceId3 = country === 'India' ? 'price_1K7Km1JBEzpLhH7BFX5kmwvq' : 'price_1K7KkGJBEzpLhH7BZ9bbfpaj' 

    
        const configT = {
          headers: {
              'Content-Type': 'application/json',
          }
        };

        var rawBody = {
          priceId: priceId3,
          slackTeamID: taskboardsuser.teamId,
          slackUserID: taskboardsuser.id,
          numUsers: taskboardsuser.numUsers,
          plan: 'taskboards&workspaceAnnually'
        }

        if(taskboardsuser.email !== null && taskboardsuser.email !== 'email'){
          rawBody.email = taskboardsuser.email
        }
        else {
          console.log('nothing')
        }

      const body = JSON.stringify(rawBody);
    
    
        
    
        const res = await axios4.post('/api/checkoutSessionNew/taskboardssub', body, configT);
        var sessionId = res.data;
    
        const { error } = await stripe.redirectToCheckout({
          sessionId: sessionId
        });
    
        
      };
    
    
      const pricesEarlyGlobal = {
        monthly: 34,
        annually: 24
      }
    
      const pricesGrowthGlobal = {
        monthly: 54,
        annually: 39
      }

      const pricesEnterpriseGlobal = {
        monthly: 99,
        annually: 79
      }

      const pricesEarlyIndia = {
        monthly: 1400,
        annually: 1084
      }
    
    
      const pricesGrowthIndia = {
        monthly: 2800,
        annually: 2584
      }
    
      const pricesEnterpriseIndia = {
        monthly: 4900,
        annually: 4084
      }
    
      const buttonClass1 = {
        monthly: "btn btn-first",
        annually: "btn btn-second"
      }
    
      const buttonClass2 = {
        monthly: "btn btn-second",
        annually: "btn btn-first"
      }

      

    
      function handleMonthlyChange(evt) {
          setBilling("monthly");
      }
    
      function handleAnnuallyChange(evt) {
        setBilling("annually");
      }
    
      function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    
      function handlePricingButtonClick() {
        
        window.open('https://slack.com/oauth/v2/authorize?client_id=791862583680.1579546743637&scope=channels:read,chat:write,chat:write.public,im:read,im:write,mpim:read,mpim:write,users:read,users:read.email&user_scope=')
      }
      
      function pricingCta(button) {
          var fn = button === 1 ? handleClickPrice1 :
            button === 2 ? handleClickPrice2 : handleClickPrice3
           

           return (<Button className="btn btn-block btn-primary text-uppercase" onClick={fn}>{stripeLoading && (
            <i
            className="fa fa-refresh fa-spin"
            style={{ marginRight: "5px" }}
            />
        )}
        {stripeLoading && <span>Loading...</span>}
        {!stripeLoading && <span>Select plan</span>}</Button>)
      }
  
    
      var currency = country === 'India' ? '₹' : '$'

      var annualPrice1 = billing === 'annually' ? country === 'India' ? `(${currency}${pricesEarlyIndia["annually"]*12}/year)` : `(${currency}${pricesEarlyGlobal["annually"]*12}/year)` : ""
      var annualPrice2 = billing === 'annually' ? country === 'India' ? `(${currency}${pricesGrowthIndia["annually"]*12}/year)` : `(${currency}${pricesGrowthGlobal["annually"]*12}/year)` : ""

      var earlyPrice = country === 'India' ? numberWithCommas(pricesEarlyIndia[billing]) : numberWithCommas(pricesEarlyGlobal[billing])
      var growthPrice = country === 'India' ? numberWithCommas(pricesGrowthIndia[billing]) : numberWithCommas(pricesGrowthGlobal[billing])

      var personalPrice = country === 'India' ? numberWithCommas(999) : numberWithCommas(19)
      var monthlyWorkspacePrice = country === 'India' ? numberWithCommas(100*taskboardsuser.numUsers) : numberWithCommas(2*taskboardsuser.numUsers)
      var annualWorkspacePrice = country === 'India' ? numberWithCommas(50*taskboardsuser.numUsers*12) : numberWithCommas(1*taskboardsuser.numUsers*12)

 

    return (
        taskboardsloading ? <Spinner /> : taskboardsloading === false && taskboardsuser.isBillingActive === true ? <BillingActiveMessage user={taskboardsuser} /> 
          : taskboardsuser.isUserLevelBillingActive === true ? (<React.Fragment>        
            <section className="pricing py-4">
              <div className="container">
                {/* <div className="row d-flex justify-content-center">
                  <div class="btn-group" role="group" aria-label="Basic example">
                    <button type="button" class={buttonClass1[billing]} onClick={handleMonthlyChange}>Personal Plan</button>
                    <button type="button" class={buttonClass2[billing]} onClick={handleAnnuallyChange}>Workspace Plan</button>
                  </div>
                </div>  */}
                <div className="mt-3 text-center"><p>Your personal plan is active. Please contact us at <span className="ricotta-color">cheese@ricotta.team</span> if you wish to cancel the plan.</p><p>Upgrade your plan to Workspace Plan. You have <b>{taskboardsuser.numUsers} users </b> in your Slack Workspace. <br />We offer special discounts to Educational Institutions and Non-Profits. Please write to us on <b><span className="ricotta-color">cheese@ricotta.team</span></b></p></div>
                {/* <div className="mt-3 text-center"><p>Black Friday Sale! Use Code <b><span className="ricotta-color">BLACKFRIDAY30</span></b> to get <b>30% Off</b> on Annual Plans. Valid till <b>4th December</b> for new subscriptions!</p></div> */}
  
                {/* {country === 'India' ? 
                  (<div className="mt-3 text-center">
                     Switch to Annual plans and save <b><span className="ricotta-color">20% on billing</span></b>
                    </div>) : 
                    (<div className="mt-3 text-center">
                     Switch to Annual plans and save <b><span className="ricotta-color">20% on billing</span></b>
                    </div>)}  */}
                <div className="row mt-4">
                  
                 
                  
                  <div className="col-lg-6">
                    <div className="card mb-5 mb-lg-0">
                      <div className="card-body">
                        <h5 className="card-title text-uppercase text-center">Workspace Plan - Monthly</h5>
                        <h6 className="card-price text-center">{currency}{monthlyWorkspacePrice}<span className="period">/month </span><br /><span className="period">(2$ per user per month)</span></h6>
                        <hr />
                        <ul className="fa-ul">
                          <li><span className="fa-li"><i className="fa fa-check"></i></span>Workspace Plan</li>
                          <li><span className="fa-li"><i className="fa fa-check"></i></span>Annual Subscription</li>
                          <li><span className="fa-li"><i className="fa fa-check"></i></span>Manage Taskboards without leaving Slack</li>
                          <li><span className="fa-li"><i className="fa fa-check"></i></span>Automated Daily Asynchronous Standups</li>
                          <li><span className="fa-li"><i className="fa fa-check"></i></span>Create, Search and Share Taskboards</li>
                          <li><span className="fa-li"><i className="fa fa-check"></i></span>End-to-end Data Encryption</li>
                        </ul>
                        {pricingCta(2)}
                        {/* <Button className="btn btn-block btn-primary text-uppercase" href="#">Sign up</Button> */}
                      </div>
                    </div>
                  </div>
  
                  <div className="col-lg-6">
                    <div className="card mb-5 mb-lg-0">
                      <div className="card-body">
                        <h5 className="card-title text-uppercase text-center">Workspace Plan - Annually</h5>
                        <h6 className="card-price text-center">{currency}{annualWorkspacePrice}<span className="period">/year </span><br /><span className="period">(1$ per user per month)</span><br /><span className="period"><b>Save 50%</b></span></h6>
                        <hr />
                        <ul className="fa-ul">
                          <li><span className="fa-li"><i className="fa fa-check"></i></span>Workspace Plan</li>
                          <li><span className="fa-li"><i className="fa fa-check"></i></span>Annual Subscription</li>
                          <li><span className="fa-li"><i className="fa fa-check"></i></span>Manage Taskboards without leaving Slack</li>
                          <li><span className="fa-li"><i className="fa fa-check"></i></span>Automated Daily Asynchronous Standups</li>
                          <li><span className="fa-li"><i className="fa fa-check"></i></span>Create, Search and Share Taskboards</li>
                          <li><span className="fa-li"><i className="fa fa-check"></i></span>End-to-end Data Encryption</li>
                        </ul>
                        {pricingCta(3)}
                      </div>
                    </div>
                  </div>
                  
                  
                </div>
              </div>
            </section>
        </React.Fragment> ) : 
        (<React.Fragment>        
          <section className="pricing py-4">
            <div className="container">
              {/* <div className="row d-flex justify-content-center">
                <div class="btn-group" role="group" aria-label="Basic example">
                  <button type="button" class={buttonClass1[billing]} onClick={handleMonthlyChange}>Personal Plan</button>
                  <button type="button" class={buttonClass2[billing]} onClick={handleAnnuallyChange}>Workspace Plan</button>
                </div>
              </div>  */}
              <div className="mt-3 text-center"><p>You have <b>{taskboardsuser.numUsers} users </b> in your Slack Workspace. Please contact us if you wish to subscribe for fewer number of users in your Workspace. <br />We offer special discounts to Educational Institutions and Non-Profits. Please write to us on <b><span className="ricotta-color">cheese@ricotta.team</span></b></p></div>
              {/* <div className="mt-3 text-center"><p>Black Friday Sale! Use Code <b><span className="ricotta-color">BLACKFRIDAY30</span></b> to get <b>30% Off</b> on Annual Plans. Valid till <b>4th December</b> for new subscriptions!</p></div> */}

              {/* {country === 'India' ? 
                (<div className="mt-3 text-center">
                   Switch to Annual plans and save <b><span className="ricotta-color">20% on billing</span></b>
                  </div>) : 
                  (<div className="mt-3 text-center">
                   Switch to Annual plans and save <b><span className="ricotta-color">20% on billing</span></b>
                  </div>)}  */}
              <div className="row mt-4">
                
                <div className="col-lg-4">
                  <div className="card mb-5 mb-lg-0">
                    <div className="card-body">
                      <h5 className="card-title text-uppercase text-center">Personal Plan</h5>
                      <h6 className="card-price text-center">{currency}{personalPrice}<span className="period">/year </span></h6>
                      <hr />
                      <ul className="fa-ul">
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Workspace Plan</li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Annual Subscription</li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Manage Taskboards without leaving Slack</li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Automated Daily Asynchronous Standups</li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Create, Search and Share Taskboards</li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>End-to-end Data Encryption</li>
                      </ul>
                      {pricingCta(1)}
                      {/* <Button className="btn btn-block btn-primary text-uppercase" href="#">Sign up</Button> */}
                    </div>
                  </div>
                </div>
                
                <div className="col-lg-4">
                  <div className="card mb-5 mb-lg-0">
                    <div className="card-body">
                      <h5 className="card-title text-uppercase text-center">Workspace Plan - Monthly</h5>
                      <h6 className="card-price text-center">{currency}{monthlyWorkspacePrice}<span className="period">/month </span><br /><span className="period">(2$ per use per month)</span></h6>
                      <hr />
                      <ul className="fa-ul">
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Workspace Plan</li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Annual Subscription</li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Manage Taskboards without leaving Slack</li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Automated Daily Asynchronous Standups</li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Create, Search and Share Taskboards</li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>End-to-end Data Encryption</li>
                      </ul>
                      {pricingCta(2)}
                      {/* <Button className="btn btn-block btn-primary text-uppercase" href="#">Sign up</Button> */}
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="card mb-5 mb-lg-0">
                    <div className="card-body">
                      <h5 className="card-title text-uppercase text-center">Workspace Plan - Annually</h5>
                      <h6 className="card-price text-center">{currency}{annualWorkspacePrice}<span className="period">/year </span><br /><span className="period">(1$ per user per month)</span><br /><span className="period"><b>Save 50%</b></span></h6>
                      <hr />
                      <ul className="fa-ul">
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Workspace Plan</li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Annual Subscription</li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Manage Taskboards without leaving Slack</li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Automated Daily Asynchronous Standups</li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Create, Search and Share Taskboards</li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>End-to-end Data Encryption</li>
                      </ul>
                      {pricingCta(3)}
                    </div>
                  </div>
                </div>
                
                
              </div>
            </div>
          </section>
      </React.Fragment> )
    )
}


BillingPricesTaskboards.propTypes = {
  auth: PropTypes.object.isRequired,
  taskboardsloadUser: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { taskboardsloadUser })(BillingPricesTaskboards);


