import { LOAD_ENGAGEMENT, ENGAGEMENT_ERROR, CLEAR_PROFILE } from '../actions/types';

const initialState = {
    loading: true,
    engagement: null,
    error: false,
}

export default function(state = initialState, action){
    const { type, payload } = action;
    switch (type) {
        case LOAD_ENGAGEMENT:
            return {
                ...state,
                loading: false,
                engagement: payload
            }
        case ENGAGEMENT_ERROR:  
            return {
                ...state,
                loading: false,
                error: true,
            }

        case CLEAR_PROFILE:
                return {
                    ...state,
                    engagement: null,
                    loading: false
            };
        default:
            return state;  
    }
};