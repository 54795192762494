import React from 'react'
import DashboardActions from './dashboard-actions'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NavbarMain from './navbar-main';
import SidebarMain from './sidebar-main';
import { Container, Row, Col } from "reactstrap";


function DashboardTrivia({auth: { user }}) {


    return (
        <React.Fragment>
            <NavbarMain />
            <hr />
        <Container fluid>
               
            <Row>
                <Col md="2">
                    <SidebarMain />
                </Col>
                <Col className="dashboard-body">
                    <div className="mt-5">
                        <p>hello</p>
                    </div>
                </Col>
            </Row>
        </Container>
        
        
        {/* <h1 className='large text-primary'>Dashboard</h1>
        <p className='lead'>
        <i className='fas fa-user' /> Welcome {user && user.name}
        </p> */}
        </React.Fragment>
    )
}

DashboardTrivia.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
});

export default connect(mapStateToProps)(DashboardTrivia);

