import React, {useState} from 'react';
import { Container, Row, Col, Card, CardBody, CardImg, Button } from "reactstrap";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "shards-react";

import abacusImg from "../../images/gamesIcons/Abacus.svg"
import avocadoImg from "../../images/gamesIcons/Avocado.svg"
import calendarImg from "../../images/gamesIcons/calendar.svg"
import cameraImg from "../../images/gamesIcons/camera.svg"
import chainImg from "../../images/gamesIcons/chain.svg"
import lyingImg from "../../images/gamesIcons/lying.svg"
import puzzleImg from "../../images/gamesIcons/puzzle.svg"
import spockImg from "../../images/gamesIcons/spock.svg"
import threadImg from "../../images/gamesIcons/Thread.svg"
import tictacImg from "../../images/gamesIcons/tictac.svg"
import trophyImg from "../../images/gamesIcons/trophy.svg"

import mltImg from "../../images/gamesIcons/mlt.svg"
import coworkersImg from "../../images/gamesIcons/coworkers.svg"
import hangmanImg from "../../images/gamesIcons/hangman.svg"
import nhivImg from "../../images/gamesIcons/nhiv.svg"
import partyprankImg from "../../images/gamesIcons/partyprank.svg"
import wodImg from "../../images/gamesIcons/wod.svg"


function GamesCards2() {
    const [game, setGame] = useState(null)
    const [open, setOpen] = useState(false)

    // for modal - uncomment handleClick fn and remove class Temp from card className
    const handleClick = (game) => {
        setGame(game)
        setOpen(true)
    }

    const toggle = () => {
        setOpen(!open)
    }

    const textSection = () => {

        if(game === "Trivia Contest"){
            return (
            <div className="mt-1">
                <p><b>Mode:</b> Asynchronous (Play at your own pace)</p>
                <p><b>Number of participants:</b> from 2 to Unlimited</p>
                <p><b>Time taken:</b> 2 to 5 minutes</p>
                <p>Play fun trivia contes with your co-workers asynchronously. With over 20,000+ mutiple choice questions, 10+ categories like Marvel, Friends, Star Wars etc, it guarantees a lot of fun! Check your weekly progress with the trivia leaderboard.</p>
                <p>How to play?<br /><br />To start a game, simply click on Trivia contest in the Trivia section of the Home page. Select the channel, category, number of questions and contest end date and time to get started. <br /><br />Schedule trivia contests by clicking on the Schedule Trivia button. You can also create custom quizzes and create your own questions.</p></div>)
        }
        else if(game === "Photo Quiz"){
            return (
            <div className="mt-1">
                <p><b>Mode:</b> Asynchronous (Play at your own pace)</p>
                <p><b>Number of participants:</b> from 2 to Unlimited</p>
                <p><b>Time taken:</b> 3 minutes</p>
                <p>Play interactive timer-based photo quizzes with fun categories. Schedule quizzes, play asynchronously and compete on the real-time leaderboard with your team.</p>
                <p>How to play?<br /><br />To start a game, simply click on photo quiz in the Trivia section of the Home page. Select the channel, category and contest end date and time to get started. <br /><br />The photo quiz contest shows you a photo with MCQ-based questions, and you simply need to answer the question correctly in 30 seconds.</p></div>)
        }
        else if(game === "Weekly Trivia"){
            return (
            <div className="mt-1">
                <p><b>Mode:</b> Asynchronous (Play at your own pace)</p>
                <p><b>Number of participants:</b> from 2 to Unlimited</p>
                <p><b>Time taken:</b> 2 to 5 minutes</p>
                <p>Posted every Wednesday, Weekly Trivia contests is a great way to challenge and compete with your co-workers on the real-time leaderboard.</p>
                <p>How to play?<br /><br />Weekly Trivia contests are posted automatically on the selected channel. Simply take the quiz and select the right answer to play. <br /><br />To set the channel for the weekly trivia, click on Weekly Trivia Help on the Ricotta Trivia home page.</p></div>)
        }
        else if(game === "Word Building"){
            return (
            <div className="mt-1">
                <p><b>Mode:</b> Asynchronous or synchronous</p>
                <p><b>Number of participants:</b> from 2 to Unlimited</p>
                <p><b>Time taken:</b> 2-3 minutes</p>
                <p>How to play?<br /><br />Build as many words as possible with the given letters. The longer the word, the higher your score!<br /><br />Click on Word Building on the Home page and select game mode, channel and language to get started.</p></div>)
        }
        else if(game === "Word Chain"){
            return (
            <div className="mt-1">
                <p><b>Mode:</b> Asynchronous or synchronous</p>
                <p><b>Number of participants:</b> 2 to 10 for synchronous; 2 to 20 for asynchronous</p>
                <p><b>Time taken:</b> 5 to 15 minutes</p>
                <p>How to play?<br /><br />Come up with words which start with the last letter of the previous word!<br /><br />Click on Word Chain on the Home page and select game mode, channel and category to get started.</p></div>)
        }
        else if(game === "Connect4"){
            return (
            <div className="mt-1">
                <p><b>Mode:</b> Asynchronous</p>
                <p><b>Number of participants:</b> 2</p>
                <p><b>Time taken:</b> Till one person wins</p>
                <p>How to play?<br /><br />To start a two-player game of Connect4, enter the slash command /connect4 in a Direct chat.<br /><br />The first person to connect 4 of the same colored clocks in a row (either vertically, horizontally, or diagonally) wins!</p></div>)
        }
        else if(game === "Tic Tac Toe"){
            return (
            <div className="mt-1">
                <p><b>Mode:</b> Asynchronous</p>
                <p><b>Number of participants:</b> 2</p>
                <p><b>Time taken:</b> Till one person wins</p>
                <p>How to play?<br /><br />To start a two-player game of Tic Tac Toe, enter the slash command /tictactoe in a Direct chat.<br /><br />The first person to place X or O (their chosen symbol) in a row, either vertically, horizontally, or diagonally wins!</p></div>)
        }
        else if(game === "Rock Paper Scissors"){
            return (
            <div className="mt-1">
                <p><b>Mode:</b> Asynchronous</p>
                <p><b>Number of participants:</b> 2</p>
                <p><b>Time taken:</b> Till one person wins</p>
                <p>How to play?<br /><br />To start a two-player game of Rock Paper Scissors, enter the slash command /rps in a Direct chat.<br /><br />To start a two-player game of Rock Paper Scissors Lizard Spock, enter the slash command /rpsls in a Direct chat.</p></div>)
        }
        else if(game === "This or That"){
            return (
            <div className="mt-1">
                <p><b>Mode:</b> Asynchronous (Play at your own pace)</p>
                <p><b>Number of participants:</b> from 2 to Unlimited</p>
                <p><b>Time taken:</b> 1-2 minutes</p>
                <p>Would You Rather (This or That) is a quick and effective Ice-breaker game for small and large teams. Simply choose your favourite out of two options (coffee or tea, sightseeing or shopping, warm shower or cold shower). </p>
                <p>How to play?<br /><br />To start a game of This or That, click on This or That in the Icebreakers section of the Home page and select the channel to post on.<br /><br />Everyone can select their desired option and have a discussion in the thread!</p></div>)
        }
        else if(game === "Two Truths and a lie"){
            return (
            <div className="mt-1">
                <p><b>Mode:</b> Asynchronous (Play at your own pace)</p>
                <p><b>Number of participants:</b> from 2 to Unlimited</p>
                <p><b>Time taken:</b> 1-2 minutes</p>
                <p>Each co-worker states 2 truths and makes up 1 lie about themselves. Remember to keep the lie realistic to make it even more engaging. The other co-workers have to guess which one is the lie out of the three statements.</p>
                <p>How to play?<br /><br />To start a game of Two Truths and a Lie, click on Two Truths and a Lie in the Icebreakers section of the Home page and select the channel to post on. Next, enter 3 statements about yourself, choose the lie and post on the channel.<br /><br />Everyone will guess which statement is the lie out of the 3.</p></div>)
        }
        else if(game === "Hangman"){
            return (
            <div className="mt-1">
                <p><b>Mode:</b> Asynchronous (Play at your own pace)</p>
                <p><b>Number of participants:</b> from 2 to Unlimited</p>
                <p><b>Time taken:</b> 2-3 minutes</p>
                <p>Play the classic Hangman game on Slack and compete with your co-workers asynchronously.</p>
                <p>How to play?<br /><br />To start a game, simply click on Hangman in the Social Word Games section of the Home page. Select the channel and contest end date and time to get started.</p></div>)
        }
        else if(game === "Word of the day"){
            return (
            <div className="mt-1">
                <p><b>Mode:</b> Asynchronous (Play at your own pace)</p>
                <p><b>Number of participants:</b> from 2 to Unlimited</p>
                <p><b>Time taken:</b> 2-3 minutes</p>
                <p>Inspired by Jotto, Wordle and Lingo, Ricotta Word of the day is a fun way to start your work day!</p>
                <p>How to play?<br /><br />Word of the day is posted daily on your default channel. To modify Word of the day settings, click on Edit Settings on the Ricotta App Home tab.</p></div>)
        }
        else if(game === "Most likely to"){
            return (
            <div className="mt-1">
                <p><b>Mode:</b> Asynchronous (Play at your own pace)</p>
                <p><b>Number of participants:</b> from 2 to Unlimited</p>
                <p><b>Time taken:</b> under 1 minute</p>
                <p><a href='https://docs.ricotta.team/ricotta-games-and-trivia-1/how-to-play-most-likely-to' target='blank'>Read more</a></p></div>)
        }
        else if(game === "Never have I ever"){
            return (
            <div className="mt-1">
                <p><b>Mode:</b> Asynchronous (Play at your own pace)</p>
                <p><b>Number of participants:</b> from 2 to Unlimited</p>
                <p><b>Time taken:</b> under 1 minute</p>
                <p><a href='https://docs.ricotta.team/ricotta-games-and-trivia-1/how-to-play-never-have-i-ever' target='blank'>Read more</a></p></div>)
        }
        else if(game === "Party Prank Picture"){
            return (
            <div className="mt-1">
                <p><b>Mode:</b> Asynchronous (Play at your own pace)</p>
                <p><b>Number of participants:</b> from 2 to Unlimited</p>
                <p><b>Time taken:</b> under 1 minute</p>
                <p><a href='https://docs.ricotta.team/ricotta-games-and-trivia-1/how-to-play-party-prank-picture' target='blank'>Read more</a></p></div>)
        }
        else {
            return (<div className="mt-2"><p></p></div>)
        }
    }

    return (
        
        <React.Fragment>
            <Container>
                <div className="mt-2">
                <Row>
                    <Col>
                    <Card height="20" className="gamesCards" onClick={() => handleClick("Photo Quiz")}>
                        <CardImg src={cameraImg} />
                        <CardBody className="text-center">
                        <h5><b>Photo Quiz</b></h5>
                        </CardBody>
                    </Card>
                    </Col>

                    <Col>
                    <Card className="gamesCards" onClick={() => handleClick("Trivia Contest")}>
                        <CardImg src={trophyImg} />
                        <CardBody className="text-center">
                        <h5><b>Trivia Contest</b></h5>
                        </CardBody>
                    </Card>
                    </Col>

                    <Col>
                    <Card className="gamesCards" onClick={() => handleClick("Weekly Trivia")}>
                        <CardImg src={calendarImg} />
                        <CardBody className="text-center">
                        <h5><b>Weekly Trivia</b></h5>
                        </CardBody>
                    </Card>
                    </Col>

                    <Col>
                    <Card className="gamesCards" onClick={() => handleClick("Word Building")}>
                        <CardImg src={puzzleImg} />
                        <CardBody className="text-center">
                        <h5><b>Word Building</b></h5>
                        </CardBody>
                    </Card>
                    </Col>

                    <Col>
                    <Card className="gamesCards" onClick={() => handleClick("Word Chain")}>
                        <CardImg src={chainImg} />
                        <CardBody className="text-center">
                        <h5><b>Word Chain</b></h5>
                        </CardBody>
                    </Card>
                    </Col>
                </Row>
                </div>
                <div className="mt-3"> 
                <Row>
                    <Col>
                    <Card className="gamesCards" onClick={() => handleClick("Connect4")}>
                        <CardImg src={abacusImg} />
                        <CardBody className="text-center">
                        <h5><b>Connect4</b></h5>
                        </CardBody>
                    </Card>
                    </Col>

                    <Col>
                    <Card className="gamesCards" onClick={() => handleClick("Tic Tac Toe")}>
                        <CardImg src={tictacImg} />
                        <CardBody className="text-center">
                        <h5><b>Tic Tac Toe</b></h5>
                        </CardBody>
                    </Card>
                    </Col>

                    <Col>
                    <Card className="gamesCards" onClick={() => handleClick("Rock Paper Scissors")}>
                        <CardImg src={spockImg} />
                        <CardBody className="text-center">
                        <h5><b>Rock Paper Scissors</b></h5>
                        </CardBody>
                    </Card>
                    </Col>

                    <Col>
                    <Card className="gamesCards" onClick={() => handleClick("This or That")}>
                        <CardImg src={avocadoImg} />
                        <CardBody className="text-center">
                        <h5><b>This or That</b></h5>
                        </CardBody>
                    </Card>
                    </Col>

                    <Col>
                    <Card className="gamesCards" onClick={() => handleClick("Two Truths and a lie")}>
                        <CardImg src={lyingImg} />
                        <CardBody className="text-center">
                        <h5><b>Two Truths and a lie</b></h5>
                        </CardBody>
                    </Card>
                    </Col>
                </Row>
                </div>
                <div className="mt-3"> 
                <Row>
                    <Col>
                    <Card className="gamesCards" onClick={() => handleClick("Hangman")}>
                        <CardImg src={hangmanImg} />
                        <CardBody className="text-center">
                        <h5><b>Hangman</b></h5>
                        </CardBody>
                    </Card>
                    </Col>

                    <Col>
                    <Card className="gamesCards" onClick={() => handleClick("Word of the day")}>
                        <CardImg src={wodImg} />
                        <CardBody className="text-center">
                        <h5><b>Word of the day</b></h5>
                        </CardBody>
                    </Card>
                    </Col>

                    <Col>
                    <Card className="gamesCards" onClick={() => handleClick("Most likely to")}>
                        <CardImg src={mltImg} />
                        <CardBody className="text-center">
                        <h5><b>Most likely to</b></h5>
                        </CardBody>
                    </Card>
                    </Col>

                    <Col>
                    <Card className="gamesCards" onClick={() => handleClick("Party Prank Picture")}>
                        <CardImg src={partyprankImg} />
                        <CardBody className="text-center">
                        <h5><b>Party Prank Picture</b></h5>
                        </CardBody>
                    </Card>
                    </Col>

                    <Col>
                    <Card className="gamesCards" onClick={() => handleClick("Never have I ever")}>
                        <CardImg src={nhivImg} />
                        <CardBody className="text-center">
                        <h5><b>Never have I ever</b></h5>
                        </CardBody>
                    </Card>
                    </Col>
                </Row>
                </div>
                </Container>
                <Modal open={open} toggle={toggle} size="lg">
                    <ModalHeader toggle={toggle}>{game}</ModalHeader>
                    <ModalBody>{textSection()}</ModalBody>
                </Modal>
                
        </React.Fragment>
    )
}

export default GamesCards2
