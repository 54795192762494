import React, { Component, useState, useEffect } from 'react';
import LandingForm from './form';
import {
    Button, Col, Row
  } from 'reactstrap';
import ReactGA from 'react-ga';
const quizQuestion = require('../config/quizQuestions');




export default function TriviaQuestion() {

    const [question, setQuestion] = useState("");
    const [optionA, setOptionA] = useState("");
    const [optionB, setOptionB] = useState("");
    const [optionC, setOptionC] = useState("");
    const [optionD, setOptionD] = useState("");
    const [correctAnswer, setCorrectAnswer] = useState("");
    const [chosenAnswer, setChosenAnswer] = useState("");
    const [iscorrect, setIscorrect] = useState("");
    const [show, setShow] = useState(false);
    
  
  
    useEffect(() => {
        var r = Math.floor(Math.random() * 10);
        var questionObj = quizQuestion(r);
        setQuestion(questionObj.question);
        setOptionA(questionObj.optionA)
        setOptionB(questionObj.optionB)
        setOptionC(questionObj.optionC)
        setOptionD(questionObj.optionD)
        setCorrectAnswer(questionObj.correctAnswer)
        console.log(show)
    }, []);


    const checkCorrect = (event) => {
        console.log(event.target.value)
        setChosenAnswer(event.target.value)
        if(event.target.value === correctAnswer){
            setIscorrect("true")
        }
        else {
            setIscorrect("false")
        }
        setShow(true)
    } 

    function handleCTAButtonClick() {
        ReactGA.event({
          category: "App Page - Trivia Quiz CTA Button Click",
          action: "Clicked on CTA button",
        })
        window.open('https://slack.com/oauth/v2/authorize?client_id=791862583680.1579546743637&scope=channels:read,chat:write,chat:write.public,im:read,im:write,mpim:read,mpim:write,users:read,users:read.email&user_scope=')
      }


    return(
        <React.Fragment>
        <section className = "cta py-3">
            
            <div className="container mt-2 text-center">
                {show === false ? (<React.Fragment>
                        <div className = "container mt-lg-2">
                            <div className = "row">
                            <div className = "col-12 text-center">
                                <h2 className = "ctaHeading"><b>{question}</b></h2>
                            </div>
                            </div>
                        </div>
                        <div className="container mt-2 text-center">
                        <Row>
                        <Col xs = {{size: 12 }} sm = {{size: 8, offset: 2 }} md={{size: 3, offset: 3 }} lg={{size: 3, offset: 3 }} xl={{size: 3, offset: 3 }}>
                            <Button className = "btn-lg btn-block quizButton" value={optionA} onClick={checkCorrect}>{optionA}</Button>
                            <Button className = "btn-lg btn-block quizButton" value={optionB} onClick={checkCorrect}>{optionB}</Button>
                        </Col>
                        <Col xs = {{size: 12 }} sm = {{size: 8, offset: 2 }} md={{size: 3, offset: 0 }} lg={{size: 3, offset: 0 }} xl={{size: 3, offset: 0 }}>
                            <Button className = "btn-lg btn-block quizButton" value={optionC} onClick={checkCorrect}>{optionC}</Button>
                            <Button className = "btn-lg btn-block quizButton" value={optionD} onClick={checkCorrect}>{optionD}</Button>
                        </Col>
                        </Row>
                        </div>
                    </React.Fragment>) :  iscorrect === "true" ?
                     (<React.Fragment>
                         <div className = "container mt-lg-2">
                            <div className = "row">
                            <div className = "col-12 text-center">
                                <h2 className = "ctaHeading"><b>Wohooo</b></h2>
                            </div>
                            </div>
                        </div>
                         <div className="container mt-2 text-center">
                        <div><p>{chosenAnswer} is the correct answer! Practice more Trivia questions!</p>
                        </div>
                        <div className="mt-2 text-center"><Col xs = {{size: 12 }} sm = {{size: 8, offset: 2 }} md={{size: 6, offset: 3 }} lg={{size: 6, offset: 3 }} xl={{size: 4, offset: 4 }}><div class="col-sm-12 mx-sm-auto col-md-12 mx-md-auto col-lg-10 mx-lg-auto col-xl-12 mx-xl-auto col-11 mx-auto">
                            <Button className = "btn-lg btn-block signUpButton2" onClick={handleCTAButtonClick}>Add Ricotta Trivia to Slack</Button><small>14 days free trial. No credit card required</small></div></Col>
                        </div>
                        </div>
                    </React.Fragment>) : (<React.Fragment>
                        <div className = "container mt-lg-2">
                            <div className = "row">
                            <div className = "col-12 text-center">
                                <h2 className = "ctaHeading"><b>Oops</b></h2>
                            </div>
                            </div>
                        </div>
                        <div className="container mt-2 text-center">
                        <div><p>{chosenAnswer} is incorrect. {correctAnswer} is the correct answer! Practice more Trivia questions!</p> 
                        </div>
                        <div className="mt-2 text-center"><Col xs = {{size: 12 }} sm = {{size: 8, offset: 2 }} md={{size: 6, offset: 3 }} lg={{size: 6, offset: 3 }} xl={{size: 4, offset: 4 }}><div class="col-sm-12 mx-sm-auto col-md-12 mx-md-auto col-lg-10 mx-lg-auto col-xl-12 mx-xl-auto col-11 mx-auto">
                            <Button className = "btn-lg btn-block signUpButton2" onClick={handleCTAButtonClick}>Add Ricotta Trivia to Slack</Button><small>14 days free trial. No credit card required</small></div></Col>
                        </div>
                        </div>
                    </React.Fragment>)}
                
            </div>

            {/* <div className="mt-2 text-center"><Col xs = {{size: 12 }} sm = {{size: 8, offset: 2 }} md={{size: 4, offset: 4 }} lg={{size: 4, offset: 4 }} xl={{size: 4, offset: 4 }}><div class="col-sm-12 mx-sm-auto col-md-12 mx-md-auto col-lg-10 mx-lg-auto col-xl-9 mx-xl-auto col-11 mx-auto">
                <Button className = "btn-lg btn-block signUpButton2" onClick={handleCTAButtonClick}>Add Ricotta to Slack</Button><small>14 day free trial. No credit card required</small></div></Col></div> */}
            
        
        </section> 
    </React.Fragment>
      
    );
  
  }