import React, { useState, useEffect, useRef } from 'react';
import {
  Button, Col, Navbar,
  NavbarBrand,
} from 'reactstrap';
const axios2 = require('axios');



export default function Test(props) {

    const [country, setCountry] = useState("");
    const [loaded, setLoaded] = useState(false);


    const inputRef = useRef()

    useEffect(() => {
   

  

    


   
  
        (async () => {
          try {
    
            
            var ipRes = await axios2.get('https://ipapi.co/json/')
            console.log(ipRes)
            ipRes.status === 200 ? setCountry(ipRes.data.country_name) : setCountry("Not found")
            console.log(country);
            
            document.title = `Page is loaded`;
            document.body.style.background = "#E04F60"
            // inputRef.current.textContent = 'yayyy'
            ipRes.status === 200 ? setLoaded(true) : console.log('oops')
            
          } catch (error) {
            console.log(error);
          }
          
        })();
          
    
        
    
        
    
       
        // // Update the document title using the browser API
        // document.title = `You clicked ${count} times`;
      }, []);


  return (
    <React.Fragment>
        <Navbar className = "mt-2" light expand="lg">
            <NavbarBrand className = "ml-md-4" href="/"><span><img src="/assets/cheese-512.png" width={30} alt="Ricotta" /> </span> <span style={{color: '#E04F60'}}>Ricotta</span></NavbarBrand>
        </Navbar>
        {loaded === false ? (<p>Loading...</p>) : (<p>Ricotta Trivia Billing Dashboard</p>)}

    
    
    </React.Fragment>
  );
}