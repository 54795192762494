import React, { Component } from 'react';
import { Media, Col, Row } from 'reactstrap';

class TriviaFeatures extends Component {
    constructor(props) {
  super(props);
  this.state = { width: 0, height: 0 };
  this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
}

componentDidMount() {
  this.updateWindowDimensions();
  window.addEventListener('resize', this.updateWindowDimensions);
}

componentWillUnmount() {
  window.removeEventListener('resize', this.updateWindowDimensions);
}

updateWindowDimensions() {
  this.setState({ width: window.innerWidth, height: window.innerHeight });
}
    
    render(){
    const imgStyle = {
  maxWidth: this.state.width
}    
        
        
        return(
<React.Fragment>  
<div>
<div className = "container text-center mt-xs-1 mb-xs-1 mt-lg-5 mb-lg-5"> 
    <h1 className="landing-header-trivia"><span className="ricotta-color">It’s Quiz O’Clock with Ricotta Trivia</span></h1>
    <p className ="lead mt-3 mb-2 landing-header2">4 different ways to play - <b>Host, Create, Weekly Trivia and Practice</b>. Unlimited fun!</p>
</div>
<div className = "container mt-xs-1 mb-xs-1 mt-lg-5 mb-lg-5">   
    <Row>  
        <Col xs = {{size: 12}} md ={{ size: 6}} className = "text-center">    
            <Media>
                <img src="/assets/host-trivia4-min.png" className = "img-fluid img-trivia" alt="Ricotta on Slack" />
            </Media>
        </Col>  
        <Col xs = {{size: 12}} md = {{ size: 5, offset: 1 }} className = "align-self-center">   
            <h4 className = "featureHeading"><b>The host with the most</b></h4>
            <p className ="lead mt-3">Host asynchronous trivia contests from a pool of 5,000 questions and compete with your team. Nerd herd, assemble!</p>
        </Col>        
    </Row>        
</div>  
<div className = "container mt-xs-1 mb-xs-1 mt-lg-5 mb-lg-5">   
    <Row> 
        <Col xs = {{size: 12}} md = {{ size: 6 }} className = "align-self-center">   
            <h4 className = "featureHeading"><b>Break the ice!</b></h4>
            <p className ="lead mt-3">Bring your team together with games such as Would you rather (This or That), Two truths and a lie, How well do you know your co-worker etc.</p>
        </Col>  
        <Col xs = {{size: 12}} md ={{ size: 5, offset: 1 }}>    
            <Media>
            <img src="/assets/Slack_Icebreakers.png" className = "img-fluid img-trivia" alt="Ricotta on Slack" />
            </Media>
        </Col>
          
    </Row>        
</div>
<div className = "container mt-xs-1 mb-xs-1 mt-lg-5 mb-lg-5">   
    <Row>  
        <Col xs = {{size: 12}} md ={{ size: 6}} className = "text-center">    
            <Media>
                <img src="/assets/daily-trivia-min.png" className = "img-fluid img-trivia" alt="Ricotta on Slack" />
            </Media>
        </Col>  
        <Col xs = {{size: 12}} md = {{ size: 5, offset: 1 }} className = "align-self-center">   
            <h4 className = "featureHeading"><b>It’s a weekly trivia affair</b></h4>
            <p className ="lead mt-3">Don't miss the trivia of the day and challenge yourself by taking the Ricotta Weekly Trivia and aim for a podium finish by outscoring your work buddies!</p>
        </Col>        
    </Row>        
</div>   
<div className = "container mt-xs-1 mb-xs-1 mt-lg-5 mb-lg-5">   
    <Row> 
        <Col xs = {{size: 12}} md = {{ size: 6 }} className = "align-self-center">   
            <h4 className = "featureHeading"><b>Create custom ice-breaker contests</b></h4>
            <p className ="lead mt-3">Create custom ice-breaker contests or training quizzes. The sky is the limit!</p>
        </Col>  
        <Col xs = {{size: 12}} md ={{ size: 5, offset: 1 }}>    
            <Media>
            <img src="/assets/create-custom4-min.png" className = "img-fluid img-trivia" alt="Ricotta on Slack" />
            </Media>
        </Col>
          
    </Row>        
</div>
</div>
        </React.Fragment>    
        );
    }
}

export default TriviaFeatures;