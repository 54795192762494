import React, { Component } from 'react';
import { Media, Col, Row } from 'reactstrap';

class TriviaWhy extends Component {
    constructor(props) {
  super(props);
  this.state = { width: 0, height: 0 };
  this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
}

componentDidMount() {
  this.updateWindowDimensions();
  window.addEventListener('resize', this.updateWindowDimensions);
}

componentWillUnmount() {
  window.removeEventListener('resize', this.updateWindowDimensions);
}

updateWindowDimensions() {
  this.setState({ width: window.innerWidth, height: window.innerHeight });
}
    
    render(){
    const imgStyle = {
  maxWidth: this.state.width
}    
        
        
        return(
<React.Fragment>  
<div className = "container text-center mt-xs-1 mb-xs-1 mt-lg-5 mb-lg-5"> 
    <h1 className="landing-header-trivia"><span className="ricotta-color">Why use Ricotta Trivia?</span></h1>
    <p className ="lead mt-3 mb-2 landing-header2">Keep your remote team connected and motivated</p>
</div>
<div className = "container mt-xs-1 mb-xs-1 mt-lg-5 mb-lg-5">   
    <Row>  
        <Col md ={{ size: 3, offset: 0}} xs = {{size: 10, offset: 1}} className = "align-self-center">   
            <Row>
                <Media className="mb-2">
                    <img src="/assets/teambonding.svg" className = "img-fluid" alt="Ricotta on Slack" />
                </Media>
            </Row>
            <Row>
                <h4 className = "featureHeading"><b>It’s time for some team bonding</b></h4>
                <p className ="lead mt-3">Team bonding is a great weapon to a happier, more productive team.</p>
            </Row>
        </Col>  
        <Col md ={{ size: 3, offset: 1}} xs = {{size: 10, offset: 1}} className = "align-self-center">    
            <Row>
                <Media className="mb-2">
                    <img src="/assets/employee.svg" className = "img-fluid" alt="Ricotta on Slack" />
                </Media>
            </Row>
            <Row>
                <h4 className = "featureHeading"><b>Employee Engagement for the win</b></h4>
                <p className ="lead mt-3">Drive employee engagement by creating a positive team culture with loads of fun!</p>
            </Row>
        </Col>  
        <Col md ={{ size: 3, offset: 1}} xs = {{size: 10, offset: 1}} className = "align-self-center">    
            <Row>
                <Media className="mb-2">
                    <img src="/assets/presentation.svg" className = "img-fluid" alt="Ricotta on Slack" />
                </Media>
            </Row>
            <Row>
                <h4 className = "featureHeading"><b>Easy employee training and motivation</b></h4>
                <p className ="lead mt-3">Create training questions or simply have fun for some motivation.</p>
            </Row>
        </Col>         
    </Row>  
</div>

        </React.Fragment>    
        );
    }
}

export default TriviaWhy;