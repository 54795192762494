import { USER_LOADED, AUTH_ERROR,LOGIN_SUCCESS, 
    LOGIN_FAIL, LOGOUT, TODO_USER_LOADED, TODO_AUTH_ERROR, TODO_LOGIN_SUCCESS, 
    TODO_LOGIN_FAIL, TODO_LOGOUT, WIKI_USER_LOADED, WIKI_AUTH_ERROR, WIKI_LOGIN_SUCCESS, 
    WIKI_LOGIN_FAIL, WIKI_LOGOUT, TASKBOARDS_USER_LOADED, TASKBOARDS_AUTH_ERROR, 
    TASKBOARDS_LOGIN_SUCCESS, TASKBOARDS_LOGIN_FAIL , TASKBOARDS_LOGOUT } from '../actions/types';

const initialState = {
    token: localStorage.getItem('token'),
    isAuthenticated: false,
    loading: true,
    user: null,
    todotoken: localStorage.getItem('todotoken'),
    todoisAuthenticated: false,
    todoloading: true,
    todouser: null,
    wikitoken: localStorage.getItem('wikitoken'),
    wikiisAuthenticated: false,
    wikiloading: true,
    wikiuser: null,
    taskboardstoken: localStorage.getItem('taskboardstoken'),
    taskboardsisAuthenticated: false,
    taskboardsloading: true,
    taskboardsuser: null
}

export default function(state = initialState, action){
    const { type, payload } = action;
    switch (type) {
        case USER_LOADED:
            return {
                ...state,
                isAuthenticated: true,
                loading: false,
                user: payload
            }
        case TODO_USER_LOADED:
            return {
                ...state,
                todoisAuthenticated: true,
                todoloading: false,
                todouser: payload
            }
        case WIKI_USER_LOADED:
            return {
                ...state,
                wikiisAuthenticated: true,
                wikiloading: false,
                wikiuser: payload
            }
        case TASKBOARDS_USER_LOADED:
            return {
                ...state,
                taskboardsisAuthenticated: true,
                taskboardsloading: false,
                taskboardsuser: payload
            }
        case LOGIN_SUCCESS:
            localStorage.setItem('token', payload.token);
            return {
                ...state,
                isAuthenticated: true,
                loading: false
            }
        case TODO_LOGIN_SUCCESS:
            localStorage.setItem('todotoken', payload.token);
            return {
                ...state,
                todoisAuthenticated: true,
                todoloading: false
            }
        case WIKI_LOGIN_SUCCESS:
            localStorage.setItem('wikitoken', payload.token);
            return {
                ...state,
                wikiisAuthenticated: true,
                wikiloading: false
            }
        case TASKBOARDS_LOGIN_SUCCESS:
            localStorage.setItem('taskboardstoken', payload.token);
            return {
                ...state,
                taskboardsisAuthenticated: true,
                taskboardsloading: false
            }
        case AUTH_ERROR:  
        case LOGIN_FAIL: 
            localStorage.removeItem('token');
            return {
                user: null,
                token: null,
                isAuthenticated: false,
                loading: false
            }
        case TODO_AUTH_ERROR:  
        case TODO_LOGIN_FAIL: 
            localStorage.removeItem('todotoken');
            return {
                todouser: null,
                todotoken: null,
                todoisAuthenticated: false,
                todoloading: false
            }
        case WIKI_AUTH_ERROR:  
        case WIKI_LOGIN_FAIL: 
            localStorage.removeItem('wikitoken');
            return {
                wikiuser: null,
                wikitoken: null,
                wikiisAuthenticated: false,
                wikiloading: false
            }
        case TASKBOARDS_AUTH_ERROR:  
        case TASKBOARDS_LOGIN_FAIL: 
            localStorage.removeItem('taskboardstoken');
            return {
                taskboardsuser: null,
                taskboardstoken: null,
                taskboardsisAuthenticated: false,
                taskboardsloading: false
            }
        case LOGOUT:  
            localStorage.removeItem('token');
            return {
                token: null,
                isAuthenticated: false,
                loading: false,
                user: null
            }
        case TODO_LOGOUT:  
            localStorage.removeItem('todotoken');
            return {
                todotoken: null,
                todoisAuthenticated: false,
                todoloading: false,
                todouser: null
            }
        case WIKI_LOGOUT:  
            localStorage.removeItem('wikitoken');
            return {
                wikitoken: null,
                wikiisAuthenticated: false,
                wikiloading: false,
                wikiuser: null
            }
        case TASKBOARDS_LOGOUT:  
            localStorage.removeItem('taskboardstoken');
            return {
                taskboardstoken: null,
                taskboardsisAuthenticated: false,
                taskboardsloading: false,
                taskboardsuser: null
            }
    
        default:
            return state;  
    }
};