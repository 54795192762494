import React from "react";
import { Container, Button } from "shards-react";

const ErrorsUnauth = () => (
  <Container fluid className="main-content-container px-4 pb-4">
    <div className="error">
      <div className="error__content">
        <h3>Oops, you don't have access to this page</h3>
        <p>Please contact our support team for further information.</p>
      </div>
    </div>
  </Container>
);

export default ErrorsUnauth;
