import React, { useState, useEffect } from 'react';
import {
  Button, Col, Navbar,
  NavbarBrand, Form, FormGroup, Label, Input, FormText
} from 'reactstrap';
import ReactGA from 'react-ga';
const codesArray = require('../config/appsumocodes');


export default function SaleSuccess(props) {

    const [code, setCode] = useState();
    const [valid, setValid] = useState();

    function handleSaleButtonClick() {
        ReactGA.event({
          category: "OKR Sale Button Click",
          action: "Clicked on OKR Sale Add to Slack button",
        })
        // production link
        window.open(`https://slack.com/oauth/v2/authorize?client_id=791862583680.812221040258&state=okrsale&scope=app_mentions:read,channels:read,chat:write,im:history,im:read,team:read,users:read,users:read.email`)
      }
      

      // // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
     

        (async () => {
          try {

            let search = window.location.search;
            let params = new URLSearchParams(search);
            let foo = params.get('code');
  
            var validState = codesArray.includes(foo);
            setCode(foo);
            setValid(validState)

            
          } catch (error) {
            console.log(error);
          }
          
        })();
      }, []);
    


const block = (<React.Fragment>
    <Navbar className = "mt-2 mb-2" light expand="lg">
            <NavbarBrand className = "ml-md-4" href="/"><span><img src="/assets/cheese-512.png" width={30} alt="Ricotta" /> </span> <span style={{color: '#E04F60'}}>Ricotta</span></NavbarBrand>
        </Navbar>
        <div className ="container mt-5 text-center">
        <h2 className="appsumo-header">The Ricotta Spring Sale!</h2>
        </div>
        {valid === false ? (<React.Fragment>
            <div className ="container mt-5 text-center">
                <h5>Your Spring Sale Code is invalid or does not exist. Please contact cheese@ricotta.team to resolve this issue.</h5>
            </div>
        </React.Fragment>): 
        (<React.Fragment>
            <div className ="container mt-5 text-center">
                <h5>Success! You have subscribed to the Ricotta Spring Sale. Your code is {code}</h5>
                <p>Note: Redeem this code by adding Ricotta to your Slack workspace. Once redeemed, your code will no longer be valid.</p>
                <p>Please reach out to cheese@ricotta.team if you face any issues.</p>
            </div>
            <div className="mt-5 text-center"><Col xs = {{size: 12 }} sm = {{size: 8, offset: 2 }} md={{size: 4, offset: 4 }} lg={{size: 4, offset: 4 }} xl={{size: 4, offset: 4 }}><div class="col-sm-12 mx-sm-auto col-md-12 mx-md-auto col-lg-10 mx-lg-auto col-xl-9 mx-xl-auto col-11 mx-auto">
                <Button className = "btn-lg btn-block signUpButton2" onClick={handleSaleButtonClick}>Add Ricotta to Slack</Button><small>Redeem your Code now</small></div></Col>
            </div>
        </React.Fragment>)}
        
        
</React.Fragment>)


  return block
  
}