import React, { useState, useEffect } from 'react';
import { Container } from "shards-react";
import { loadStripe } from '@stripe/stripe-js';
const axios4 = require('../../../config/axiosInstance4');


function BillingActiveMessage({user}) {
    const [billing, setBilling] = useState("annually");

  const [errorText, setErrorText] = useState();
  const [isAdmin, setIsAdmin] = useState();
  const [isDummy, setIsDummy] = useState();
  const [isBillingActive, setIsBillingActive] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [slackteamid, setSlackteamid] = useState("");
  const [slackuserid, setSlackuserid] = useState("");
  const [custId, setCustId] = useState("");
  const [billingUser, setBillingUser] = useState("");
  const [country, setCountry] = useState("");
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [ccUpdate, setccUpdate] = useState("");

  // to activate update billing
  // const [redirect, setRedirect] = useState();


    useEffect(() => {
        try {
            var isAdmin = user.isAdmin;
        var email = user.email;
        var billingActive = user.isBillingActive;
        var name = user.name;
        var slackTeamID = user.teamId;
        var slackUserID = user.id;
        var stripeCustomerId = user.stripeCustomerId;
        var leBillingUser = user.billingUser;
        var ccUpdate = user.ccUpdate;


        setIsAdmin(isAdmin);
        setName(name);
        setEmail(email);
        setSlackteamid(slackTeamID);
        setSlackuserid(slackUserID);
        setIsDummy(isAdmin)
        setCustId(stripeCustomerId)
        setBillingUser(leBillingUser)
        setccUpdate(ccUpdate)

            if(billingActive === true && slackUserID.toString() === leBillingUser.toString() && ccUpdate === 'true'){
                setLoaded(true);
                setRedirect(true);

                (async () => {
                // API call
                const configT = {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                  };
              
                  const body2 = JSON.stringify({ 
                      customerId: stripeCustomerId,
                      returnUrl: "https://www.ricotta.team/trivia"
                  });
              
                  const res = await axios4.post('/api/checkoutSessionNew/billingportal', body2, configT);
                  var sessionUrl = res.data;
                  window.location.href = sessionUrl;
                })();
            }
            else {
                setLoaded(true);
                setRedirect(false); 
            }
  
            // old code
            // if(billingActive === false || slackUserID.toString() !== leBillingUser.toString()){

            //     setLoaded(true);
            //     setRedirect(false); 
            // }
            // else {

            //     // (async () => {
            //     // // API call
            //     // const configT = {
            //     //     headers: {
            //     //         'Content-Type': 'application/json',
            //     //     }
            //     //   };
              
            //     //   const body2 = JSON.stringify({ 
            //     //       customerId: stripeCustomerId,
            //     //       returnUrl: "https://www.ricotta.team/trivia"
            //     //   });
              
            //     //   const res = await axios4.post('/api/checkoutSessionNew/billingportal', body2, configT);
            //     //   var sessionUrl = res.data;
            //     //   window.location.href = sessionUrl;
            //     // })();
            // }
        
            
        } catch (error) {
            
        }


    }, [])


    return (
        <React.Fragment>
            {redirect === false ? (<React.Fragment><Container fluid className="main-content-container px-2 pb-2">
                <div className="error">
                <div className="error__content">
                    <h3>Your subscription to Ricotta Games & Trivia is active!</h3>
                    <p className ="lead mt-2">To update or cancel your plan, please contact us at <a href = "mailto: cheese@ricotta.team">cheese@ricotta.team</a></p> 
                </div>
                </div>
            </Container></React.Fragment>) : 
            (<React.Fragment><Container fluid className="main-content-container px-2 pb-2"><div className ="container mt-5">
            <h1 className="billing-management-header">Ricotta Trivia Update Payment</h1> 
            <p className ="mt-2">Redirecting...please wait</p>
            </div> </Container></React.Fragment>)}
            
        </React.Fragment>
    )
}

export default BillingActiveMessage
