import React, { useState, useEffect } from 'react';
import {
  Button, Col, Navbar,
  NavbarBrand,
} from 'reactstrap';
import { loadStripe } from '@stripe/stripe-js';
const codesArray = require('../config/appsumocodes');
const axios2 = require('axios');


const stripePromise = loadStripe('pk_live_oYyuovMeNVaQe5SSflWCE8XD00edZonWk9');



export default function Sale(props) {

    const [code, setCode] = useState();
    const [country, setCountry] = useState("");


    useEffect(async () => {


        var r = Math.floor(Math.random() * 9000);
        var theCode = codesArray[r];
        setCode(theCode);

        var ipRes = await axios2.get('https://ipapi.co/json/')
        console.log(ipRes)
        ipRes.status === 200 ? setCountry(ipRes.data.country_name) : setCountry("Not found")
        console.log(country);

    }, []);

    const handleClickPrice4 = async (event) => {
        // When the customer clicks on the button, redirect them to Checkout.
        const stripe = await stripePromise;
        // old - normal pricing
        // const priceId4 = country === 'India' ? 'price_1HMt47JBEzpLhH7BzYxXerEM' : 'price_1HbPJwJBEzpLhH7B5No7Gebl'
    
        // new quarterly sale pricing
        const priceId4 = country === 'India' ? 'price_1IYTGJJBEzpLhH7BSd0m9u5T' : 'price_1IYTEOJBEzpLhH7BLf158huo'
    
    
        // global normal price id 'price_1HbPJwJBEzpLhH7B5No7Gebl' 
    
        const { error } = await stripe.redirectToCheckout({
          lineItems: [
            // Replace with the ID of your price
            {price: priceId4, quantity: 1}
          ],
          mode: 'subscription',
          successUrl: `https://app.ricotta.team/salesuccess?code=${code}`,
          cancelUrl: `https://app.ricotta.team/paymentfailedokrsale`,
          billingAddressCollection: 'required',
          clientReferenceId: `okrSale&unlimitedAnnually&NA&NA`
        });
      };




  return (
    <React.Fragment>
        <Navbar className = "mt-2" light expand="lg">
            <NavbarBrand className = "ml-md-4" href="https://www.ricotta.team"><span><img src="/assets/cheese-512.png" width={30} alt="Ricotta" /> </span> <span style={{color: '#E04F60'}}>Ricotta</span></NavbarBrand>
        </Navbar>
        <div className ="container ">
          <h1 className ="mt-5 text-center ricotta-color"><b>The Ricotta OKR Spring Sale - at only $199 a year!</b></h1>
          <h3 className ="mt-5 text-center">OKR Software on Slack for unlimited users in your Slack workspace.</h3>
            <h3 className ="mt-3 text-center">Offer ends on <span className="ricotta-color">April 5th 2021.</span> Subscribe now!</h3>
        </div>
        <div className ="container mt-5">
          <p className ="lead mt-2 text-center"><b>New user? Subscribe for $199 a year.</b></p>
     
        </div>
        <div className="mt-md-4 mt-2"><Col xs = {{size: 10, offset: 1 }} md={{size: 4, offset: 4 }}><div className="col-md-9 mx-md-auto">
          <Button className = "btn-lg btn-block signUpButton2" onClick={handleClickPrice4}>
            Subscribe</Button></div></Col></div>

            <div className ="container mt-5">
          <p className ="lead mt-2 text-center"><b>Already have an account? Sign in with Slack to subscribe.</b></p>
     
        </div>
        <div className="mt-md-4 mt-2"><Col xs = {{size: 10, offset: 1 }} md={{size: 4, offset: 4 }}><div className="col-md-9 mx-md-auto">
          <Button className = "btn-lg btn-block signUpButton2" href="https://slack.com/oauth/v2/authorize?client_id=791862583680.812221040258&scope=users:read&state=billing&redirect_uri=https://ricotta-interaction.herokuapp.com/api/auth">
            Sign in with<span><img src="/assets/slack_white.svg" width={80} height={30} alt="Slack" /></span></Button></div></Col></div>
    
    
    </React.Fragment>
  );
}