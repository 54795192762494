import React, { useState, useEffect } from 'react';
import {
  Button, Col, Navbar,
  NavbarBrand, Media
} from 'reactstrap';
const axios = require('axios');


export default function TaskboardsPaymentSuccess(props) {

  const [country, setCountry] = useState("");

  // // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let team = params.get('team');
    var applicationId = 'A02TP7FLW22'
    var href = `https://slack.com/app_redirect?app=${applicationId}&team=${team}`
   
    window.setTimeout(function(){

        window.location.href = href;

    }, 3000);


  });


  return (
    <React.Fragment>
        <div className ="container billing-div">
            <p className ="lead mt-5 text-center"><b>Subscription successful. Redirecting to your Slack workspace...</b></p> 
        </div>
        <div className="container">
            <Media>
            <img src="/assets/sub_yes.svg" className = "img-fluid billing-management-image" alt="Ricotta on Slack" />
            </Media>
        </div>
    
    
    </React.Fragment>
  );
}