const axios = require('axios');

var axiosInstance3 = process.env.NODE_ENV === 'production' ? axios.create({
  baseURL: 'https://triviaapp.ricotta.team/'
}) : 
axios.create({
  baseURL: 'http://localhost:8081/'
})




module.exports = axiosInstance3;