import React, { Component } from 'react';

const textArray = ['trivia', 'ice-breakers'];

class TriviaHeading extends Component {
  constructor() {
    super();
    this.state = { textIdx: 0 };
  }

  componentDidMount() {
    this.timeout = setInterval(() => {
      let currentIdx = this.state.textIdx;
      this.setState({ textIdx: currentIdx + 1 });
    }, 2000);
  }

  componentDidUnmount() {
    clearInterval(this.timeout);
  }

  render() {
    let textThatChanges = textArray[this.state.textIdx % textArray.length];

    return (
        <h1 className="landing-header-trivia">Engage your <span className="ricotta-color">remote team</span> with some fun <span className="ricotta-color">{textThatChanges}</span></h1>
    )
  }
}

export default TriviaHeading;