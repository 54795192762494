import React, { Component } from 'react';
import { Col, Form, FormGroup, Alert, Label, Input, Button, Row } from 'reactstrap';
import { emailSignUpCall } from '../config/emailSignUpCall';
// const axios = require('../config/axiosInstance');


class FormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        value: '',
        isOpen: false,
        isVisible: false,
        depictValue: '',
        fields: {},
        errors: {}
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleValidation = this.handleValidation.bind(this);  
    this.toggleAlert = this.toggleAlert.bind(this);
    this.toggleAlert2 = this.toggleAlert2.bind(this);  
  }

 toggleAlert(){
        this.setState({
            isOpen: !this.state.isOpen
        });
    }


    
 toggleAlert2(){
        this.setState({
            isVisible: !this.state.isVisible
        });
    }    
    
handleValidation(){
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        //Email
        if(!fields["email"]){
           formIsValid = false;
           errors["email"] = "Email cannot be empty";
        }

        if(typeof fields["email"] !== "undefined"){
           let lastAtPos = fields["email"].lastIndexOf('@');
           let lastDotPos = fields["email"].lastIndexOf('.');

           if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') == -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
              formIsValid = false;
              errors["email"] = "Email is not valid";
            }
       }  

       this.setState({errors: errors});
       return formIsValid;
   }    
        
  handleChange(field, event) {
    this.setState({value: event.target.value});
      let fields = this.state.fields;
        fields[field] = event.target.value;        
        this.setState({fields});
  }

  async handleSubmit(event) {
    event.preventDefault();
      if(this.handleValidation()){
            if(this.state.isVisible === true) {
              this.toggleAlert2();  
            }
              this.toggleAlert();
              
            const res = await emailSignUpCall(this.state.value)
            console.log(res);
    
            if(res === 'success'){
              this.setState({depictValuevalue: this.state.value});  
              this.setState({value: ''});
              setTimeout(
              function() {
                  if (this.state.isOpen === true){
                  this.toggleAlert();
                  }
              }
              .bind(this),
              2000
              );
            }
             
  }
      
    else  {
      // const config = {
      //   headers: {
      //     'Content-Type': 'application/json'
      //   }
      // };

      //   const res = axios.get('/hellofromnetlify', config);
        this.toggleAlert2();
    }
  }
  render() {
    return (
    <React.Fragment>
         <section className = "cta py-5">
            <div className = "container mt-lg-3">
                <div className = "row">
                    <div className = "col-12 text-center">
                        <h2 className = "ctaHeading ricotta-color"><b>Request a demo</b></h2>
                        <p>We will answer all your questions and walk you through Ricotta</p>
                    </div>
                </div>
            </div>
      <div className = "container">   
    
    <Form onSubmit={this.handleSubmit}>
      <FormGroup row>
          <Col xs = {{size: 12}} md ={{size: 5, offset: 2 }}>
        <Input className = "signUpForm" type="text" placeholder="Please enter your work email" value={this.state.value} onChange={this.handleChange.bind(this, "email")} />
      </Col>
      <Col xs = {{size: 8, offset: 2 }} md={{size: 3, offset: 0 }}>
      <Button className = "btn-sm btn-block signUpButton" type="submit" value="Submit"><b>Request a demo</b></Button>
      </Col>
      </FormGroup>
    </Form>
      <Row>
    <Col xs = {{size: 12}} md ={{size: 8, offset: 2 }}>
        <Alert color="success" isOpen={this.state.isOpen} toggle={this.toggleAlert}>
        Thank you for signing up, {this.state.depictValuevalue}! Our team will reach out to you shortly.
        </Alert>
    </Col>
    <Col xs = {{size: 12}} md ={{size: 5, offset: 2 }}>
      <Alert color="danger" isOpen={this.state.isVisible} toggle={this.toggleAlert2}>
        Email has errors: {this.state.errors["email"]}!
      </Alert>
    {/* <Button className = "btn-lg btn-block signUpButton" type="submit" href="https://ricotta.team/" target="_blank"> Add to Slack </Button> */}
    </Col>  
    </Row>    
  </div> 
    </section>
    </React.Fragment>   
    );
  }
}

export default FormComponent;